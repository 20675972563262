<template>
  <FlyInputLabel
    :error="error || inputErrorMessage"
    :label="label"
    :length-error="inputWarningMessage"
    :class-name="labelClass"
  >
    <div class="fly-box input-container">
      <slot name="input-prefix"></slot>
      <div class="fly-box icon-input-container">
        <FlyIcon
          v-if="iconLeft"
          class="fly-icon"
          :is-active="focused"
          :name="iconLeft"
        />
        <input
          ref="input"
          :class="{ 'fly-input': true, error: error }"
          :disabled="isDisabled"
          :maxlength="maxLength"
          :placeholder="placeholder"
          v-bind="$attrs"
          @focus="onFocus"
          @blur="onBlur"
        />
        <FlyIcon v-if="iconRight" class="fly-icon" :name="iconRight" />
      </div>
      <slot name="input-suffix"></slot>
    </div>
    <template #helpText>
      <slot
        ><div v-if="helpText" class="fly-text fly-text--input-help-text">
          {{ helpText }}
        </div></slot
      >
    </template>
  </FlyInputLabel>
</template>

<script>
import VueTypes from 'vue-types';
import FlyInputLabel from 'shared/components/Form/FlyInputLabel.vue';
import INPUT_MAX_LENGTH_ENUM from 'shared/components/Form/INPUT_MAX_LENGTH_ENUM';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';

export default {
  name: 'FlyInput',
  components: {
    FlyInputLabel,
    FlyIcon,
  },
  inheritAttrs: false,
  props: {
    autoFocus: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    iconLeft: VueTypes.string.def(''),
    placeholder: VueTypes.string.def(''),
    iconRight: VueTypes.string.def(''),
    error: VueTypes.string.def(''),
    label: VueTypes.string.def(''),
    helpText: VueTypes.string.def(''),
    maxDate: VueTypes.number,
    minDate: VueTypes.number,
    labelClass: VueTypes.string,
  },
  data() {
    return {
      focused: false,
      inputErrorMessage: '',
      inputWarningMessage: '',
      maxLength: '',
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading || this.disabled;
    },
  },
  watch: {
    '$attrs.value'() {
      this.computeError();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.autoFocus) {
        this.$refs.input.focus();
      }
    });
    this.setMaxLength();
  },
  methods: {
    setMaxLength() {
      switch (this.$attrs.inputtype) {
        case 'email':
          this.maxLength = INPUT_MAX_LENGTH_ENUM.EMAIL_MAX_LENGTH;
          break;
        case 'node_name':
          this.maxLength = INPUT_MAX_LENGTH_ENUM.NODE_NAME_MAX_LENGTH;
          break;
        case 'user_name':
          this.maxLength = INPUT_MAX_LENGTH_ENUM.USER_NAME_MAX_LENGTH;
          break;
        case 'telephone':
          this.maxLength = INPUT_MAX_LENGTH_ENUM.TELEPHONE_MAX_LENGTH;
          break;
        case 'password':
          this.maxLength = INPUT_MAX_LENGTH_ENUM.PASSWORD_MAX_LENGTH;
          break;
        case 'node_config':
          this.maxLength = INPUT_MAX_LENGTH_ENUM.NODE_CONFIG_MAX_LENGTH;
          break;
        default:
          this.maxLength = '';
      }
    },
    computeError() {
      const isMaxDateError =
        this.maxDate && new Date(this.$attrs.value) > this.maxDate;
      const isMinDateError =
        this.minDate && new Date(this.$attrs.value) < this.minDate;
      const isMaxLengthError =
        this.maxLength &&
        this.$attrs.value &&
        this.$attrs.value.length >= this.maxLength;

      if (isMaxDateError) {
        this.inputErrorMessage = `The value is incorrect. Maximum date is ${this.maxDate.toLocaleDateString()}`;
      }

      if (isMinDateError) {
        this.inputErrorMessage = `The value is incorrect. Minimum date is ${this.minDate.toLocaleDateString()}`;
      }

      if (isMaxLengthError) {
        this.inputWarningMessage = `The maximum length was exceeded. The string was cut at ${this.maxLength} characters`;
      } else {
        this.inputWarningMessage = '';
      }

      if (!isMaxDateError && !isMinDateError) {
        this.inputErrorMessage = '';
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    // eslint-disable-next-line vue/no-unused-properties
    blur() {
      this.$refs.input.blur();
    },
    // eslint-disable-next-line vue/no-unused-properties
    focus() {
      this.$refs.input.focus();
    },
    // eslint-disable-next-line vue/no-unused-properties
    selectAllText() {
      if (!this.$attrs.readonly) {
        this.$refs.input.setSelectionRange(0, this.$refs.input.value.length);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-input {
  transition: all 150ms ease-in;
  outline: none !important;
  height: 100%;

  &[disabled],
  &.fly-input--loading {
    cursor: not-allowed;
    background-color: $fly-color-grey-8;
  }

  &::placeholder {
    color: $fly-color-grey-4;
  }
}

.input-container {
  width: 100%;

  .icon-input-container {
    position: relative;
    width: 100%;
  }
}
</style>
