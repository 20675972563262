import assert from 'assert';
import { each, keys } from 'lodash';
import { clearErrorStore, setError, clearError } from './errorMutationTypes.js';
import { getAllErrors, hasError } from './errorGetterTypes.js';

export default {
  state: {},
  getters: {
    [hasError]: (state) => (key) => state[key],
    [getAllErrors]: (state) => state,
  },
  mutations: {
    [setError](state, { key, value }) {
      assert(key, 'specify key for setError');
      state[key] = value;
    },
    [clearError](state, key) {
      state[key] = null;
    },
    [clearErrorStore](state) {
      each(keys(state), (key) => {
        state[key] = null;
      });
    },
  },
};
