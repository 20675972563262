import pipelinesUIStore from './pipelinesUIStore.js';
import pipelinesDataStore from './pipelinesDataStore.js';
import pipelineUsageStore from 'web/pipelines/usage/pipelineUsageStore';
import pipelineLogsStore from './details/logs/pipelineLogsStore.js';
import pipelineHooksStore from 'web/pipelines/details/hooks/pipelineHooksStore';
import pipelineAccountUsageStore from 'web/settings/usage/pipelineAccountUsageStore';

export default {
  modules: {
    ui: pipelinesUIStore,
    data: pipelinesDataStore,
    usage: pipelineUsageStore,
    logs: pipelineLogsStore,
    hooks: pipelineHooksStore,
    accountUsage: pipelineAccountUsageStore,
  },
};
