export const getLoggedInUser = 'getLoggedInUser';
export const isUserTrialExpired = 'isUserTrialExpired';
export const getBillingSubscriptions = 'getBillingSubscriptions';
export const hasActiveSubscription = 'hasActiveSubscription';
export const isInActiveTrial = 'isInActiveTrial';
export const getTrialExpiryDate = 'getTrialExpiryDate';
export const isUserAnAdmin = 'isUserAnAdmin';
export const isClientStripeManaged = 'isClientStripeManaged';
export const isClientBypassBilling = 'isClientBypassBilling';
export const getClient = 'getClient';
export const getFreeObservabilityQuota = 'getFreeObservabilityQuota';
export const getFreeObservabilityUsage = 'getFreeObservabilityUsage';
export const getPaidObservabilityQuota = 'getPaidObservabilityQuota';
export const getPaidObservabilityUsage = 'getPaidObservabilityUsage';
export const showObservabilityUpsell = 'showObservabilityUpsell';
export const showObservabilityUpgrade = 'showObservabilityUpgrade';
export const canCreateAlert = 'canCreateAlert';
export const isBillingMailerOptedIn = 'isBillingMailerOptedIn';
export const isMonthlyMailerOptedIn = 'isMonthlyMailerOptedIn';
