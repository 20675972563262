<template>
  <component
    :is="name"
    v-if="name"
    class="fly-icon-component"
    :class="classes"
    v-bind="$attrs"
  />
</template>

<script>
import flyIcons from './requireIcons';
import FlyIconProps from 'shared/components/Icon/FlyIconProps';

export default {
  name: 'FlyIcon',
  components: {
    ...flyIcons,
  },
  props: {
    ...FlyIconProps,
  },
};
</script>

<style lang="scss">
.fly-icon-component path {
  transition: all 150ms ease-in;
}
</style>
