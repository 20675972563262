import { get } from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import {
  clearFormData,
  clearFormErrors,
  resetFormData,
  setFormData,
  setFormField,
  setFormFieldAtPath,
  setFormFieldError,
  setInitialFormData,
  setInitialFormField,
  setInitialFormFieldAtPath,
} from 'shared/stores/form/formMutationTypes';
import {
  formFieldHasChanges,
  formFieldValue,
  formHasChanges,
  formHasError,
  getChangedFormData,
  getFormData,
  getFormError,
  getInitialFormData,
} from 'shared/stores/form/formGetterTypes';
import VueTypes from 'vue-types';

export default (FORM_KEY) => ({
  props: {
    formName: VueTypes.string.def(FORM_KEY),
  },
  computed: {
    ...mapGetters({
      getInitialFormData,
      getFormData,
      getFormError,
      _formFieldValue: formFieldValue,
      _formHasError: formHasError,
      _formHasChanges: formHasChanges,
      _formFieldHasChanges: formFieldHasChanges,
      getChangedFormData,
    }),
    initialFormData() {
      return this.getInitialFormData(this.formName);
    },
    formData() {
      return this.getFormData(this.formName);
    },
    formFieldValue() {
      return (field) => this._formFieldValue({ key: this.formName, field });
    },
    formFieldError() {
      return (field) => get(this.formErrors, field);
    },
    formErrors() {
      return this.getFormError(this.formName);
    },
    formHasChanges() {
      return this._formHasChanges(this.formName);
    },
    formHasError() {
      return this._formHasError(this.formName);
    },
    formFieldHasChanges() {
      return (field) => this._formFieldHasChanges(this.formName, field);
    },
    changedFormData() {
      return this.getChangedFormData(this.formName);
    },
  },
  methods: {
    ...mapMutations({
      _setInitialFormData: setInitialFormData,
      _setFormData: setFormData,
      _clearFormData: clearFormData,
      _resetFormData: resetFormData,
      _setFormField: setFormField,
      _setInitialFormField: setInitialFormField,
      _setFormFieldError: setFormFieldError,
      _clearFormErrors: clearFormErrors,
      _setFormFieldAtPath: setFormFieldAtPath,
      _setInitialFormFieldAtPath: setInitialFormFieldAtPath,
    }),
    setInitialFormData(formData) {
      this._setInitialFormData({ key: this.formName, formData });
    },
    setFormData(formData) {
      this._setFormData({ key: this.formName, formData });
    },
    setFormField(field, e) {
      const value = e instanceof Event ? e.target.value : e;
      this._setFormField({
        key: this.formName,
        field,
        value,
      });
    },
    setInitialFormField(field, e) {
      const value = e instanceof Event ? e.target.value : e;
      this._setInitialFormField({
        key: this.formName,
        field,
        value,
      });
    },
    setFormFieldAtPath(path, e) {
      const value = e instanceof Event ? e.target.value : e;
      this._setFormFieldAtPath({
        key: this.formName,
        path,
        value,
      });
    },
    setInitialFormFieldAtPath(path, e) {
      const value = e instanceof Event ? e.target.value : e;
      this._setInitialFormFieldAtPath({
        key: this.formName,
        path,
        value,
      });
    },
    setFormFieldError(field, error) {
      this._setFormFieldError({
        key: this.formName,
        field,
        error,
      });
    },
    clearFormData() {
      this._clearFormData(this.formName);
    },
    resetFormData() {
      this._resetFormData(this.formName);
    },
    clearFormErrors() {
      this._clearFormErrors(this.formName);
    },
  },
});
