export const verifyPasswordTokenAction = 'verifyPasswordTokenAction';
export const verifyInviteTokenAction = 'verifyInviteTokenAction';
export const userInviteAction = 'userInviteAction';
export const resetPasswordAction = 'resetPasswordAction';
export const loginAction = 'loginAction';
export const logoutAction = 'logoutAction';
export const forgotPasswordAction = 'forgotPasswordAction';
export const signupAction = 'signupAction';
export const userEmailConfirmationAction = 'userEmailConfirmationAction';
export const setSignupPasswordAction = 'setSignupPasswordAction';
