import createServiceStore from 'shared/utils/createServiceStore.js';
import { SOURCE_SCHEMA_SERVICE_KEY } from './sourceSchemaEnum.js';
import sourceSchemaService from './sourceSchemaService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';

export default createServiceStore({
  serviceName: SOURCE_SCHEMA_SERVICE_KEY,
  service: sourceSchemaService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
});
