import { filter, map } from 'lodash';
import * as Sentry from '@sentry/vue';
import CONFIG from 'shared/config';

export default ({ user }) => {
  Sentry.configureScope(function (scope) {
    const scriptTags = document.querySelectorAll('script');
    const ourScriptsRegex = new RegExp(`^${CONFIG.CDN_URL}js.*`);
    scope.setUser({
      email: user.email,
      id: user.id,
      timezone: user.time_zone,
      name: user.full_name,
    });
    scope.setTag('clientId', user.client.id);
    scope.setTag('clientName', user.client.name);
    scope.setExtra(
      'appScripts',
      JSON.stringify(
        map(
          filter(scriptTags, (scriptTag) => {
            return ourScriptsRegex.test(scriptTag.src);
          }),
          'src'
        )
      )
    );
  });
};
