import createServiceStore from 'shared/utils/createServiceStore.js';
import { PUBLICIP_SERVICE_KEY } from './publicIPEnum.js';
import publicIPService from './publicIPService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';

export default createServiceStore({
  serviceName: PUBLICIP_SERVICE_KEY,
  service: publicIPService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  idPath: 'region',
});
