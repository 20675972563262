<template>
  <div class="fly-box ga4-properties-select-table">
    <div class="fly-box header-container">
      <FlyLink
        class="fly-link fly-text fly-text--ui-small"
        href="#"
        @click.prevent="refreshAvailableProperties"
      >
        <FlyIcon class="fly-icon" name="Refresh" />
        Refresh table list
      </FlyLink>
    </div>
    <div class="fly-box table-container">
      <data-table
        :rows="propertiesData"
        :pagination="pagination"
        :query="query"
        filter
        @load-data="loadData"
      >
        <template #thead>
          <table-head>
            <FlyCheckbox
              :label="selectAllLabel"
              :checked="allCollectionsSelected"
              @change="onSelectAllClick"
            />
          </table-head>
          <table-head><div class="table-header">Property Name</div></table-head>
          <table-head>
            <div class="table-header">Property ID</div>
          </table-head>
          <table-head>
            <div class="table-header">Account Name</div>
          </table-head>
          <table-head>
            <div class="table-header">Account ID</div>
          </table-head>
        </template>

        <template #tbody="{ row }">
          <table-body>
            <FlyCheckbox
              :checked="row.selected"
              @change="onCollectionSelectionChange(row, $event)"
            />
          </table-body>
          <table-body>
            <span>{{ row.display_name }}</span>
          </table-body>
          <table-body>
            <span>{{ row.id }}</span>
          </table-body>
          <table-body>
            <span>{{ row.account_name }}</span>
          </table-body>
          <table-body>
            <span>{{ row.account }}</span>
          </table-body>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import FormStoreMixin from 'shared/stores/form/FormStoreMixin';
import { SETUP_PIPELINE_FORM } from 'web/form/formKeys';
import FlyCheckbox from 'shared/components/Form/FlyCheckbox.vue';
import { get, every, each, findIndex, find, isEmpty } from 'lodash';
import { mapActions } from 'vuex';
import FlyLink from 'shared/components/Link/FlyLink.vue';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import { DataTable, TableBody, TableHead } from '@jobinsjp/vue3-datatable';
import { getGA4PropertiesAction } from 'web/sources/sourcesActionTypes';

function paginate(data, page, perPage) {
  const start = (page - 1) * perPage;
  const end = page * perPage;
  return data.slice(start, end);
}

export default {
  name: 'GA4PropertiesSelectTable',
  components: {
    FlyCheckbox,
    DataTable,
    TableBody,
    TableHead,
    FlyLink,
    FlyIcon,
  },
  mixins: [FormStoreMixin(SETUP_PIPELINE_FORM)],
  emits: ['selectedPropertiesChange'],
  setup() {
    const query = ref({
      search: '',
    });

    return { query };
  },

  data() {
    return {
      pagination: {
        page: 1,
        total: 10,
      },
    };
  },
  computed: {
    properties() {
      return get(this.formData, 'config.selectedProperties', []) || [];
    },
    refreshToken() {
      return get(this.formData, 'config.static.refreshToken');
    },
    accessToken() {
      return get(this.formData, 'config.static.accessToken');
    },
    allCollectionsSelected() {
      if (!this.properties.length) {
        return false;
      }
      let queryCollections = this.query.search
        ? this.properties.filter((item) =>
            item.display_name
              .toLowerCase()
              .includes(this.query.search.toLowerCase()),
          )
        : this.properties;
      if (isEmpty(queryCollections)) return false;
      return every(queryCollections, ({ display_name }) => {
        return find(this.properties, { display_name }).selected;
      });
    },
    selectAllLabel() {
      return this.query.search ? 'Select All Filtered' : 'Select All';
    },
  },
  watch: {
    availableCollections: {
      handler() {
        this.setPropertiesInFormData();
        this.loadData({ page: 1, total: 10, per_page: 10 });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.setPropertiesInFormData();
    this.loadData({ page: 1, total: 10, per_page: 10 });
  },
  methods: {
    ...mapActions({
      getGA4PropertiesAction,
    }),
    loadData(query) {
      this.query.search = query.search;
      this.propertiesData = query.search
        ? this.properties.filter((item) =>
            item.display_name
              .toLowerCase()
              .includes(query.search.toLowerCase()),
          )
        : this.properties;
      const total = this.propertiesData.length;
      this.propertiesData = paginate(
        this.propertiesData,
        query.page,
        query.per_page,
      );
      this.pagination = {
        ...this.pagination,
        page: query.page,
        total,
      };
    },
    setPropertiesInFormData() {
      let ga4Properties = get(
        this.formData,
        'config.extra_args.ga4Properties',
        [],
      );
      let defaultValue = [];
      let formProperties = [];
      let previousLoopableObject =
        get(this.formData, 'config.dynamic.loopableObject') || defaultValue;
      each(ga4Properties, (property) => {
        formProperties.push({
          ...property,
          selected: !!find(previousLoopableObject, ['propertyId', property.id]),
        });
      });
      this.setFormFieldAtPath('config.selectedProperties', formProperties);
    },
    onCollectionSelectionChange({ id }, e) {
      this.setFormFieldAtPath(
        `config.selectedProperties[${findIndex(this.properties, {
          id,
        })}].selected`,
        e.target.checked,
      );
      this.emitDynamicFormChange();
    },
    setPropertyForAll(propertyName, e) {
      let defaultValue = [];
      let formProperties =
        get(this.formData, 'config.selectedProperties', defaultValue) ||
        defaultValue;
      let queryPropeties = this.query.search
        ? this.properties.filter((item) =>
            item.display_name
              .toLowerCase()
              .includes(this.query.search.toLowerCase()),
          )
        : this.properties;
      queryPropeties.forEach(({ id }) => {
        formProperties[findIndex(this.properties, ['id', id])][propertyName] =
          e.target.checked;
      });
      this.setFormFieldAtPath('config.selectedProperties', formProperties);
      this.emitDynamicFormChange();
    },
    onSelectAllClick(e) {
      this.setPropertyForAll('selected', e);
    },
    emitDynamicFormChange() {
      let dynamicLoopableObject = [];
      let formProperties = get(this.formData, 'config.selectedProperties', []);
      formProperties.forEach(({ id, account, selected }) => {
        if (selected) {
          dynamicLoopableObject.push({
            propertyId: id,
            accountId: account,
          });
        }
      });
      this.$emit('selectedPropertiesChange', dynamicLoopableObject);
    },
    async refreshAvailableProperties() {
      const newProperties = await this.getGA4PropertiesAction({
        refreshToken: this.refreshToken,
        accessToken: this.accessToken,
      });
      this.setFormFieldAtPath(
        'config.extra_args.ga4Properties',
        get(newProperties, ['ga4Properties'], []),
      );
      this.setPropertiesInFormData();
      this.loadData({ page: 1, total: 10, per_page: 10 });
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.ga4-properties-select-table {
  flex-direction: column;
  margin-left: 8px;
  width: 100%;

  .header-container {
    align-items: center;
    justify-content: space-between;
    position: sticky;
    padding-top: 4px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    top: 0;
    z-index: 1;

    .fly-link {
      color: $fly-color-blue-1;
      margin-right: 8px;

      .fly-icon {
        margin-right: 8px;
        path {
          fill: $fly-color-blue-1;
        }
      }
    }
  }

  .table-container {
    .data-table {
      width: 100%;

      .table-header {
        color: $fly-color-grey-1;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

select.dt__pagination_size {
  width: 80px;
}
</style>
