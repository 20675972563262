import { CONNECTOR_API_URL } from './connectorEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(CONNECTOR_API_URL, {
  extraMethods: {
    async getS3RoleAccessSetupCloudFormationTemplateUrl({ params }) {
      return this.request({
        url: `${this.route}s3_role_access_template/`,
        method: 'get',
        params,
      });
    },
  },
});
