<template>
  <FlyDropdown
    trigger="click"
    mount-on="body"
    :popper-options="{ placement: 'bottom' }"
  >
    <template #trigger>
      <slot></slot>
    </template>
    <template #options>
      <slot name="menu">
        <div class="fly-box fly-menu">
          <div
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :class="'fly-box fly-menu-item ' + (menuItem.class || '')"
            @click="onMenuItemClick(menuItem)"
            @touchend="onMenuItemClick(menuItem)"
          >
            <FlyIcon
              v-if="menuItem.icon"
              class="fly-icon"
              :name="menuItem.icon"
            />
            <span class="fly-text fly-text--ui-small">
              {{ menuItem.text }}
            </span>
          </div>
        </div>
      </slot>
    </template>
  </FlyDropdown>
</template>

<script>
import FlyDropdown from 'shared/components/Dropdown/FlyDropdown.vue';
import VueTypes from 'vue-types';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';

export default {
  name: 'FlyMenu',
  components: {
    FlyIcon,
    FlyDropdown,
  },
  emits: ['menu-item-click'],
  props: {
    menuItems: VueTypes.array.def([]),
  },
  methods: {
    onMenuItemClick(menuItem) {
      this.$emit('menu-item-click', menuItem);
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-menu {
  background-color: #ffffff;
  border: 1px solid $fly-color-grey-6;
  border-radius: 8px;
  box-shadow: 0 8px 16px -4px transparentize($fly-color-grey-3, 0.9);
  flex-direction: column;
  overflow: auto;
  padding: 4px 0;
  position: relative;
  max-height: 320px;
  z-index: 999999;
  width: 100%;

  .fly-menu-item {
    align-items: center;
    background-color: #ffffff;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
      background-color: $fly-color-grey-8;
    }

    &.fly-text--danger {
      .fly-text--ui-small {
        color: $fly-color-red-1;
      }

      .fly-icon {
        path {
          fill: $fly-color-red-1;
        }
      }
    }

    .fly-text--ui-small {
      color: $fly-color-grey-2;
    }

    .fly-icon {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
  }
}
</style>
