<template>
  <FlyMenu
    v-if="menuItems.length"
    :menu-items="menuItems"
    :popper-options="{
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    }"
    @menu-item-click="onMenuItemClick"
  >
    <slot></slot>
  </FlyMenu>
</template>

<script>
import FlyMenu from 'shared/components/Menu/FlyMenu';
import { mapMutations } from 'vuex';
import { setModalOpenState } from 'shared/store/modal/modalMutationTypes';
import CONFIG from 'shared/config';

export default {
  name: 'AppNavbarAppsMenu',
  components: {
    FlyMenu,
  },
  props: {},
  computed: {
    menuItems() {
      return [
        {
          icon: 'ETL',
          key: 'etl',
          text: 'ETL & Reverse ETL',
        },
        {
          icon: 'APIGeneration',
          key: 'api_generation',
          text: 'API Generation',
        },
        {
          icon: 'DWH',
          key: 'dwh',
          text: 'Data Warehouse Insights',
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setModalOpenState,
    }),
    onMenuItemClick({ key }) {
      const url = `${CONFIG.INTEGRATEIO_URL}/apps`;

      window.location = `${url}/?utm_source=FlyData&utm_medium=drop_down&utm_campaign=ui_Integration&app_id=${key}`;
    },
  },
};
</script>

<style lang="scss"></style>
