<template>
  <label
    class="fly-switch"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <input
      type="checkbox"
      class="fly-switch--checkbox-input fly-input--type-checkbox fly-input"
      :disabled="isDisabled"
      :checked="checked"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div
      :class="{
        'fly-box': true,
        'fly-switch--well': true,
        'fly-switch--well--checked': checked,
        'fly-switch--well--disabled': isDisabled,
        'fly-switch--well--hovered': hovered,
        'fly-switch--well--focused': focused,
      }"
    >
      <div class="fly-box fly-switch--thumb"></div>
    </div>
    <div class="fly-box fly-switch--label">
      <slot name="label">
        <span
          v-if="label"
          class="fly-text fly-text--ui-small fly-switch--label-text"
        >
          {{ label }}
        </span>
      </slot>
      <slot>
        <span
          v-if="helpText"
          class="fly-text fly-text--ui-small fly-switch--help-text"
        >
          {{ helpText }}
        </span>
      </slot>
    </div>
  </label>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'FlySwitch',
  props: {
    checked: VueTypes.bool.isRequired,
    disabled: VueTypes.bool.def(false),
    label: VueTypes.string.def(''),
    helpText: VueTypes.string.def(''),
  },
  data() {
    return {
      hovered: false,
      focused: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading || this.disabled;
    },
  },
  methods: {
    onMouseEnter() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.fly-switch {
  cursor: pointer;
  align-items: center;
  position: relative;
  display: flex;

  &.fly-switch--small {
    .fly-switch--well {
      height: 16px;
      width: 30px;
    }

    .fly-switch--thumb {
      height: 12px;
      width: 12px;
      margin: 0 2px;
    }
  }

  .fly-switch--checkbox-input {
    position: absolute;
    opacity: 0;
    left: 5px;
  }

  .fly-switch--well {
    height: 20px;
    width: 36px;
    position: absolute;
    border-radius: 14px;
    background-color: $fly-color-grey-9;
    align-items: center;
    justify-content: flex-start;

    &.fly-switch--well--disabled {
      background-color: $fly-color-grey-7;
    }

    &.fly-switch--well--checked {
      background-color: $fly-color-green-1;
      justify-content: flex-end;
    }
  }

  .fly-switch--thumb {
    height: 16px;
    width: 16px;
    margin: 0 2px;
    position: absolute;
    border-radius: 100%;
    background-color: #ffffff;
  }

  .fly-switch--label {
    flex-direction: column;
    margin-left: 52px;
  }

  .fly-switch--label-text {
    font-weight: 500;
  }

  .fly-switch--help-text {
    color: $fly-color-grey-4;
  }
}
</style>
