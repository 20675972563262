/* eslint-disable vue/no-unused-properties */
import { createApp } from 'vue';
import 'shared/styles/styles.scss';
import * as Sentry from '@sentry/vue';
import { sync } from 'vuex-router-sync';
import Clipboard from 'v-clipboard3';
import VueAuthenticate from 'vue-authenticate-2';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '@jobinsjp/vue3-datatable/dist/style.css';
import App from './app/App.vue';
import router from './router';
import store from './store';
import CONFIG from 'shared/config';
import SOURCE_ENUM from 'web/sources/SOURCE_ENUM';
import getCSRFToken from 'shared/utils/getCSRFToken.js';
import './directives/isLoadingDirective';

sync(store, router);

const validChars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
let array = new Uint8Array(40);
window.crypto.getRandomValues(array);
array = array.map((x) => validChars.charCodeAt(x % validChars.length));
const randomState = String.fromCharCode.apply(null, array);
const codeVerifier = CryptoJS.lib.WordArray.random(43).toString(
  CryptoJS.enc.Base64url,
);
// base64 url encoding of the sha256 hash of code_verifier.
const codeChallenge = CryptoJS.SHA256(codeVerifier).toString(
  CryptoJS.enc.Base64url,
);

const Vue = createApp(App).use(store).use(router).use(Clipboard);
Vue.use(VueAuthenticate, {
  axios: axios.create({
    headers: {
      'X-CSRFToken': getCSRFToken(),
      'Content-Security-Policy': "frame-ancestors 'none'",
    },
  }),
  providers: {
    [SOURCE_ENUM.BING_ADS]: {
      name: SOURCE_ENUM.BING_ADS,
      authorizationEndpoint:
        'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        'openid',
        'offline_access',
        encodeURIComponent('https://ads.microsoft.com/msads.manage'),
      ],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.BING_ADS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.BING_ADS}/`,
    },
    [SOURCE_ENUM.SHOPIFY]: {
      name: SOURCE_ENUM.SHOPIFY,
      authorizationEndpoint: '',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        'read_orders',
        'read_fulfillments',
        'read_customers',
        'read_inventory',
        'read_locations',
        'read_marketing_events',
        'read_products',
        'read_product_listings',
        'read_reports',
        'read_shipping',
      ],
      scopeDelimiter: ',',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.SHOPIFY_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      responseParams: {
        code: 'code',
        clientId: 'clientId',
        redirectUri: 'redirectUri',
        shop: 'shop',
      },
      url: `/api/v1/external_oauth/${SOURCE_ENUM.SHOPIFY}/`,
    },
    [SOURCE_ENUM.EBAY]: {
      name: SOURCE_ENUM.EBAY,
      authorizationEndpoint: 'https://auth.ebay.com/oauth2/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        encodeURIComponent(
          'https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly',
        ),
      ],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.EBAY_PRODUCTION_OAUTH_CLIENT_ID,
      redirect_uri: () => CONFIG.EBAY_PRODUCTION_OAUTH_REDIRECT_URI,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.EBAY}/`,
    },
    [SOURCE_ENUM.GOOGLE_SHEETS]: {
      name: SOURCE_ENUM.GOOGLE_SHEETS,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        encodeURIComponent(
          'https://www.googleapis.com/auth/spreadsheets.readonly',
        ),
        encodeURIComponent(
          'https://www.googleapis.com/auth/drive.metadata.readonly',
        ),
      ],
      accessType: 'offline',
      prompt: 'consent',
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'prompt',
        'access_type',
        'include_granted_scopes',
      ],
      state: randomState,
      includeGrantedScopes: false,
      clientId: CONFIG.GOOGLE_SHEETS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.GOOGLE_SHEETS}/`,
    },
    [SOURCE_ENUM.GOOGLE_ANALYTICS]: {
      name: SOURCE_ENUM.YOUTUBE_ANALYTICS,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        encodeURIComponent(
          'https://www.googleapis.com/auth/analytics.readonly',
        ),
      ],
      accessType: 'offline',
      prompt: 'consent',
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'prompt',
        'access_type',
        'include_granted_scopes',
      ],
      state: randomState,
      includeGrantedScopes: false,
      clientId: CONFIG.GOOGLE_SHEETS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.GOOGLE_SHEETS}/`,
    },
    [SOURCE_ENUM.ZENDESK_CHAT]: {
      name: SOURCE_ENUM.ZENDESK_CHAT,
      authorizationEndpoint: 'https://www.zopim.com/oauth2/authorizations/new',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['read', 'chat'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state', 'subdomain'],
      state: randomState,
      clientId: CONFIG.ZENDESK_CHAT_OAUTH_CLIENT_ID,
      subdomain: '',
      redirect_uri: () => encodeURIComponent(window.location.origin),
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.ZENDESK_CHAT}/`,
    },
    [SOURCE_ENUM.ZENDESK_SELL]: {
      name: SOURCE_ENUM.ZENDESK_SELL,
      authorizationEndpoint: 'https://api.getbase.com/oauth2/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['read'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.ZENDESK_SELL_OAUTH_CLIENT_ID,
      redirect_uri: () => encodeURIComponent(window.location.origin),
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.ZENDESK_SELL}/`,
    },
    [SOURCE_ENUM.ZENDESKSUPPORT]: {
      name: SOURCE_ENUM.ZENDESKSUPPORT,
      authorizationEndpoint: '',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['read'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.ZENDESK_SUPPORT_OAUTH_CLIENT_ID,
      redirect_uri: () => encodeURIComponent(window.location.origin),
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.ZENDESKSUPPORT}/`,
    },
    [SOURCE_ENUM.ADROLL]: {
      name: SOURCE_ENUM.ADROLL,
      authorizationEndpoint: 'https://services.adroll.com/auth/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['all'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.ADROLL_OAUTH_CLIENT_ID,
      redirect_uri: () => CONFIG.ADROLL_OAUTH_REDIRECT_URI,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.ADROLL}/`,
    },
    [SOURCE_ENUM.LINKEDIN_ADS]: {
      name: SOURCE_ENUM.LINKEDIN_ADS,
      authorizationEndpoint: 'https://www.linkedin.com/oauth/v2/authorization',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['r_ads', 'r_ads_reporting'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.LINKEDIN_ADS_OAUTH_CLIENT_ID,
      redirect_uri: () => CONFIG.LINKEDIN_ADS_OAUTH_REDIRECT_URI,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.LINKEDIN_ADS}/`,
    },
    [SOURCE_ENUM.SALESFORCE_MARKETING]: {
      name: SOURCE_ENUM.SALESFORCE_MARKETING,
      authorizationEndpoint: '',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['api', 'refresh_token', 'offline_access'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      responseParams: {
        code: 'code',
        clientId: 'clientId',
        redirectUri: 'redirectUri',
      },
      state: randomState,
      clientId: CONFIG.SALESFORCE_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.SALESFORCE_MARKETING}/`,
    },
    [SOURCE_ENUM.SQUARE]: {
      name: SOURCE_ENUM.SQUARE,
      authorizationEndpoint: 'https://connect.squareup.com/oauth2/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        'BANK_ACCOUNTS_READ',
        'APPOINTMENTS_BUSINESS_SETTINGS_READ',
        'APPOINTMENTS_READ',
        'APPOINTMENTS_ALL_READ',
        'PAYMENTS_READ',
        'CASH_DRAWER_READ',
        'ITEMS_READ',
        'CUSTOMERS_READ',
        'DISPUTES_READ',
        'EMPLOYEES_READ',
        'GIFTCARDS_READ',
        'INVENTORY_READ',
        'INVOICES_READ',
        'TIMECARDS_SETTINGS_READ',
        'TIMECARDS_READ',
        'MERCHANT_PROFILE_READ',
        'LOYALTY_READ',
        'ORDERS_READ',
        'ONLINE_STORE_SITE_READ',
        'ONLINE_STORE_SNIPPETS_READ',
        'SUBSCRIPTIONS_READ',
      ],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state', 'session'],
      state: randomState,
      session: false,
      clientId: CONFIG.SQUARE_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.SQUARE}/production/`,
    },
    [SOURCE_ENUM.GOOGLE_ADS]: {
      name: SOURCE_ENUM.GOOGLE_ADS,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [encodeURIComponent('https://www.googleapis.com/auth/adwords')],
      accessType: 'offline',
      prompt: 'consent',
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'prompt',
        'access_type',
        'include_granted_scopes',
      ],
      state: randomState,
      includeGrantedScopes: false,
      clientId: CONFIG.GOOGLE_SHEETS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.GOOGLE_SHEETS}/`,
    },
    [SOURCE_ENUM.HUBSPOT]: {
      name: SOURCE_ENUM.HUBSPOT,
      authorizationEndpoint: 'https://app-eu1.hubspot.com/oauth/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        'oauth',
        'forms',
        'automation',
        'content',
        'crm.lists.read',
        'crm.objects.contacts.read',
        'crm.schemas.contacts.read',
        'crm.objects.companies.read',
        'crm.objects.deals.read',
        'crm.schemas.companies.read',
        'crm.schemas.deals.read',
        'crm.objects.owners.read',
      ],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      responseParams: {
        code: 'code',
        clientId: 'clientId',
        redirectUri: 'redirectUri',
      },
      state: randomState,
      clientId: CONFIG.HUBSPOT_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.HUBSPOT}/`,
    },
    [SOURCE_ENUM.SNAPCHAT]: {
      name: SOURCE_ENUM.SNAPCHAT,
      authorizationEndpoint: '',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      responseParams: {
        code: 'code',
      },
      redirectUri:
        window.location.origin +
        `/api/v1/external_oauth/${SOURCE_ENUM.SNAPCHAT}/`,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.SNAPCHAT}/`,
    },
    [SOURCE_ENUM.PAYPAL]: {
      name: SOURCE_ENUM.PAYPAL,
      authorizationEndpoint: '',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [],
      accessType: 'offline',
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: '',
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.PAYPAL}/`,
    },
    [SOURCE_ENUM.MAILCHIMP]: {
      name: SOURCE_ENUM.MAILCHIMP,
      authorizationEndpoint: 'https://login.mailchimp.com/oauth2/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [],
      scopeDelimiter: '%20',
      requiredUrlParams: ['state'],
      state: randomState,
      clientId: CONFIG.MAILCHIMP_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.MAILCHIMP}/`,
    },
    [SOURCE_ENUM.TWITTER]: {
      name: SOURCE_ENUM.TWITTER,
      authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
      oauthType: '1.0',
      popupOptions: { width: 580, height: 700 },
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.TWITTER}/`,
    },
    [SOURCE_ENUM.YOUTUBE_ANALYTICS]: {
      name: SOURCE_ENUM.YOUTUBE_ANALYTICS,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        encodeURIComponent('https://www.googleapis.com/auth/youtube.readonly'),
        encodeURIComponent(
          'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
        ),
        encodeURIComponent(
          'https://www.googleapis.com/auth/yt-analytics.readonly',
        ),
      ],
      accessType: 'offline',
      prompt: 'consent',
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'prompt',
        'access_type',
        'include_granted_scopes',
      ],
      state: randomState,
      includeGrantedScopes: false,
      clientId: CONFIG.GOOGLE_SHEETS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.GOOGLE_SHEETS}/`,
    },
    [SOURCE_ENUM.YOUTUBE]: {
      name: SOURCE_ENUM.YOUTUBE,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        encodeURIComponent('https://www.googleapis.com/auth/youtube.readonly'),
        encodeURIComponent(
          'https://www.googleapis.com/auth/youtube.channel-memberships.creator',
        ),
      ],
      accessType: 'offline',
      prompt: 'consent',
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'prompt',
        'access_type',
        'include_granted_scopes',
      ],
      state: randomState,
      includeGrantedScopes: false,
      clientId: CONFIG.YOUTUBE_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.YOUTUBE}/`,
    },
    [SOURCE_ENUM.PINTEREST]: {
      name: SOURCE_ENUM.PINTEREST,
      authorizationEndpoint: 'https://www.pinterest.com/oauth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['ads:read'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope'],
      state: randomState,
      clientId: CONFIG.PINTEREST_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin + '/',
      url: `/api/v1/external_oauth/${SOURCE_ENUM.PINTEREST}/`,
      responsType: 'code',
    },
    [SOURCE_ENUM.TIKTOK_ADS]: {
      name: SOURCE_ENUM.TIKTOK_ADS,
      authorizationEndpoint: 'https://ads.tiktok.com/marketing_api/auth',
      oauthType: '2.0',
      popupOptions: { width: 1020, height: 750 },
      defaultUrlParams: ['app_id', 'state', 'redirect_uri', 'rid'],
      responseParams: {
        code: 'code',
        authCode: 'auth_code',
        id: 'id',
      },
      state: randomState,
      appId: CONFIG.TIKTOK_ADS_OAUTH_APP_ID,
      rid: CONFIG.TIKTOK_ADS_OAUTH_APP_RID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.TIKTOK_ADS}/`,
    },
    [SOURCE_ENUM.INTUIT_QUICKBOOKS]: {
      name: SOURCE_ENUM.INTUIT_QUICKBOOKS,
      authorizationEndpoint: 'https://appcenter.intuit.com/connect/oauth2',
      oauthType: '2.0',
      popupOptions: { width: 1020, height: 750 },
      defaultUrlParams: [
        'client_id',
        'redirect_uri',
        'response_type',
        'scope',
        'state',
      ],
      scope: ['com.intuit.quickbooks.accounting'],
      responseType: 'code',
      state: randomState,
      clientId: CONFIG.INTUIT_QUICKBOOKS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.INTUIT_QUICKBOOKS}/`,
    },
    [SOURCE_ENUM.FACEBOOK]: {
      name: SOURCE_ENUM.FACEBOOK,
      authorizationEndpoint: 'https://www.facebook.com/v19.0/dialog/oauth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['ads_read', 'read_insights'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.FACEBOOK_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.FACEBOOK_PAGES}/`,
    },
    [SOURCE_ENUM.FACEBOOK_PAGES]: {
      name: SOURCE_ENUM.FACEBOOK_PAGES,
      authorizationEndpoint: 'https://www.facebook.com/v19.0/dialog/oauth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'state',
        'response_type',
        'config_id',
        'override_default_response_type',
      ],
      responseType: 'code',
      overrideDefaultResponseType: true,
      state: randomState,
      configId: CONFIG.FACEBOOK_OAUTH_CONFIG_ID,
      clientId: CONFIG.FACEBOOK_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.FACEBOOK_PAGES}/`,
    },
    [SOURCE_ENUM.RINGCENTRAL]: {
      name: SOURCE_ENUM.RINGCENTRAL,
      authorizationEndpoint:
        'https://platform.devtest.ringcentral.com/restapi/oauth/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scopeDelimiter: '%20',
      state: randomState,
      clientId: CONFIG.RINGCENTRAL_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.RINGCENTRAL}/`,
      responsType: 'code',
    },
    [SOURCE_ENUM.GOOGLE_SEARCH_CONSOLE]: {
      name: SOURCE_ENUM.GOOGLE_SEARCH_CONSOLE,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        encodeURIComponent(
          'https://www.googleapis.com/auth/webmasters.readonly',
        ),
      ],
      accessType: 'offline',
      prompt: 'consent',
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'prompt',
        'access_type',
        'include_granted_scopes',
      ],
      state: randomState,
      includeGrantedScopes: false,
      clientId: CONFIG.GOOGLE_SHEETS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.GOOGLE_SHEETS}/`,
    },
    [SOURCE_ENUM.CRITEO]: {
      name: SOURCE_ENUM.CRITEO,
      authorizationEndpoint: 'https://consent.criteo.com/request',
      oauthType: '1.0',
      popupOptions: { width: 580, height: 700 },
      state: randomState,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.CRITEO}/`,
    },
    [SOURCE_ENUM.GITHUB]: {
      name: SOURCE_ENUM.GITHUB,
      authorizationEndpoint: 'https://github.com/login/oauth/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['repo'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['state'],
      state: randomState,
      clientId: CONFIG.GITHUB_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.GITHUB}/`,
    },
    [SOURCE_ENUM.DRIP]: {
      name: SOURCE_ENUM.DRIP,
      authorizationEndpoint: 'https://www.getdrip.com/oauth/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [],
      scopeDelimiter: '%20',
      requiredUrlParams: ['state'],
      state: randomState,
      clientId: CONFIG.DRIP_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.DRIP}/`,
    },
    [SOURCE_ENUM.SLACK]: {
      name: SOURCE_ENUM.SLACK,
      authorizationEndpoint: 'https://slack.com/oauth/v2/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        'channels:history',
        'channels:read',
        'files:read',
        'groups:read',
        'links:read',
        'reactions:read',
        'remote_files:read',
        'team:read',
        'usergroups:read',
        'users.profile:read',
        'users:read',
        'users:read.email',
      ],
      scopeDelimiter: '%20',
      requiredUrlParams: ['state', 'scope'],
      state: randomState,
      clientId: CONFIG.SLACK_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.SLACK}/`,
    },
    [SOURCE_ENUM.SALESFORCE]: {
      name: SOURCE_ENUM.SALESFORCE,
      authorizationEndpoint: '',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['refresh_token', 'offline_access', 'api'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      state: randomState,
      clientId: CONFIG.SALESFORCE_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      responseParams: {
        code: 'code',
        clientId: 'clientId',
        redirectUri: 'redirectUri',
      },
      url: `/api/v1/external_oauth/${SOURCE_ENUM.SALESFORCE_MARKETING}/`,
    },
    [SOURCE_ENUM.SALESFORCEV2]: {
      name: SOURCE_ENUM.SALESFORCEV2,
      authorizationEndpoint: '',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: ['api', 'refresh_token', 'offline_access'],
      scopeDelimiter: '%20',
      requiredUrlParams: ['scope', 'state'],
      responseParams: {
        code: 'code',
        clientId: 'clientId',
        redirectUri: 'redirectUri',
      },
      state: randomState,
      clientId: CONFIG.SALESFORCE_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.SALESFORCE_MARKETING}/`,
    },
    [SOURCE_ENUM.AMAZON_ADS]: {
      name: SOURCE_ENUM.AMAZON_ADS,
      authorizationEndpoint: '',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [encodeURIComponent('advertising::campaign_management')],
      scopeDelimiter: '%20',
      requiredUrlParams: ['state', 'scope'],
      state: randomState,
      clientId: CONFIG.AMAZON_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.AMAZON_ADS}/`,
    },
    [SOURCE_ENUM.AMAZON_MARKETPLACE]: {
      name: SOURCE_ENUM.AMAZON_MARKETPLACE,
      authorizationEndpoint:
        'https://sellercentral.amazon.com/apps/authorize/consent',
      oauthType: '2.0',
      popupOptions: { width: 700, height: 700 },
      scope: [],
      scopeDelimiter: '%20',
      requiredUrlParams: ['state', 'application_id', 'version', 'scope'],
      state: randomState,
      version: CONFIG.AMAZON_MARKETPLACE_MODE === 'production' ? '' : 'beta',
      applicationId: CONFIG.AMAZON_MARKETPLACE_OAUTH_CLIENT_ID,
      clientId: CONFIG.AMAZON_MARKETPLACE_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.AMAZON_MARKETPLACE}/`,
      responseParams: {
        spapi_oauth_code: 'spapi_oauth_code',
        state: 'state',
      },
    },
    [SOURCE_ENUM.GA4]: {
      name: SOURCE_ENUM.GA4,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        encodeURIComponent(
          'https://www.googleapis.com/auth/analytics.readonly',
        ),
      ],
      accessType: 'offline',
      prompt: 'consent',
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'prompt',
        'access_type',
        'include_granted_scopes',
      ],
      state: randomState,
      includeGrantedScopes: false,
      clientId: CONFIG.GOOGLE_SHEETS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.GA4}/`,
    },
    [SOURCE_ENUM.GA360]: {
      name: SOURCE_ENUM.GA360,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        encodeURIComponent(
          'https://www.googleapis.com/auth/cloud-platform.read-only',
        ),
      ],
      accessType: 'offline',
      prompt: 'consent',
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'prompt',
        'access_type',
        'include_granted_scopes',
      ],
      state: randomState,
      includeGrantedScopes: false,
      clientId: CONFIG.GOOGLE_SHEETS_OAUTH_CLIENT_ID,
      redirectUri: window.location.origin,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.GA360}/`,
    },
    [SOURCE_ENUM.AIRTABLE]: {
      name: SOURCE_ENUM.AIRTABLE,
      authorizationEndpoint: 'https://airtable.com/oauth2/v1/authorize',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 700 },
      scope: [
        'data.records:read',
        'data.recordComments:read',
        'schema.bases:read',
      ],
      scopeDelimiter: '%20',
      requiredUrlParams: [
        'scope',
        'state',
        'code_challenge',
        'code_challenge_method',
      ],
      responseParams: {
        code: 'code',
        response_type: 'response_type',
        code_challenge: 'code_challenge',
        code_challenge_method: 'code_challenge_method',
      },
      state: randomState,
      codeChallenge: codeChallenge,
      codeChallengeMethod: 'S256',
      responseType: 'code',
      clientId: CONFIG.AIRTABLE_OAUTH_CLIENT_ID,
      redirectUri: CONFIG.AIRTABLE_OAUTH_REDIRECT_URI,
      url: `/api/v1/external_oauth/${SOURCE_ENUM.AIRTABLE}/?code_verifier=${codeVerifier}`,
    },
  },
});

if (CONFIG.SENTRY_DSN && CONFIG.SENTRY_DSN !== 'fake') {
  Sentry.init({
    Vue,
    dsn: CONFIG.SENTRY_DSN,
  });
}

Vue.directive('isLoading', {
  mounted(el, binding) {
    if (binding.value) {
      el.classList.add('fly-loading'); // Add class when the value is true
    } else {
      el.classList.remove('fly-loading'); // Remove class otherwise
    }
  },
  updated(el, binding) {
    if (binding.value) {
      el.classList.add('fly-loading');
    } else {
      el.classList.remove('fly-loading');
    }
  },
});

Vue.directive('click-outside', {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
});

window.store = store;
window.app = Vue;

Vue.mount('#app');
