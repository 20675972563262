import createServiceStore from 'shared/utils/createServiceStore.js';
import { DESTINATION_METRIC_SCHEMA_SERVICE_KEY } from './destinationMetricSchemaEnum.js';
import destinationMetricSchemaService from './destinationMetricSchemaService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import { find, get, map } from 'lodash';
import {
  getColumnsForTablesInDestinationMetricSchema,
  getSchemaInDestinationMetricSchema,
  getTablesInDestinationMetricSchema,
} from 'web/destinationMetricSchema/destinationMetricSchemaGetterTypes.js';

export default createServiceStore({
  serviceName: DESTINATION_METRIC_SCHEMA_SERVICE_KEY,
  service: destinationMetricSchemaService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  getters: {
    [getSchemaInDestinationMetricSchema]: (state) => (id) => {
      return get(state, [id, 'schema']) || [];
    },
    [getTablesInDestinationMetricSchema]: (state, getters) => (id) => {
      const schema = getters[getSchemaInDestinationMetricSchema](id);
      return map(schema, 'table');
    },
    [getColumnsForTablesInDestinationMetricSchema]: (state, getters) => (
      id,
      table
    ) => {
      const schema = getters[getSchemaInDestinationMetricSchema](id);
      const _table = find(schema, { table }) || {};
      return _table.columns || [];
    },
  },
});
