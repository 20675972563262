import { get } from 'lodash';
import moment from 'moment-timezone';
import { initAction } from './appActionTypes.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { initializing } from '../loading/loadingKeys.js';
import { isAuthenticated, shouldRefreshApp } from './appGetterTypes.js';
import { getLoggedInUserAction } from 'web/user/userActionTypes';
import userServiceGetters from 'web/user/userServiceGetters';
import { getShowSidebar } from 'web/app/appGetterTypes';
import {
  setPusherSubscriptionChannels,
  setRefreshApp,
  setShowSidebar,
} from 'web/app/appMutationTypes';
import {
  initIntercomForUserAction,
  setDefaultTimezoneAction,
  showIntercomAction,
  shutdownIntercomAction,
  updateIntercomAction,
} from 'web/app/appActionTypes';
import userServiceActions from 'web/user/userServiceActions';
import userServiceMutations from 'web/user/userServiceMutations';
import CONFIG from 'shared/config';
import {
  ALL,
  getClientChannel,
  getUserChannel,
} from 'shared/clients/pusher/CHANNELS_ENUM';
import setupSentryForUser from 'web/app/setupSentryForUser';
import { setAllFeatureTogglesForLoggedInUserAction } from 'web/featureToggle/featureToggleActionTypes';

export default {
  state: {
    refreshApp: false,
    showSidebar: true,
  },
  getters: {
    [isAuthenticated]: (state, getters, rootState, rootGetters) =>
      !!rootGetters[userServiceGetters.get]('me'),
    [getShowSidebar]: (state) => state.showSidebar,
    [shouldRefreshApp]: (state) => state.refreshApp,
  },
  mutations: {
    [setShowSidebar](state, value) {
      state.showSidebar = value;
    },
    [setRefreshApp](state, data) {
      state.refreshApp = data.refresh;
    },
    [setPusherSubscriptionChannels](state, channels) {
      state.pusherSubscriptionChannels = channels;
    },
  },
  actions: {
    async [initAction]({ commit, dispatch }) {
      commit(setLoading, { key: initializing, value: true });
      if (CONFIG.ENABLE_MIRAGE) {
        await import('../mirage');
      }
      try {
        const user = await dispatch(getLoggedInUserAction);
        await dispatch(setDefaultTimezoneAction, user);
        dispatch(setAllFeatureTogglesForLoggedInUserAction);

        if (CONFIG.SENTRY_DSN && CONFIG.SENTRY_DSN !== 'fake') {
          setupSentryForUser({ user });
        }

        if (CONFIG.NODE_ENV === 'production' || CONFIG.NODE_ENV === 'staging') {
          dispatch(initIntercomForUserAction, user);
        }
        if (CONFIG.PUSHER_APP_KEY !== 'fake') {
          commit(setPusherSubscriptionChannels, [
            ALL,
            getUserChannel(user.id),
            getClientChannel(user.client.id),
          ]);
        }
      } finally {
        commit(setLoading, { key: initializing, value: false });
      }
    },
    async [setDefaultTimezoneAction]({ commit, dispatch }, user) {
      let momentTimezoneToSet = get(user, 'time_zone', null);
      if (!momentTimezoneToSet) {
        const updatedUser = await dispatch(userServiceActions.update, {
          idOrQuery: 'me',
          data: {
            time_zone: moment.tz.guess(true),
          },
        });

        commit(userServiceMutations.update, {
          id: 'me',
          payload: {
            time_zone: updatedUser.time_zone,
          },
        });
        momentTimezoneToSet = updatedUser.time_zone;
      }
      moment.tz.setDefault(momentTimezoneToSet);
    },
    [initIntercomForUserAction](store, user) {
      const intercomSettings = {
        hide_default_launcher: false,
        app_id: CONFIG.INTERCOM_APP_ID,
        user_id: user.id, // User ID
        user_hash: user.intercom_hmac, // HMAC using SHA-256
        name: user.full_name,
        email: user.email,
        company: {
          company_id: user.client.id,
          name: user.client.name,
        },
        Product: 'flydata',
      };
      window.Intercom('boot', intercomSettings);
    },
    [showIntercomAction]() {
      window.Intercom('show');
    },
    [updateIntercomAction]() {
      window.Intercom &&
        window.Intercom('update', {
          last_request_at: parseInt(new Date().getTime() / 1000),
        });
    },
    [shutdownIntercomAction]() {
      window.Intercom('shutdown');
    },
  },
};
