<template>
  <label
    class="fly-box fly-radio"
    v-bind="$attrs"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <input
      type="radio"
      class="fly-radio--input"
      :disabled="isDisabled"
      :checked="checked"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
    />
    <div
      :class="{
        'fly-box': true,
        'fly-radio--box': true,
        checked: checked,
        disabled: isDisabled,
      }"
    />
    <div class="fly-box fly-radio--label-box">
      <slot name="label">
        <div
          v-if="label"
          :class="
            'fly-radio--label fly-text fly-text--ui-small' +
            (isDisabled ? 'disabled' : '')
          "
        >
          {{ label }}
        </div>
      </slot>
      <slot>
        <div
          v-if="helpText"
          :class="
            'fly-text fly-text--ui-small fly-radio--help-text ' +
            (isDisabled ? 'disabled' : '')
          "
          v-bind="helpTextProps"
        >
          {{ helpText }}
        </div>
      </slot>
    </div>
  </label>
</template>

<script>
import VueTypes from 'vue-types';
import LoadingMixin from 'shared/components/Interaction/LoadingMixin';
import { isEqual } from 'lodash';

export default {
  name: 'FlyRadio',
  props: {
    value: VueTypes.any.isRequired,
    selectedValue: VueTypes.any.isRequired,
    disabled: VueTypes.bool.def(false),
    label: VueTypes.string.def(''),
    helpText: VueTypes.string.def(''),
  },
  mixins: [LoadingMixin],
  emits: ['change'],
  data() {
    return {
      hovered: false,
      focused: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading || this.disabled;
    },
    checked() {
      return isEqual(this.value, this.selectedValue);
    },
  },
  methods: {
    onMouseEnter() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    onChange() {
      this.$emit('change', this.value);
    },
  },
  inheritAttrs: false,
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-radio {
  cursor: pointer;
  align-items: center;
  position: relative;

  .fly-radio--box {
    height: 20px;
    width: 20px;
    position: absolute;
    border-radius: 100%;
    background-color: #ffffff;
    border: 2px solid $fly-color-grey-5;
    box-shadow: inset 0 0 0 3px $fly-color-white-1;

    &:hover,
    &:focus {
      border-color: $fly-color-blue-1;
    }

    &.disabled {
      background-color: $fly-color-grey-8;
      border-color: $fly-color-grey-8;
    }

    &.checked {
      background-color: $fly-color-blue-1;
      border-color: $fly-color-blue-1;
    }
  }

  .fly-radio--label-box {
    flex-direction: column;
    margin-left: 36px;
  }

  .fly-radio--label {
    font-weight: 500;

    &.disabled {
      color: $fly-color-grey-5;
    }
  }

  .fly-radio--help-text {
    color: $fly-color-grey-4;

    &.disabled {
      color: $fly-color-grey-5;
    }
  }

  .fly-radio--input {
    position: absolute;
  }
}
</style>
