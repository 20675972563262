import store from '../store';
import { createRouter, createWebHashHistory } from 'vue-router';
import { isAuthenticated, shouldRefreshApp } from '../app/appGetterTypes.js';
import { initAction } from '../app/appActionTypes.js';
import registerAuthGuardForRouter from 'shared/utils/registerAuthGuardForRouter.js';
import { getAllErrors } from '../error/errorGetterTypes.js';
import PIPELINE_STATUS_GROUP_ENUM from 'web/pipelines/PIPELINE_STATUS_GROUP_ENUM';
import { updateIntercomAction } from 'web/app/appActionTypes';
import {
  hasActiveSubscription,
  isInActiveTrial,
  isUserTrialExpired,
} from 'web/user/userDataGetterTypes';
import CONFIG from 'shared/config';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "login" */ './Home.vue'),
    meta: {
      requiresAuth: true,
      preventWhenLoggedIn: false,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () =>
      import(/* webpackChunkName: "login" */ '../auth/PrivacyView.vue'),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: false,
    },
  },
  {
    path: '/tos',
    name: 'tos',
    component: () =>
      import(/* webpackChunkName: "login" */ '../auth/ToSView.vue'),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../auth/LoginView.vue'),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => {
      window.location = `${CONFIG.INTEGRATEIO_URL}/auth/login`;
    },
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/verify-email',
    name: 'verifyEmail',
    component: () =>
      import(
        /* webpackChunkName: "passwordReset" */ '../auth/VerifyEmailView.vue'
      ),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/email-sent',
    name: 'passwordEmailSent',
    component: () =>
      import(
        /* webpackChunkName: "passwordReset" */ '../auth/PasswordEmailSentView.vue'
      ),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () =>
      import(
        /* webpackChunkName: "passwordReset" */ '../auth/ResetPasswordView.vue'
      ),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/invalid-token',
    name: 'invalidPasswordResetToken',
    component: () => {
      window.location = `${CONFIG.INTEGRATEIO_URL}/auth/login`;
    },
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/invalid-invite-token',
    name: 'invalidUserInviteToken',
    component: () => {
      window.location = `${CONFIG.INTEGRATEIO_URL}/auth/login`;
    },
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () =>
      import(/* webpackChunkName: "login" */ '../auth/SignupView.vue'),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/email-signup',
    name: 'emailSignup',
    component: () =>
      import(/* webpackChunkName: "login" */ '../auth/EmailSignupView.vue'),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/inactive-client',
    name: 'inactiveClient',
    component: () =>
      import(/* webpackChunkName: "login" */ '../auth/InactiveClientView.vue'),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/user-confirmation/:token',
    name: 'userConfirmation',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '../auth/UserConfirmationView.vue'
      ),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/user-invite/:token',
    name: 'userInvite',
    component: () =>
      import(/* webpackChunkName: "login" */ '../auth/UserInviteView.vue'),
    meta: {
      requiresAuth: false,
      preventWhenLoggedIn: true,
    },
  },
  {
    path: '/pipelines',
    component: () =>
      import(
        /* webpackChunkName: "pipelinesList" */ '../pipelines/PipelinesView.vue'
      ),
    children: [
      {
        path: '',
        name: 'pipelines',
        redirect: {
          name: 'pipelinesList',
          query: { status: PIPELINE_STATUS_GROUP_ENUM.ACTIVE },
        },
      },
      {
        path: 'list',
        name: 'pipelinesList',
        component: () =>
          import(
            /* webpackChunkName: "pipelinesList" */ '../pipelines/list/PipelinesListView.vue'
          ),
      },
      {
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "pipelinesDetail" */ '../pipelines/details/PipelineDetailsView'
          ),
        children: [
          {
            path: '',
            name: 'pipelineDetail',
            redirect: {
              name: 'pipelineDetailOverview',
            },
          },
          {
            path: 'overview',
            name: 'pipelineDetailOverview',
            component: () =>
              import(
                /* webpackChunkName: "pipelinesDetail" */ '../pipelines/details/overview/PipelineDetailsOverviewView'
              ),
          },
          {
            path: 'settings',
            component: () =>
              import(
                /* webpackChunkName: "pipelinesDetail" */ '../pipelines/details/settings/PipelineDetailsSettingsView'
              ),
            children: [
              {
                path: '',
                name: 'pipelineDetailSettings',
                redirect: {
                  name: 'pipelineDetailSchemaConfig',
                },
              },
              {
                path: 'schema_config',
                name: 'pipelineDetailSchemaConfig',
                component: () =>
                  import(
                    /* webpackChunkName: "pipelinesDetail" */ '../pipelines/details/settings/PipelineDetailsSchemaConfig'
                  ),
              },
              {
                path: 'schema_maintenance',
                name: 'pipelineDetailSchemaMaintenance',
                component: () =>
                  import(
                    /* webpackChunkName: "pipelinesDetail" */ '../pipelines/details/settings/PipelineDetailsSchemaMaintenance'
                  ),
              },
            ],
          },
          {
            path: 'logs',
            name: 'pipelineDetailLogs',
            component: () =>
              import(
                /* webpackChunkName: "pipelinesDetail" */ '../pipelines/details/logs/PipelineDetailsLogsView'
              ),
          },
          {
            path: 'hooks',
            name: 'pipelineDetailHooks',
            component: () =>
              import(
                /* webpackChunkName: "pipelinesDetail" */ '../pipelines/details/hooks/PipelineDetailsHooksView'
              ),
          },
        ],
      },
      {
        path: 'setup/:id',
        name: 'pipelineSetup',
        component: () =>
          import(
            /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/PipelineCreateView.vue'
          ),
        children: [
          {
            path: 'source/select',
            name: 'pipelineCreateSourceSelect',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/source/PipelineSelectSourceView.vue'
              ),
            meta: {
              stepGroup: 'source',
              index: 0,
            },
          },
          {
            path: 'source/prerequisites',
            name: 'pipelineCreateSourcePrerequisites',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/docs/PipelineCreateDocsView.vue'
              ),
            meta: {
              stepGroup: 'source',
              index: 1,
            },
          },
          {
            path: 'source/create',
            name: 'pipelineCreateSourceCreate',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/source/PipelineCreateSourceView.vue'
              ),
            meta: {
              stepGroup: 'source',
              index: 2,
            },
          },
          {
            path: 'source/review',
            name: 'pipelineCreateSourceReview',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/source/PipelineReviewSourceView.vue'
              ),
            meta: {
              stepGroup: 'source',
              index: 3,
            },
          },
          {
            path: 'destination/select',
            name: 'pipelineCreateDestinationSelect',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/destination/PipelineSelectDestinationView.vue'
              ),
            meta: {
              stepGroup: 'destination',
              index: 4,
            },
          },
          {
            path: 'destination/prerequisites',
            name: 'pipelineCreateDestinationPrerequisites',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/docs/PipelineCreateDocsView.vue'
              ),
            meta: {
              stepGroup: 'destination',
              index: 5,
            },
          },
          {
            path: 'destination/review',
            name: 'pipelineCreateDestinationReview',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/destination/PipelineReviewDestinationView.vue'
              ),
            meta: {
              stepGroup: 'destination',
              index: 6,
            },
          },
          {
            path: 'destination/create',
            name: 'pipelineCreateDestinationCreate',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/destination/PipelineCreateDestinationView.vue'
              ),
            meta: {
              stepGroup: 'destination',
              index: 7,
            },
          },
          {
            path: 'schema-settings',
            name: 'pipelineCreateSchemaSettings',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/schema/PipelineCreateSchemaSettingsView.vue'
              ),
            meta: {
              stepGroup: 'schema',
              index: 8,
            },
          },
          {
            path: 'sync-settings',
            name: 'pipelineCreateSyncSettings',
            component: () =>
              import(
                /* webpackChunkName: "pipelineCreate" */ '../pipelines/create/settings/PipelineCreateSyncSettingsView.vue'
              ),
            meta: {
              stepGroup: 'settings',
              index: 9,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/sources',
    component: () =>
      import(/* webpackChunkName: "sources" */ '../sources/SourcesView.vue'),
    children: [
      {
        path: '',
        name: 'sources',
        redirect: {
          name: 'sourcesList',
        },
      },
      {
        path: 'list',
        name: 'sourcesList',
        component: () =>
          import(
            /* webpackChunkName: "sources" */ '../sources/list/SourcesListView.vue'
          ),
      },
      {
        path: 'create',
        component: () =>
          import(
            /* webpackChunkName: "sources" */ '../sources/create/SourceCreateView.vue'
          ),
        children: [
          {
            path: '',
            name: 'sourcesCreate',
            redirect: {
              name: 'sourcesCreateSelect',
            },
          },
          {
            path: 'select',
            name: 'sourcesCreateSelect',
            component: () =>
              import(
                /* webpackChunkName: "sources" */ '../sources/create/SourceCreateSelectView.vue'
              ),
          },
          {
            path: 'prerequisites',
            name: 'sourcesCreatePrerequisites',
            component: () =>
              import(
                /* webpackChunkName: "sources" */ '../common/NodeCreateDocsView.vue'
              ),
          },
          {
            path: 'details',
            name: 'sourcesCreateDetails',
            component: () =>
              import(
                /* webpackChunkName: "sources" */ '../sources/create/SourceCreateDetailsView.vue'
              ),
          },
        ],
      },
      {
        path: ':id',
        name: 'sourceDetail',
        component: () =>
          import(
            /* webpackChunkName: "sources" */ '../sources/details/SourceDetailView.vue'
          ),
      },
      {
        path: ':id/edit',
        name: 'sourceEdit',
        component: () =>
          import(
            /* webpackChunkName: "pipelineCreate" */ '../sources/edit/EditSourceView.vue'
          ),
        meta: {
          stepGroup: 'source',
          index: 2,
        },
      },
    ],
  },
  {
    path: '/destinations',
    component: () =>
      import(
        /* webpackChunkName: "destinations" */ '../destinations/DestinationsView.vue'
      ),
    children: [
      {
        path: '',
        name: 'destinations',
        redirect: {
          name: 'destinationsList',
        },
      },
      {
        path: 'list',
        name: 'destinationsList',
        component: () =>
          import(
            /* webpackChunkName: "destinations" */ '../destinations/list/DestinationsListView.vue'
          ),
      },
      {
        path: 'create',
        component: () =>
          import(
            /* webpackChunkName: "destinations" */ '../destinations/create/DestinationCreateView.vue'
          ),
        children: [
          {
            path: '',
            name: 'destinationsCreate',
            redirect: {
              name: 'destinationsCreateSelect',
            },
          },
          {
            path: 'select',
            name: 'destinationsCreateSelect',
            component: () =>
              import(
                /* webpackChunkName: "destinations" */ '../destinations/create/DestinationCreateSelectView.vue'
              ),
          },
          {
            path: 'prerequisites',
            name: 'destinationsCreatePrerequisites',
            component: () =>
              import(
                /* webpackChunkName: "destinations" */ '../common/NodeCreateDocsView.vue'
              ),
          },
          {
            path: 'details',
            name: 'destinationsCreateDetails',
            component: () =>
              import(
                /* webpackChunkName: "destinations" */ '../destinations/create/DestinationCreateDetailsView.vue'
              ),
          },
        ],
      },
      {
        path: ':id',
        name: 'destinationDetail',
        component: () =>
          import(
            /* webpackChunkName: "destinations" */ '../destinations/details/DestinationDetailView.vue'
          ),
      },
      {
        path: ':id/edit',
        name: 'destinationEdit',
        component: () =>
          import(
            /* webpackChunkName: "pipelineCreate" */ '../destinations/edit/EditDestinationView.vue'
          ),
        meta: {
          stepGroup: 'destination',
          index: 2,
        },
      },
    ],
  },
  {
    path: '/observability',
    component: () =>
      import(
        /* webpackChunkName: "alerts" */ '../observability/ObservabilityView.vue'
      ),
    children: [
      {
        path: '',
        name: 'observability',
        redirect: {
          name: 'alertsList',
        },
      },
      {
        path: 'alerts/list',
        name: 'alertsList',
        component: () =>
          import(
            /* webpackChunkName: "alerts" */ '../alerts/list/AlertsListView.vue'
          ),
      },
      {
        path: 'metrics/list',
        name: 'metricsList',
        component: () =>
          import(
            /* webpackChunkName: "alerts" */ '../metrics/list/MetricListView.vue'
          ),
      },
    ],
  },
  {
    path: '/alerts',
    component: () =>
      import(/* webpackChunkName: "alerts" */ '../alerts/AlertsView.vue'),
    children: [
      {
        path: 'create',
        component: () =>
          import(
            /* webpackChunkName: "alerts" */ '../alerts/create/AlertCreateView.vue'
          ),
        children: [
          {
            path: '',
            name: 'alertCreate',
            redirect: {
              name: 'alertCreateSetup',
            },
          },
          {
            path: 'setup',
            name: 'alertCreateSetup',
            component: () =>
              import(
                /* webpackChunkName: "alerts" */ '../alerts/create/AlertCreateSetupView.vue'
              ),
          },
        ],
      },
      {
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "alerts" */ '../alerts/details/AlertDetailView.vue'
          ),
        children: [
          {
            path: '',
            name: 'alertDetail',
            redirect: {
              name: 'alertDetailOverview',
              query: {
                range: 'day',
              },
            },
          },
          {
            path: 'overview',
            name: 'alertDetailOverview',
            component: () =>
              import(
                /* webpackChunkName: "alerts" */ '../alerts/details/AlertDetailOverviewView.vue'
              ),
          },
          {
            path: 'settings',
            name: 'alertDetailSettings',
            component: () =>
              import(
                /* webpackChunkName: "alerts" */ '../alerts/details/AlertDetailSettingsView.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '../settings/SettingsView.vue'),
    children: [
      {
        path: '',
        name: 'settings',
        redirect: {
          name: 'accountSettings',
        },
      },
      {
        // Account will be rendered inside Setting's <router-view>
        // when /settings/account is matched
        path: 'account',
        name: 'accountSettings',
        component: () => import('../settings/account/AccountView.vue'),
      },
      {
        path: 'billing',
        name: 'billingSettings',
        component: () => import('../settings/billing/BillingView.vue'),
      },
      {
        path: 'users',
        name: 'usersSettings',
        component: () => import('../settings/users/UsersView.vue'),
      },
      {
        path: 'hooks',
        component: () => import('../settings/hooks/HooksView.vue'),
        children: [
          {
            path: '',
            name: 'hooks',
            redirect: {
              name: 'hooksList',
            },
          },
          {
            path: 'list',
            name: 'hooksList',
            component: () =>
              import(
                /* webpackChunkName: "hooks" */ '../settings/hooks/list/HooksListView.vue'
              ),
          },
          // {
          //   path: 'create',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "hooks" */ '../hooks/create/HookCreateView.vue'
          //     ),
          //   children: [
          //     {
          //       path: '',
          //       name: 'hooksCreate',
          //       redirect: {
          //         name: 'hooksCreateSelect',
          //       },
          //     },
          //     {
          //       path: 'select',
          //       name: 'hooksCreateSelect',
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "hooks" */ '../hooks/create/HookCreateSelectView.vue'
          //         ),
          //     },
          //     {
          //       path: 'prerequisites',
          //       name: 'hooksCreatePrerequisites',
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "hooks" */ '../common/NodeCreateDocsView.vue'
          //         ),
          //     },
          //     {
          //       path: 'details',
          //       name: 'hooksCreateDetails',
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "hooks" */ '../hooks/create/HookCreateDetailsView.vue'
          //         ),
          //     },
          //   ],
          // },
          {
            path: ':id',
            name: 'hookDetail',
            component: () =>
              import(
                /* webpackChunkName: "hooks" */ '../settings/hooks/details/HookDetailView.vue'
              ),
          },
          // {
          //   path: ':id/edit',
          //   name: 'hookEdit',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "pipelineCreate" */ '../hooks/edit/EditHookView.vue'
          //     ),
          //   meta: {
          //     stepGroup: 'hook',
          //     index: 2,
          //   },
          // },
        ],
      },
      {
        path: 'usage',
        name: 'usageSettings',
        component: () => import('../settings/usage/UsageView.vue'),
      },
      {
        path: 'usage/:id',
        name: 'pipelineUsageSettings',
        component: () =>
          import('../settings/usage/pipeline/PipelineUsageView.vue'),
      },
      {
        path: 'notifications',
        name: 'notificationsSettings',
        component: () =>
          import('../settings/notifications/NotificationsView.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

registerAuthGuardForRouter(router, store, {
  isAuthenticatedGetter: isAuthenticated,
  initAction,
  allErrorsGetter: getAllErrors,
  updateIntercomAction,
  shouldRefreshApp,
  isUserTrialExpiredGetter: isUserTrialExpired,
  trialExpiredView: {
    name: 'billingSettings',
  },
  hasActiveSubscriptionGetter: hasActiveSubscription,
  isInActiveTrialGetter: isInActiveTrial,
});

export default router;
