import createServiceStore from 'shared/utils/createServiceStore.js';
import { METRICS_SERVICE_KEY } from './metricsEnum.js';
import metricsService from './metricsService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';

export default createServiceStore({
  serviceName: METRICS_SERVICE_KEY,
  service: metricsService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
});
