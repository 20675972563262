<template>
  <FlySingleSelect
    :label="label"
    :options="options"
    :search="false"
    :value="_value"
    :error="error"
    @change="onSelect"
  />
</template>

<script>
import FlySingleSelect from 'shared/components/Form/FlySingleSelect.vue';
import VueTypes from 'vue-types';
import CLOUD_PROVIDER_ENUM from 'web/common/CLOUD_PROVIDER_ENUM.js';
import CLOUD_PROVIDER_REGION_ENUM from 'web/common/CLOUD_PROVIDER_REGION_ENUM.js';
import { find } from 'lodash';
import sift from 'sift';

export default {
  name: 'CloudRegionSingleSelect',
  components: {
    FlySingleSelect,
  },
  props: {
    label: VueTypes.string.def('Region'),
    provider: VueTypes.number.def(null),
    value: VueTypes.number.def(null),
    filter: VueTypes.func.def(() => true),
    error: VueTypes.string.def(''),
  },
  emits: ['change'],
  computed: {
    options() {
      return CLOUD_PROVIDER_REGION_ENUM[
        this.provider || CLOUD_PROVIDER_ENUM.AWS
      ].filter(sift(this.filter));
    },
    _value() {
      return find(this.options, { value: this.value });
    },
  },
  methods: {
    onSelect({ value } = {}) {
      this.$emit('change', value);
    },
  },
};
</script>

<style></style>
