<template>
  <FlyTooltip :tooltip="text">
    <FlyLink
      class="fly-app-navbar-menu-item"
      :is-active="isActive"
      v-bind="$attrs"
    >
      <FlyIcon v-if="icon" class="fly-icon" :name="icon" />
    </FlyLink>
  </FlyTooltip>
</template>

<script>
import VueTypes from 'vue-types';
import FlyLink from 'shared/components/Link/FlyLink.vue';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import FlyTooltip from 'shared/components/Tooltip/FlyTooltip.vue';

export default {
  name: 'AppNavbarMenuItem',
  props: {
    icon: VueTypes.string.def(''),
    isActive: VueTypes.bool.def(false),
    text: VueTypes.string.def(''),
  },
  components: {
    FlyLink,
    FlyTooltip,
    FlyIcon,
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.fly-app-navbar-menu-item {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 8px 0;
  text-decoration: none;
  opacity: 0.5;

  &.fly-active,
  &:hover {
    opacity: 1;
  }

  &.fly-active {
    opacity: 1;
    box-shadow: inset 3px 0 0 0 $fly-color-blue-1;
  }

  .fly-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
