export default Object.freeze({
  ['AED']: {
    label: 'AED',
    value: 'AED',
  },
  ['AFN']: {
    label: 'AFN',
    value: 'AFN',
  },
  ['ALL']: {
    label: 'ALL',
    value: 'ALL',
  },
  ['AMD']: {
    label: 'AMD',
    value: 'AMD',
  },
  ['ANG']: {
    label: 'ANG',
    value: 'ANG',
  },
  ['AOA']: {
    label: 'AOA',
    value: 'AOA',
  },
  ['ARS']: {
    label: 'ARS',
    value: 'ARS',
  },
  ['AUD']: {
    label: 'AUD',
    value: 'AUD',
  },
  ['AWG']: {
    label: 'AWG',
    value: 'AWG',
  },
  ['AZN']: {
    label: 'AZN',
    value: 'AZN',
  },
  ['BAM']: {
    label: 'BAM',
    value: 'BAM',
  },
  ['BBD']: {
    label: 'BBD',
    value: 'BBD',
  },
  ['BDT']: {
    label: 'BDT',
    value: 'BDT',
  },
  ['BGN']: {
    label: 'BGN',
    value: 'BGN',
  },
  ['BHD']: {
    label: 'BHD',
    value: 'BHD',
  },
  ['BIF']: {
    label: 'BIF',
    value: 'BIF',
  },
  ['BMD']: {
    label: 'BMD',
    value: 'BMD',
  },
  ['BND']: {
    label: 'BND',
    value: 'BND',
  },
  ['BOB']: {
    label: 'BOB',
    value: 'BOB',
  },
  ['BRL']: {
    label: 'BRL',
    value: 'BRL',
  },
  ['BSD']: {
    label: 'BSD',
    value: 'BSD',
  },
  ['BTC']: {
    label: 'BTC',
    value: 'BTC',
  },
  ['BTN']: {
    label: 'BTN',
    value: 'BTN',
  },
  ['BWP']: {
    label: 'BWP',
    value: 'BWP',
  },
  ['BYN']: {
    label: 'BYN',
    value: 'BYN',
  },
  ['BYR']: {
    label: 'BYR',
    value: 'BYR',
  },
  ['BZD']: {
    label: 'BZD',
    value: 'BZD',
  },
  ['CAD']: {
    label: 'CAD',
    value: 'CAD',
  },
  ['CDF']: {
    label: 'CDF',
    value: 'CDF',
  },
  ['CHF']: {
    label: 'CHF',
    value: 'CHF',
  },
  ['CLF']: {
    label: 'CLF',
    value: 'CLF',
  },
  ['CLP']: {
    label: 'CLP',
    value: 'CLP',
  },
  ['CNY']: {
    label: 'CNY',
    value: 'CNY',
  },
  ['COP']: {
    label: 'COP',
    value: 'COP',
  },
  ['CRC']: {
    label: 'CRC',
    value: 'CRC',
  },
  ['CUC']: {
    label: 'CUC',
    value: 'CUC',
  },
  ['CUP']: {
    label: 'CUP',
    value: 'CUP',
  },
  ['CVE']: {
    label: 'CVE',
    value: 'CVE',
  },
  ['CZK']: {
    label: 'CZK',
    value: 'CZK',
  },
  ['DJF']: {
    label: 'DJF',
    value: 'DJF',
  },
  ['DKK']: {
    label: 'DKK',
    value: 'DKK',
  },
  ['DOP']: {
    label: 'DOP',
    value: 'DOP',
  },
  ['DZD']: {
    label: 'DZD',
    value: 'DZD',
  },
  ['EGP']: {
    label: 'EGP',
    value: 'EGP',
  },
  ['ERN']: {
    label: 'ERN',
    value: 'ERN',
  },
  ['ETB']: {
    label: 'ETB',
    value: 'ETB',
  },
  ['EUR']: {
    label: 'EUR',
    value: 'EUR',
  },
  ['FJD']: {
    label: 'FJD',
    value: 'FJD',
  },
  ['FKP']: {
    label: 'FKP',
    value: 'FKP',
  },
  ['GBP']: {
    label: 'GBP',
    value: 'GBP',
  },
  ['GEL']: {
    label: 'GEL',
    value: 'GEL',
  },
  ['GGP']: {
    label: 'GGP',
    value: 'GGP',
  },
  ['GHS']: {
    label: 'GHS',
    value: 'GHS',
  },
  ['GIP']: {
    label: 'GIP',
    value: 'GIP',
  },
  ['GMD']: {
    label: 'GMD',
    value: 'GMD',
  },
  ['GNF']: {
    label: 'GNF',
    value: 'GNF',
  },
  ['GTQ']: {
    label: 'GTQ',
    value: 'GTQ',
  },
  ['GYD']: {
    label: 'GYD',
    value: 'GYD',
  },
  ['HKD']: {
    label: 'HKD',
    value: 'HKD',
  },
  ['HNL']: {
    label: 'HNL',
    value: 'HNL',
  },
  ['HRK']: {
    label: 'HRK',
    value: 'HRK',
  },
  ['HTG']: {
    label: 'HTG',
    value: 'HTG',
  },
  ['HUF']: {
    label: 'HUF',
    value: 'HUF',
  },
  ['IDR']: {
    label: 'IDR',
    value: 'IDR',
  },
  ['ILS']: {
    label: 'ILS',
    value: 'ILS',
  },
  ['IMP']: {
    label: 'IMP',
    value: 'IMP',
  },
  ['INR']: {
    label: 'INR',
    value: 'INR',
  },
  ['IQD']: {
    label: 'IQD',
    value: 'IQD',
  },
  ['IRR']: {
    label: 'IRR',
    value: 'IRR',
  },
  ['ISK']: {
    label: 'ISK',
    value: 'ISK',
  },
  ['JEP']: {
    label: 'JEP',
    value: 'JEP',
  },
  ['JMD']: {
    label: 'JMD',
    value: 'JMD',
  },
  ['JOD']: {
    label: 'JOD',
    value: 'JOD',
  },
  ['JPY']: {
    label: 'JPY',
    value: 'JPY',
  },
  ['KES']: {
    label: 'KES',
    value: 'KES',
  },
  ['KGS']: {
    label: 'KGS',
    value: 'KGS',
  },
  ['KHR']: {
    label: 'KHR',
    value: 'KHR',
  },
  ['KMF']: {
    label: 'KMF',
    value: 'KMF',
  },
  ['KPW']: {
    label: 'KPW',
    value: 'KPW',
  },
  ['KRW']: {
    label: 'KRW',
    value: 'KRW',
  },
  ['KWD']: {
    label: 'KWD',
    value: 'KWD',
  },
  ['KYD']: {
    label: 'KYD',
    value: 'KYD',
  },
  ['KZT']: {
    label: 'KZT',
    value: 'KZT',
  },
  ['LAK']: {
    label: 'LAK',
    value: 'LAK',
  },
  ['LBP']: {
    label: 'LBP',
    value: 'LBP',
  },
  ['LKR']: {
    label: 'LKR',
    value: 'LKR',
  },
  ['LRD']: {
    label: 'LRD',
    value: 'LRD',
  },
  ['LSL']: {
    label: 'LSL',
    value: 'LSL',
  },
  ['LTL']: {
    label: 'LTL',
    value: 'LTL',
  },
  ['LVL']: {
    label: 'LVL',
    value: 'LVL',
  },
  ['LYD']: {
    label: 'LYD',
    value: 'LYD',
  },
  ['MAD']: {
    label: 'MAD',
    value: 'MAD',
  },
  ['MDL']: {
    label: 'MDL',
    value: 'MDL',
  },
  ['MGA']: {
    label: 'MGA',
    value: 'MGA',
  },
  ['MKD']: {
    label: 'MKD',
    value: 'MKD',
  },
  ['MMK']: {
    label: 'MMK',
    value: 'MMK',
  },
  ['MNT']: {
    label: 'MNT',
    value: 'MNT',
  },
  ['MOP']: {
    label: 'MOP',
    value: 'MOP',
  },
  ['MRO']: {
    label: 'MRO',
    value: 'MRO',
  },
  ['MUR']: {
    label: 'MUR',
    value: 'MUR',
  },
  ['MVR']: {
    label: 'MVR',
    value: 'MVR',
  },
  ['MWK']: {
    label: 'MWK',
    value: 'MWK',
  },
  ['MXN']: {
    label: 'MXN',
    value: 'MXN',
  },
  ['MYR']: {
    label: 'MYR',
    value: 'MYR',
  },
  ['MZN']: {
    label: 'MZN',
    value: 'MZN',
  },
  ['NAD']: {
    label: 'NAD',
    value: 'NAD',
  },
  ['NGN']: {
    label: 'NGN',
    value: 'NGN',
  },
  ['NIO']: {
    label: 'NIO',
    value: 'NIO',
  },
  ['NOK']: {
    label: 'NOK',
    value: 'NOK',
  },
  ['NPR']: {
    label: 'NPR',
    value: 'NPR',
  },
  ['NZD']: {
    label: 'NZD',
    value: 'NZD',
  },
  ['OMR']: {
    label: 'OMR',
    value: 'OMR',
  },
  ['PAB']: {
    label: 'PAB',
    value: 'PAB',
  },
  ['PEN']: {
    label: 'PEN',
    value: 'PEN',
  },
  ['PGK']: {
    label: 'PGK',
    value: 'PGK',
  },
  ['PHP']: {
    label: 'PHP',
    value: 'PHP',
  },
  ['PKR']: {
    label: 'PKR',
    value: 'PKR',
  },
  ['PLN']: {
    label: 'PLN',
    value: 'PLN',
  },
  ['PYG']: {
    label: 'PYG',
    value: 'PYG',
  },
  ['QAR']: {
    label: 'QAR',
    value: 'QAR',
  },
  ['RON']: {
    label: 'RON',
    value: 'RON',
  },
  ['RSD']: {
    label: 'RSD',
    value: 'RSD',
  },
  ['RUB']: {
    label: 'RUB',
    value: 'RUB',
  },
  ['RWF']: {
    label: 'RWF',
    value: 'RWF',
  },
  ['SAR']: {
    label: 'SAR',
    value: 'SAR',
  },
  ['SBD']: {
    label: 'SBD',
    value: 'SBD',
  },
  ['SCR']: {
    label: 'SCR',
    value: 'SCR',
  },
  ['SDG']: {
    label: 'SDG',
    value: 'SDG',
  },
  ['SEK']: {
    label: 'SEK',
    value: 'SEK',
  },
  ['SGD']: {
    label: 'SGD',
    value: 'SGD',
  },
  ['SHP']: {
    label: 'SHP',
    value: 'SHP',
  },
  ['SLL']: {
    label: 'SLL',
    value: 'SLL',
  },
  ['SOS']: {
    label: 'SOS',
    value: 'SOS',
  },
  ['SRD']: {
    label: 'SRD',
    value: 'SRD',
  },
  ['STD']: {
    label: 'STD',
    value: 'STD',
  },
  ['SVC']: {
    label: 'SVC',
    value: 'SVC',
  },
  ['SYP']: {
    label: 'SYP',
    value: 'SYP',
  },
  ['SZL']: {
    label: 'SZL',
    value: 'SZL',
  },
  ['THB']: {
    label: 'THB',
    value: 'THB',
  },
  ['TJS']: {
    label: 'TJS',
    value: 'TJS',
  },
  ['TMT']: {
    label: 'TMT',
    value: 'TMT',
  },
  ['TND']: {
    label: 'TND',
    value: 'TND',
  },
  ['TOP']: {
    label: 'TOP',
    value: 'TOP',
  },
  ['TRY']: {
    label: 'TRY',
    value: 'TRY',
  },
  ['TTD']: {
    label: 'TTD',
    value: 'TTD',
  },
  ['TWD']: {
    label: 'TWD',
    value: 'TWD',
  },
  ['TZS']: {
    label: 'TZS',
    value: 'TZS',
  },
  ['UAH']: {
    label: 'UAH',
    value: 'UAH',
  },
  ['UGX']: {
    label: 'UGX',
    value: 'UGX',
  },
  ['USD']: {
    label: 'USD',
    value: 'USD',
  },
  ['UYU']: {
    label: 'UYU',
    value: 'UYU',
  },
  ['UZS']: {
    label: 'UZS',
    value: 'UZS',
  },
  ['VEF']: {
    label: 'VEF',
    value: 'VEF',
  },
  ['VND']: {
    label: 'VND',
    value: 'VND',
  },
  ['VUV']: {
    label: 'VUV',
    value: 'VUV',
  },
  ['WST']: {
    label: 'WST',
    value: 'WST',
  },
  ['XAF']: {
    label: 'XAF',
    value: 'XAF',
  },
  ['XAG']: {
    label: 'XAG',
    value: 'XAG',
  },
  ['XAU']: {
    label: 'XAU',
    value: 'XAU',
  },
  ['XCD']: {
    label: 'XCD',
    value: 'XCD',
  },
  ['XDR']: {
    label: 'XDR',
    value: 'XDR',
  },
  ['XOF']: {
    label: 'XOF',
    value: 'XOF',
  },
  ['XPF']: {
    label: 'XPF',
    value: 'XPF',
  },
  ['YER']: {
    label: 'YER',
    value: 'YER',
  },
  ['ZAR']: {
    label: 'ZAR',
    value: 'ZAR',
  },
  ['ZMK']: {
    label: 'ZMK',
    value: 'ZMK',
  },
  ['ZMW']: {
    label: 'ZMW',
    value: 'ZMW',
  },
  ['ZWL']: {
    label: 'ZWL',
    value: 'ZWL',
  },
});
