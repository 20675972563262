import VueTypes from 'vue-types';

export default {
  props: {
    isLoading: VueTypes.bool.def(false),
  },
  computed: {
    loadingMixinClasses() {
      return [{ 'fly-loading': this.isLoading }];
    },
  },
};
