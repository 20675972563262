export const LOGIN_FORM = 'LOGIN_FORM';
export const EMAIL_SIGNUP_FORM = 'EMAIL_SIGNUP_FORM';
export const USER_INFORMATION_UPDATE = 'USER_INFORMATION_UPDATE';
export const USER_PASSWORD_UPDATE = 'USER_PASSWORD_UPDATE';
export const FORGOT_PASSWORD_FORM = 'FORGOT_PASSWORD_FORM';
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
export const USER_SETUP_FORM = 'USER_SETUP_FORM';
export const CREATE_PIPELINE_FORM = 'CREATE_PIPELINE_FORM';
export const SETUP_PIPELINE_FORM = 'SETUP_PIPELINE_FORM';
export const CREATE_SOURCE_FORM = 'CREATE_SOURCE_FORM';
export const CREATE_DESTINATION_FORM = 'CREATE_DESTINATION_FORM';
export const COLLECTION_FIELD_EXCLUDE_FORM = 'COLLECTION_FIELD_EXCLUDE_FORM';
export const CUSTOM_PRIMARY_KEY_FORM = 'CUSTOM_PRIMARY_KEY_FORM';
export const CREATE_SECURE_TUNNEL_FORM = 'CREATE_SECURE_TUNNEL_FORM';
export const USER_INVITE_FORM = 'USER_INVITE_FORM';
export const HOOK_CREATE_FORM = 'HOOK_CREATE_FORM';
export const HOOK_ATTACH_FORM = 'HOOK_ATTACH_FORM';
export const SYNC_SETTINGS_PIPELINE_FORM = 'SYNC_SETTINGS_PIPELINE_FORM';
export const SCHEMA_SETTINGS_PIPELINE_FORM = 'SCHEMA_SETTINGS_PIPELINE_FORM';
export const ALERT_SETUP_FORM = 'ALERT_SETUP_FORM';
export const METRIC_CREATE_FORM = 'METRIC_CREATE_FORM';
export const SCHEMA_SETTINGS_RESYNC_FORM = 'SCHEMA_SETTINGS_RESYNC_FORM';
export const CONFIRM_ALERT_DEACTIVATION_FORM =
  'CONFIRM_ALERT_DEACTIVATION_FORM';
export const CONFIRM_ARCHIVE_PIPELINE_FORM = 'CONFIRM_ARCHIVE_PIPELINE_FORM';
export const CONFIRM_ARCHIVE_SOURCE_FORM = 'CONFIRM_ARCHIVE_SOURCE_FORM';
export const CONFIRM_ARCHIVE_DESTINATION_FORM =
  'CONFIRM_ARCHIVE_DESTINATION_FORM';
export const CONFIRM_USER_DEACTIVATION_FORM = 'CONFIRM_USER_DEACTIVATION_FORM';
export const CONFIRM_METRIC_ARCHIVE_FORM = 'CONFIRM_METRIC_ARCHIVE_FORM';
