import { getSetupIntentTokenAction } from 'web/billing/billingActionTypes';
import { setError } from 'web/error/errorMutationTypes';
import { setLoading } from 'web/loading/loadingMutationTypes';
import billingService from 'web/billing/billingService';
import {
  setIsAnnual,
  setSelectedProduct,
  setStripeSetupIntent,
} from 'web/billing/billingMutationTypes';
import {
  getIsAnnual,
  getSelectedProduct,
  getStripeSetupIntentToken,
} from 'web/billing/billingGetterTypes';

export default {
  state: {
    stripeSetupIntentToken: null,
    selectedProduct: null,
    isAnnual: false,
  },
  getters: {
    [getStripeSetupIntentToken](state) {
      return state.stripeSetupIntentToken;
    },
    [getSelectedProduct](state) {
      return state.selectedProduct;
    },
    [getIsAnnual](state) {
      return state.isAnnual;
    },
  },
  mutations: {
    [setStripeSetupIntent]: (state, clientSecret) => {
      state.stripeSetupIntentToken = clientSecret;
    },
    [setSelectedProduct]: (state, product) => {
      state.selectedProduct = product;
    },
    [setIsAnnual]: (state, annual) => {
      state.isAnnual = annual;
    },
  },
  actions: {
    [getSetupIntentTokenAction]: async ({ commit }) => {
      commit(setError, { key: getSetupIntentTokenAction, value: null });
      commit(setLoading, { key: getSetupIntentTokenAction, value: true });
      try {
        const resp = await billingService.getSetupIntentToken();
        commit(setStripeSetupIntent, resp.client_secret);
      } catch (error) {
        commit(setError, { key: getSetupIntentTokenAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: getSetupIntentTokenAction, value: false });
      }
    },
  },
};
