import { setLoading } from 'web/loading/loadingMutationTypes';
import { setError } from 'web/error/errorMutationTypes';
import pipelinesService from 'web/pipelines/pipelinesService';
import { fetchPipelineEventPageAction } from 'web/pipelines/details/logs/pipelineLogsActionTypes';

export default {
  actions: {
    async [fetchPipelineEventPageAction](
      { commit },
      { pipelineId, page, page_size }
    ) {
      var pipelineEventsPageResponse = {};
      commit(setLoading, { key: fetchPipelineEventPageAction, value: true });
      commit(setError, { key: fetchPipelineEventPageAction, value: null });
      try {
        pipelineEventsPageResponse = await pipelinesService.fetchPipelineEventPage(
          {
            pipelineId,
            page,
            page_size,
          }
        );
        return pipelineEventsPageResponse;
      } catch (err) {
        commit(setError, { key: fetchPipelineEventPageAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: fetchPipelineEventPageAction, value: false });
      }
    },
  },
};
