import createServiceStore from 'shared/utils/createServiceStore.js';
import { TUNNEL_SERVICE_KEY } from './tunnelEnum.js';
import tunnelService from './tunnelService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import {
  downloadTunnelTemplateAction,
  testTunnelConnectionAction,
} from 'web/tunnel/tunnelActionTypes';
import { setTunnelTestConnectionResult } from 'web/tunnel/tunnelMutationTypes';

export default createServiceStore({
  serviceName: TUNNEL_SERVICE_KEY,
  service: tunnelService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  actions: {
    async [downloadTunnelTemplateAction](
      { commit },
      { tunnelId, config_type, silent = false }
    ) {
      !silent &&
        commit(setLoading, { key: downloadTunnelTemplateAction, value: true });
      commit(setError, { key: downloadTunnelTemplateAction, value: null });

      try {
        await tunnelService.downloadTunnelTemplate(tunnelId, config_type);
      } catch (err) {
        commit(setError, { key: downloadTunnelTemplateAction, value: err });
      } finally {
        !silent &&
          commit(setLoading, {
            key: downloadTunnelTemplateAction,
            value: false,
          });
      }
    },
    async [testTunnelConnectionAction]({ commit }, tunnelId = null) {
      commit(setLoading, { key: testTunnelConnectionAction, value: true });
      commit(setError, { key: testTunnelConnectionAction, value: null });
      commit(setTunnelTestConnectionResult, {});

      try {
        const result = await tunnelService.testConnection(tunnelId);
        commit(setTunnelTestConnectionResult, result);
      } catch (err) {
        commit(setError, { key: testTunnelConnectionAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: testTunnelConnectionAction, value: false });
      }
    },
  },
});
