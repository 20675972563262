<template>
  <teleport to="body">
    <div class="fly-box fly-modal">
      <div class="fly-box fly-modal--overlay" @click.self="onOverlayClick" />
      <div class="fly-box fly-modal--container" @click.self="onOverlayClick">
        <component
          :is="tagName"
          :class="{
            'fly-box': true,
            'fly-modal--box-container': true,
            'is-transparent': transparent,
          }"
          v-bind="$attrs"
        >
          <div v-if="showHeader" class="fly-box fly-modal--header">
            <slot name="header"></slot>
          </div>
          <div class="fly-box fly-modal--content">
            <slot></slot>
          </div>
          <div v-if="showFooter" class="fly-box fly-modal--footer">
            <slot name="footer"></slot>
          </div>
        </component>
      </div>
    </div>
  </teleport>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'FlyModal',
  props: {
    showFooter: VueTypes.bool.def(true),
    showHeader: VueTypes.bool.def(true),
    transparent: VueTypes.bool.def(false),
    tagName: VueTypes.string.def('div'),
  },
  emits: ['close'],
  methods: {
    emitClose() {
      this.$emit('close');
    },
    onOverlayClick() {
      this.emitClose();
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  margin-top: 0;
}
.fly-modal--overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: $fly-color-grey-1;
  opacity: 0.6;
}

.fly-modal--container {
  justify-content: center;
  max-height: 92%;
  z-index: 1;
  width: inherit;
}

.fly-modal--box-container {
  background-color: #ffffff;
  border-radius: 8px;
  flex-direction: column;
  width: fit-content;
  min-width: 50%;

  &.is-transparent {
    box-shadow: 0 16px 32px 0 transparentize($fly-color-grey-1, 0.75);
    background-color: transparent;
  }

  @media (max-width: $--fly-breakpoint-md) {
    width: 100%;
  }
}

.fly-modal--content {
  overflow: auto;
  width: fit-content;
}
</style>
