import { PIPELINES_API_URL } from './pipelinesEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(PIPELINES_API_URL, {
  extraMethods: {
    async downloadETLScript(pipelineId, etl_source_id, etl_destination_id) {
      return this.request({
        url: `${PIPELINES_API_URL}${pipelineId}/etl-script/`,
        method: 'get',
        params: {
          etl_source_id,
          etl_destination_id,
        },
      });
    },
    async changeStatus(pipelineId, status) {
      return this.request({
        url: `${PIPELINES_API_URL}${pipelineId}/${status}/`,
        method: 'post',
      });
    },
    async fetchPipelineUsage({ id, ...params }) {
      return this.request({
        url: `${PIPELINES_API_URL}${id}/usage/`,
        method: 'get',
        params,
      });
    },
    async fetchPipelineCollectionUsage({ id, ...params }) {
      return this.request({
        url: `${PIPELINES_API_URL}${id}/collections/`,
        method: 'get',
        params,
      });
    },
    async resyncCollections(id, _data) {
      return this.request({
        url: `${PIPELINES_API_URL}${id}/resync-collections/`,
        method: 'post',
        data: _data,
      });
    },
    async fetchPipelineEventPage({ pipelineId, ...params }) {
      return this.request({
        url: `${PIPELINES_API_URL}${pipelineId}/events/`,
        method: 'get',
        params,
      });
    },
    async fetchPipelineHooks({ pipelineId, ...params }) {
      return this.request({
        url: `${PIPELINES_API_URL}${pipelineId}/hooks/`,
        method: 'get',
        params,
      });
    },
    async fetchAccountUsage({ ...params }) {
      return this.request({
        url: `${PIPELINES_API_URL}account-usage/`,
        method: 'get',
        params,
      });
    },
    async fetchAccountUsagePipelines({ ...params }) {
      return this.request({
        url: `${PIPELINES_API_URL}account-usage-pipelines/`,
        method: 'get',
        params,
      });
    },
    async fetchPipelineUsageCollections({ pipelineId, ...params }) {
      return this.request({
        url: `${PIPELINES_API_URL}${pipelineId}/usage-collections/`,
        method: 'get',
        params,
      });
    },
  },
});
