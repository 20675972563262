<template>
  <router-link v-if="to" :to="to" :class="classes" v-bind="$attrs">
    <slot></slot>
  </router-link>
  <a v-else :class="classes" v-bind="$attrs">
    <slot></slot>
  </a>
</template>

<script>
import VueTypes from 'vue-types';
import ActiveMixin from 'shared/components/Interaction/ActiveMixin';
import LoadingMixin from 'shared/components/Interaction/LoadingMixin';

export default {
  name: 'FlyLink',
  mixins: [ActiveMixin, LoadingMixin],
  props: {
    to: VueTypes.oneOfType([VueTypes.string, VueTypes.object]).def(''),
    useRouterLinkActive: VueTypes.bool.def(true),
  },
  computed: {
    classes() {
      return [
        'fly-link',
        { 'fly-use-router-link-active': this.useRouterLinkActive },
        this.activeMixinClasses,
        this.loadingMixinClasses,
      ];
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-link {
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }

  &.fly-loading {
    background: $fly-color-grey-8 !important;
    color: $fly-color-grey-8 !important;
    border-radius: 10px;
  }
}
</style>
