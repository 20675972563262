import featureToggleConfig from './featureToggleConfig.js';
import { isFunction, isPlainObject } from 'lodash';
import sift from 'sift';

const isFeatureToggleEnabled = (toggle, params) => {
  const featureToggle = featureToggleConfig[toggle];

  if (isFunction(featureToggle)) {
    return featureToggle(params);
  }

  if (isPlainObject(featureToggle)) {
    const siftFn = sift(featureToggle);
    return siftFn(params);
  }

  return featureToggle;
};

export default isFeatureToggleEnabled;
