import { get } from 'lodash';
import { setDestinationTestConnectionResult } from 'web/destinations/destinationsMutationTypes';
import {
  getDestinationTestConnectionResult,
  isDestinationTestConnectionResultSuccessful,
} from 'web/destinations/destinationsGetterTypes';
import destinationsServiceActions from 'web/destinations/destinationsServiceActions';
import { hasError } from 'web/error/errorGetterTypes';

export default {
  state: { testDestinationConnectionResult: {} },
  getters: {
    [getDestinationTestConnectionResult]: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      const error = get(
        rootGetters[hasError](destinationsServiceActions.create),
        'response.data',
        null
      );
      if (error) {
        if (
          get(error, 'detail.error', null) ===
          'Destination Test Connection Failed'
        ) {
          return get(error, 'detail.validation_result', {});
        }
        return get(error, 'detail');
      }
      return state.testDestinationConnectionResult;
    },
    [isDestinationTestConnectionResultSuccessful]: (state, getters) => {
      return (
        get(getters[getDestinationTestConnectionResult], 'success') === true
      );
    },
  },
  mutations: {
    [setDestinationTestConnectionResult](state, result) {
      state.testDestinationConnectionResult = result;
    },
  },
  actions: {},
};
