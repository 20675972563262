import { get } from 'lodash';
import { setTunnelTestConnectionResult } from 'web/tunnel/tunnelMutationTypes';
import {
  getTunnelTestConnectionResult,
  isTunnelTestConnectionResultSuccessful,
} from 'web/tunnel/tunnelGetterTypes';

export default {
  state: {
    testTunnelConnectionResult: {},
  },
  getters: {
    [getTunnelTestConnectionResult]: (state) => {
      return state.testTunnelConnectionResult;
    },
    [isTunnelTestConnectionResultSuccessful]: (state, getters) => {
      return get(getters[getTunnelTestConnectionResult], 'success') === true;
    },
  },
  mutations: {
    [setTunnelTestConnectionResult](state, result) {
      state.testTunnelConnectionResult = result;
    },
  },
  actions: {},
};
