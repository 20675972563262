import { DOCS_API_URL } from './docsEnum.js';
import axios from 'axios';
import qs from 'qs';

const axiosInstance = axios.create({
  baseURL: DOCS_API_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { indices: false });
  },
});

export default {
  async get(id, config = {}) {
    const response = await axiosInstance.request({
      url: `articles/${id}/`,
      ...config,
    });
    return response.data.article;
  },
};
