<template>
  <div class="fly-box fly-app-navbar">
    <slot>
      <div class="fly-box fly-app-navbar-container">
        <AppNavbarLogo />
        <div class="fly-box fly-app-navbar-items-container">
          <AppNavbarMenu class="fly-app-navbar-menu" />
        </div>
      </div>
      <AppNavbarUser />
    </slot>
  </div>
</template>

<script>
import AppNavbarLogo from 'web/common/nav/AppNavbarLogo';
import AppNavbarMenu from 'web/common/nav/AppNavbarMenu';
import AppNavbarUser from 'web/common/nav/AppNavbarUser';

export default {
  name: 'AppNavbar',
  components: {
    AppNavbarMenu,
    AppNavbarLogo,
    AppNavbarUser,
  },
};
</script>

<style lang="scss">
@import '~shared/components/fly-variables';

.fly-app-navbar {
  width: 50px;
  background: linear-gradient(180deg, #00346c 0%, #081d34 76.52%);
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
  overflow: hidden;

  .fly-app-navbar-container {
    flex-direction: column;

    .fly-app-navbar-items-container {
      flex-direction: column;
    }

    .fly-app-navbar-menu {
      margin-top: 4px;
    }
  }
}
</style>
