import { get, isEmpty, map, set, filter, includes, orderBy } from 'lodash';
import { setLoading } from 'web/loading/loadingMutationTypes';
import { setError } from 'web/error/errorMutationTypes';
import pipelinesService from 'web/pipelines/pipelinesService';
import {
  fetchAvgPipelineUsageAction,
  fetchPipelineCollectionUsageAction,
  fetchPipelineUsageAction,
  fetchTotalPipelineUsageAction,
} from 'web/pipelines/usage/pipelineUsageActionTypes';
import {
  setAvgPipelineUsage,
  setPipelineCollectionUsage,
  setPipelineUsage,
  setTotalPipelineUsage,
} from 'web/pipelines/usage/pipelineUsageMutationTypes';
import {
  getAvgUsage,
  getCollectionUsage,
  getTotalContUsage,
  getTotalInitUsage,
  getTotalUsage,
  getUsage,
} from 'web/pipelines/usage/pipelineUsageGetterTypes';

export function toChartData(data) {
  return map(data, (datum) => {
    return {
      x: datum[0],
      y: datum[1],
    };
  });
}

export default {
  state: {},
  getters: {
    [getUsage]: (state) => (id) => {
      const initSyncRecords = get(
        state[id],
        'usage.init_sync_processed_records',
        []
      );
      const contSyncRecords = get(
        state[id],
        'usage.cont_sync_processed_records',
        []
      );

      if (isEmpty(initSyncRecords) || isEmpty(contSyncRecords)) {
        return null;
      }

      const totalSyncRecords = map(initSyncRecords, (initSyncData, index) => {
        return {
          x: initSyncData[0],
          y: initSyncData[1] + contSyncRecords[index][1],
        };
      });

      return [
        {
          name: 'Initial sync records (non-billable)',
          data: toChartData(initSyncRecords),
        },
        {
          name: 'Continuous sync records (billable)',
          data: toChartData(contSyncRecords),
        },
        {
          name: 'Total records',
          data: totalSyncRecords,
        },
      ];
    },
    [getTotalUsage]: (state) => (id) => {
      const totalUsage = get(state[id], 'totalUsage', {});
      return (
        totalUsage.cont_sync_processed_records +
          totalUsage.init_sync_processed_records || 0
      );
    },
    [getTotalInitUsage]: (state) => (id) => {
      const totalUsage = get(state[id], 'totalUsage', {});
      return totalUsage.init_sync_processed_records || 0;
    },
    [getTotalContUsage]: (state) => (id) => {
      const totalUsage = get(state[id], 'totalUsage', {});
      return totalUsage.cont_sync_processed_records || 0;
    },
    [getAvgUsage]: (state) => (id) => {
      const avgUsage = get(state[id], 'avgUsage', {});
      return avgUsage.avg_processed_records || 0;
    },
    [getCollectionUsage]: (state) => (
      id,
      filterQuery,
      sortBy = ['status'],
      sortOrder = ['asc']
    ) => {
      let collectionUsage = get(state[id], 'collectionUsage', []);
      if (filterQuery) {
        collectionUsage = filter(collectionUsage, (usage) => {
          return includes(usage.collection, filterQuery);
        });
      }
      collectionUsage = map(collectionUsage, (usage) => {
        return {
          ...usage,
          total_records:
            usage.cont_sync_processed_records +
            usage.init_sync_processed_records,
        };
      });

      const sortByUpdated =
        sortBy === 'collection_position'
          ? 'collection_position.position'
          : sortBy;

      return orderBy(collectionUsage, sortByUpdated, sortOrder);
    },
  },
  mutations: {
    [setPipelineUsage](state, { id, usage }) {
      set(state, `[${id}].usage`, usage);
    },
    [setTotalPipelineUsage](state, { id, usage }) {
      set(state, `[${id}].totalUsage`, usage);
    },
    [setAvgPipelineUsage](state, { id, usage }) {
      set(state, `[${id}].avgUsage`, usage);
    },
    [setPipelineCollectionUsage](state, { id, usage }) {
      set(state, `[${id}].collectionUsage`, usage);
    },
  },
  actions: {
    async [fetchPipelineUsageAction](
      { commit },
      { id, start_timestamp, end_timestamp, group_by, silent = false }
    ) {
      !silent &&
        commit(setLoading, { key: fetchPipelineUsageAction, value: true });
      commit(setError, { key: fetchPipelineUsageAction, value: null });

      try {
        const usage = await pipelinesService.fetchPipelineUsage({
          id,
          start_timestamp,
          end_timestamp,
          group_by,
          group_by_op: 'sum',
        });
        commit(setPipelineUsage, { id, usage });
      } catch (err) {
        commit(setError, { key: fetchPipelineUsageAction, value: err });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, { key: fetchPipelineUsageAction, value: false });
      }
    },
    async [fetchTotalPipelineUsageAction](
      { commit },
      { id, start_timestamp, end_timestamp, group_by, silent = false }
    ) {
      !silent &&
        commit(setLoading, { key: fetchTotalPipelineUsageAction, value: true });
      commit(setError, { key: fetchTotalPipelineUsageAction, value: null });

      try {
        const usage = await pipelinesService.fetchPipelineUsage({
          id,
          start_timestamp,
          end_timestamp,
          group_by,
          group_by_op: 'total',
        });
        commit(setTotalPipelineUsage, { id, usage });
      } catch (err) {
        commit(setError, { key: fetchTotalPipelineUsageAction, value: err });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, {
            key: fetchTotalPipelineUsageAction,
            value: false,
          });
      }
    },
    async [fetchAvgPipelineUsageAction](
      { commit },
      { id, start_timestamp, end_timestamp, group_by, silent = false }
    ) {
      !silent &&
        commit(setLoading, { key: fetchAvgPipelineUsageAction, value: true });
      commit(setError, { key: fetchAvgPipelineUsageAction, value: null });

      try {
        const usage = await pipelinesService.fetchPipelineUsage({
          id,
          start_timestamp,
          end_timestamp,
          group_by,
          group_by_op: 'avg',
        });
        commit(setAvgPipelineUsage, { id, usage });
      } catch (err) {
        commit(setError, { key: fetchAvgPipelineUsageAction, value: err });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, {
            key: fetchAvgPipelineUsageAction,
            value: false,
          });
      }
    },
    async [fetchPipelineCollectionUsageAction](
      { commit },
      { id, start_timestamp, end_timestamp, silent = false }
    ) {
      !silent &&
        commit(setLoading, {
          key: fetchPipelineCollectionUsageAction,
          value: true,
        });
      commit(setError, {
        key: fetchPipelineCollectionUsageAction,
        value: null,
      });

      try {
        const usage = await pipelinesService.fetchPipelineCollectionUsage({
          id,
          start_timestamp,
          end_timestamp,
        });
        commit(setPipelineCollectionUsage, { id, usage });
      } catch (err) {
        commit(setError, {
          key: fetchPipelineCollectionUsageAction,
          value: err,
        });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, {
            key: fetchPipelineCollectionUsageAction,
            value: false,
          });
      }
    },
  },
};
