import { get } from 'lodash';
import { setSourceTestConnectionResult } from 'web/sources/sourcesMutationTypes';
import {
  getSourceTestConnectionResult,
  isSourceTestConnectionResultSuccessful,
} from 'web/sources/sourcesGetterTypes';
import sourcesServiceActions from 'web/sources/sourcesServiceActions';
import { hasError } from 'web/error/errorGetterTypes';

export default {
  state: {
    testSourceConnectionResult: {},
  },
  getters: {
    [getSourceTestConnectionResult]: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      const error = get(
        rootGetters[hasError](sourcesServiceActions.create),
        'response.data',
        null
      );
      if (error) {
        if (
          get(error, 'detail.error', null) === 'Source Test Connection Failed'
        ) {
          return get(error, 'detail.validation_result', {});
        }
        return get(error, 'detail');
      }
      return state.testSourceConnectionResult;
    },
    [isSourceTestConnectionResultSuccessful]: (state, getters) => {
      return get(getters[getSourceTestConnectionResult], 'success') === true;
    },
  },
  mutations: {
    [setSourceTestConnectionResult](state, result) {
      state.testSourceConnectionResult = result;
    },
  },
  actions: {},
};
