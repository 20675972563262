import { isModalOpen } from 'shared/store/modal/modalGetterTypes';
import {
  clearFormData,
  setInitialFormData,
} from 'shared/stores/form/formMutationTypes';
import { HOOK_ATTACH_FORM, HOOK_CREATE_FORM } from 'web/form/formKeys';
import { isEmpty } from 'lodash';
import { openModal } from 'shared/store/modal/modalMutationTypes';
import {
  showHookCreateModal,
  showHookAttachModal,
} from 'web/hook/hookGetterTypes';
import { HOOK_ATTACH_MODAL, HOOK_CREATE_MODAL } from 'web/modal/modalKeys';
import {
  openHookAttachModalAction,
  openHookCreateModalAction,
} from 'web/hook/hookActionTypes';

export default {
  state: {},
  getters: {
    [showHookCreateModal](state, getters, rootState, rootGetters) {
      return rootGetters[isModalOpen](HOOK_CREATE_MODAL);
    },
    [showHookAttachModal](state, getters, rootState, rootGetters) {
      return rootGetters[isModalOpen](HOOK_ATTACH_MODAL);
    },
  },
  actions: {
    [openHookCreateModalAction]({ commit }, initialFormData = {}) {
      commit(clearFormData, HOOK_CREATE_FORM);
      if (!isEmpty(initialFormData)) {
        commit(setInitialFormData, {
          key: HOOK_CREATE_FORM,
          formData: initialFormData,
        });
      }
      commit(openModal, HOOK_CREATE_MODAL);
    },
    [openHookAttachModalAction]({ commit }, initialFormData = {}) {
      console.log('initialFormData', initialFormData);
      initialFormData.entity_type = 'pipeline';

      commit(clearFormData, HOOK_ATTACH_FORM);
      if (!isEmpty(initialFormData)) {
        commit(setInitialFormData, {
          key: HOOK_ATTACH_FORM,
          formData: initialFormData,
        });
      }
      commit(openModal, HOOK_ATTACH_MODAL);
    },
  },
};
