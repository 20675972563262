import { each } from 'lodash';
import { getAllToasts } from './toastGetterTypes.js';
import { showToast, hideToast, hideAllToast } from './toastMutationTypes.js';
import { showToastAction } from './toastActionTypes.js';

export default {
  state: {
    toasts: {},
  },
  getters: {
    [getAllToasts]: (state) => state.toasts,
  },
  mutations: {
    [showToast](state, { key, title, description, type }) {
      state.toasts[key] = {
        key,
        description,
        title,
        type,
      };
    },
    [hideToast](state, key) {
      state.toasts[key] = null;
    },
    [hideAllToast](state) {
      each(state.toasts, (toast) => {
        if (toast) state.toasts[toast.key] = null;
      });
    },
  },
  actions: {
    [showToastAction](
      { commit },
      { duration = 4000, title, description, type, key }
    ) {
      commit(showToast, {
        title,
        description,
        key,
        type,
      });

      if (duration) {
        setTimeout(() => commit(hideToast, key), duration);
      }
    },
  },
};
