import createServiceStore from 'shared/utils/createServiceStore.js';
import { HOOK_SERVICE_KEY } from './hookEnum.js';
import hookService from './hookService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import {
  archiveHookAction,
  attachHookAction,
  detachHookAction,
  editHookAttachmentAction,
  getHookAttachmentAction,
} from 'web/hook/hookActionTypes';

export default createServiceStore({
  serviceName: HOOK_SERVICE_KEY,
  service: hookService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  getters: {},
  actions: {
    async [archiveHookAction]({ commit }, { hookId, status, silent = false }) {
      !silent && commit(setLoading, { key: archiveHookAction, value: status });
      commit(setError, { key: archiveHookAction, value: null });
      try {
        await hookService.archive(hookId);
      } catch (err) {
        commit(setError, { key: archiveHookAction, value: err });
        throw err;
      } finally {
        !silent && commit(setLoading, { key: archiveHookAction, value: false });
      }
    },
    async [getHookAttachmentAction]({ commit }, data) {
      commit(setError, { key: getHookAttachmentAction, value: null });
      commit(setLoading, { key: getHookAttachmentAction, value: true });
      try {
        const hook = await hookService.getAttachment({
          hookId: data.hook_id,
          hookSourceType: data.entity_type,
          hookSourceId: data.entity_id,
          data: {
            selected_events: data.selected_events,
          },
        });
        return hook;
      } catch (error) {
        commit(setError, { key: getHookAttachmentAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: getHookAttachmentAction, value: false });
      }
    },
    async [attachHookAction]({ commit }, data) {
      commit(setError, { key: attachHookAction, value: null });
      commit(setLoading, { key: attachHookAction, value: true });
      try {
        const hook = await hookService.attach({
          hookId: data.hook_id,
          hookSourceType: data.entity_type,
          hookSourceId: data.entity_id,
          data: {
            selected_events: data.selected_events,
          },
        });
        return hook;
      } catch (error) {
        commit(setError, { key: attachHookAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: attachHookAction, value: false });
      }
    },
    async [detachHookAction]({ commit }, data) {
      commit(setError, { key: detachHookAction, value: null });
      commit(setLoading, { key: detachHookAction, value: true });
      try {
        const hook = await hookService.detach({
          hookId: data.hook_id,
          hookSourceType: data.entity_type,
          hookSourceId: data.entity_id,
        });
        return hook;
      } catch (error) {
        commit(setError, { key: detachHookAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: detachHookAction, value: false });
      }
    },
    async [editHookAttachmentAction]({ commit }, data) {
      commit(setError, { key: attachHookAction, value: null });
      commit(setLoading, { key: attachHookAction, value: true });
      try {
        const hook = await hookService.editAttachment({
          hookId: data.hook_id,
          hookSourceType: data.entity_type,
          hookSourceId: data.pipeline_id,
          data: {
            selected_events: data.selected_events,
          },
        });
        return hook;
      } catch (error) {
        commit(setError, { key: attachHookAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: attachHookAction, value: false });
      }
    },
  },
});
