import {
  extend,
  isEqual,
  cloneDeep,
  set,
  reduce,
  every,
  isArray,
  isEmpty,
  isObjectLike,
  castArray,
  some,
  get,
} from 'lodash';
import {
  formHasError,
  getChangedFormData,
  getFormData,
  getFormError,
  getInitialFormData,
  formFieldHasChanges,
  formHasChanges,
  formFieldValue,
} from './formGetterTypes.js';
import {
  clearFormData,
  resetFormData,
  setFormData,
  setFormField,
  setInitialFormData,
} from './formMutationTypes';
import {
  clearFormErrors,
  setFormFieldAtPath,
  setFormFieldError,
} from './formMutationTypes.js';
import {
  setInitialFormField,
  setInitialFormFieldAtPath,
} from 'shared/stores/form/formMutationTypes';

export default (initialFormData = {}) => ({
  state: reduce(
    initialFormData,
    (acc, value, key) => {
      acc[key] = {
        initialFormData: cloneDeep(initialFormData[key]),
        formData: cloneDeep(initialFormData[key]),
        formErrors: {},
      };
      return acc;
    },
    {}
  ),

  getters: {
    [getInitialFormData]: (state) => (key) => state[key].initialFormData,
    [getFormData]: (state) => (key) => state[key].formData,
    [getFormError]: (state) => (key) => state[key].formErrors,
    [formFieldValue]: (state, getters) => ({ key, field }) =>
      get(getters[getFormData](key), field),
    [formHasError]: (state) => (key) =>
      !every(state[key].formErrors, (value) => {
        if (isArray(value)) {
          return every(value, (val) =>
            isObjectLike(val) ? isEmpty(val) : !val
          );
        }
        return isObjectLike(value) ? isEmpty(value) : !value;
      }),
    [formHasChanges]: (state) => (key) =>
      !isEqual(state[key].formData, state[key].initialFormData),
    [formFieldHasChanges]: (state) => (key, field) => {
      const fields = castArray(field);
      return some(
        fields,
        (field) =>
          !isEqual(
            get(state[key].formData, field, null),
            get(state[key].initialFormData, field, null)
          )
      );
    },
    [getChangedFormData]: (state, getters) => (key) =>
      reduce(
        state[key].formData,
        (res, val, field) => {
          if (getters[formFieldHasChanges](key, field)) {
            res[field] = state[key].formData[field];
          }
          return res;
        },
        {}
      ),
  },
  mutations: {
    [setInitialFormData](state, { key, formData }) {
      const _initialFormData = extend(
        {},
        cloneDeep(initialFormData[key]),
        cloneDeep(formData)
      );
      const _formData = extend(
        {},
        cloneDeep(initialFormData[key]),
        cloneDeep(formData)
      );
      state[key] = {
        initialFormData: _initialFormData,
        formData: _formData,
        formErrors: {},
      };
    },
    [setFormData](state, { key, formData }) {
      state[key].formData = extend({}, state[key].formData, formData);
    },
    [setInitialFormField](state, { key, field, value }) {
      state[key].initialFormData[field] = value;
      state[key].formData[field] = value;
    },
    [setFormField](state, { key, field, value }) {
      state[key].formData[field] = value;
    },
    [setInitialFormFieldAtPath](state, { key, path, value }) {
      set(state[key].initialFormData, path, value);
      set(state[key].formData, path, value);
    },
    [setFormFieldAtPath](state, { key, path, value }) {
      set(state[key].formData, path, value);
    },
    [setFormFieldError](state, { key, field, error }) {
      state[key].formErrors[field] = error;
    },
    [clearFormData](state, key) {
      state[key] = {
        initialFormData: cloneDeep(initialFormData[key] || {}),
        formData: cloneDeep(initialFormData[key] || {}),
        formErrors: {},
      };
    },
    [resetFormData](state, key) {
      state[key].formData = cloneDeep(initialFormData[key] || {});
      state[key].formErrors = {};
    },
    [clearFormErrors](state, key) {
      state[key].formErrors = {};
    },
  },
});
