import { getMetricsWithSourceAndDestination } from 'web/metrics/metricsGetterTypes.js';
import metricsServiceGetters from 'web/metrics/metricsServiceGetters.js';
import { get, map } from 'lodash';
import sourcesServiceGetters from 'web/sources/sourcesServiceGetters.js';
import destinationsServiceGetters from 'web/destinations/destinationsServiceGetters.js';

export default {
  state: {},
  getters: {
    [getMetricsWithSourceAndDestination]: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      const metrics = rootGetters[metricsServiceGetters.getAll]();
      return map(metrics, (metric) => {
        const isSource = metric.data_store_type === 'source';
        const sourceOrDestination = isSource
          ? rootGetters[sourcesServiceGetters.get](metric.data_store_id)
          : rootGetters[destinationsServiceGetters.get](metric.data_store_id);
        return {
          ...metric,
          data_store_type_name: get(
            sourceOrDestination,
            isSource ? 'source_type' : 'destination_type'
          ),
          data_store_name: get(sourceOrDestination, 'name'),
        };
      });
    },
  },
  mutations: {},
  actions: {},
};
