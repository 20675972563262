import {
  CREATE_PIPELINE_FORM,
  FORGOT_PASSWORD_FORM,
  LOGIN_FORM,
  USER_INFORMATION_UPDATE,
  USER_PASSWORD_UPDATE,
  EMAIL_SIGNUP_FORM,
  USER_SETUP_FORM,
  CREATE_DESTINATION_FORM,
  CREATE_SOURCE_FORM,
  RESET_PASSWORD_FORM,
  SETUP_PIPELINE_FORM,
  COLLECTION_FIELD_EXCLUDE_FORM,
  CUSTOM_PRIMARY_KEY_FORM,
  CREATE_SECURE_TUNNEL_FORM,
  USER_INVITE_FORM,
  SYNC_SETTINGS_PIPELINE_FORM,
  SCHEMA_SETTINGS_PIPELINE_FORM,
  ALERT_SETUP_FORM,
  METRIC_CREATE_FORM,
  SCHEMA_SETTINGS_RESYNC_FORM,
  CONFIRM_ALERT_DEACTIVATION_FORM,
} from './formKeys';
import PIPELINE_STATUS_ENUM from 'web/pipelines/PIPELINE_STATUS_ENUM';
import CLOUD_PROVIDER_ENUM from 'web/common/CLOUD_PROVIDER_ENUM';
import CLOUD_PROVIDER_REGION_ENUM from 'web/common/CLOUD_PROVIDER_REGION_ENUM';
import TUNNEL_TYPE_ENUM from 'web/common/TUNNEL_TYPE_ENUM.js';

export default Object.freeze({
  [LOGIN_FORM]: {
    email: '',
    password: '',
  },
  [USER_INFORMATION_UPDATE]: {
    first_name: '',
    last_name: '',
    phone_country_code: '',
    phone_national_number: '',
    time_zone: '',
  },
  [USER_PASSWORD_UPDATE]: {
    current_password: '',
    new_password: '',
    confirm_password: '',
  },
  [FORGOT_PASSWORD_FORM]: {
    email: '',
  },
  [RESET_PASSWORD_FORM]: {
    new_password: '',
    confirm_password: '',
    token: '',
  },
  [USER_SETUP_FORM]: {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
  },
  [EMAIL_SIGNUP_FORM]: {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  },
  [CREATE_PIPELINE_FORM]: {
    id: null,
    name: '',
    region: CLOUD_PROVIDER_REGION_ENUM[CLOUD_PROVIDER_ENUM.AWS][0].value,
  },
  [SETUP_PIPELINE_FORM]: {
    id: null,
    name: '',
    source_id: null,
    destination_id: null,
    maintenance_window: '',
    persist_deleted_rows: false,
    sync_mappings: {},
    status: PIPELINE_STATUS_ENUM.RUNNING,
    source_sync_frequency: 900000,
    destination_sync_frequency: 900000,
    destination_sync_cron: null,
    current_sync_mode: 'default',
    auto_sync_new_collections: false,
    s3_file_format: 'avro',
  },
  [SYNC_SETTINGS_PIPELINE_FORM]: {
    maintenance_window: '02:00-04:00',
    persist_deleted_rows: false,
    source_sync_frequency: 900000,
    destination_sync_frequency: 900000,
    auto_sync_new_collections: false,
    current_sync_mode: 'default',
    s3_file_format: 'avro',
  },
  [SCHEMA_SETTINGS_PIPELINE_FORM]: {
    sync_mappings: {},
  },
  [CREATE_SOURCE_FORM]: {
    id: null,
    name: '',
    config: {
      connection_config: {
        database: '',
        host: '',
        port: null,
        user: '',
        password: '',
      },
    },
    data_store: '',
    variant: '',
    connectionType: 'direct',
  },
  [CREATE_DESTINATION_FORM]: {
    id: null,
    name: '',
    config: {
      connection_config: {
        database: '',
        host: '',
        port: null,
        user: '',
        password: '',
      },
    },
    data_store: '',
    variant: '',
    connectionType: 'direct',
  },
  [COLLECTION_FIELD_EXCLUDE_FORM]: {
    excluded: [],
  },
  [CREATE_SECURE_TUNNEL_FORM]: {
    name: '',
    type: TUNNEL_TYPE_ENUM.SSH,
    ssh_port: 22,
  },
  [USER_INVITE_FORM]: {
    email: '',
    role: '',
  },
  [CUSTOM_PRIMARY_KEY_FORM]: {
    custom_primary_keys: [],
  },
  [ALERT_SETUP_FORM]: {
    id: null,
    name: '',
    priority: 'high',
    run_frequency: 3600,
    notification: {},
    trigger_condition: 'all',
    alert_metrics: [],
  },
  [METRIC_CREATE_FORM]: {
    id: null,
    name: '',
    data_store_id: null,
    data_store_type: null,
    metric_type: null,
    where_conditions: null,
    table_name: null,
    column_name: null,
    column_type: null,
    threshold: {
      type: 'constant',
      range: [null, null],
    },
  },
  [SCHEMA_SETTINGS_RESYNC_FORM]: {
    resync_tables: [],
    all_resync_selected: false,
    reset_state: true,
  },
  [CONFIRM_ALERT_DEACTIVATION_FORM]: {
    id: null,
  },
});
