<template>
  <div class="fly-box source-destination-test-connection-step">
    <div class="fly-box icon-container">
      <FlyIcon
        :class="'fly-icon ' + (isIconWarning ? 'icon-warning' : '')"
        :name="resultIcon"
      ></FlyIcon>
    </div>
    <div class="fly-box text-container">
      <div :class="'fly-text fly-text--ui-small ' + titleColor">
        {{ title }}
      </div>
      <div class="fly-text fly-text--ui-xsmall">{{ subTitle }}</div>
      <div v-if="errorMessage" class="fly-text fly-text--ui-xsmall">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import { get, isNil } from 'lodash';

export default {
  name: 'SourceDestinationTestConnectionStep',
  components: {
    FlyIcon,
  },
  props: {
    stepResult: VueTypes.object.isRequired,
  },
  computed: {
    title() {
      if (!this.stepResult.success && !this.stepResult.mandatory) {
        return `${this.stepResult.action} (Optional)`;
      }
      return this.stepResult.action;
    },
    subTitle() {
      return this.stepResult.message;
    },
    errorMessage() {
      return get(this.stepResult, 'error.message', '');
    },
    resultIcon() {
      if (isNil(this.stepResult.success)) {
        return 'EmptyRadiobox';
      }
      if (this.stepResult.success) {
        return 'TickCircle';
      }
      return 'WarningCircle';
    },
    isIconWarning() {
      return !this.stepResult.success && !this.stepResult.mandatory;
    },
    titleColor() {
      if (isNil(this.stepResult.success)) {
        return 'grey-4';
      }
      if (this.stepResult.success) {
        return 'grey-1';
      }
      if (!this.stepResult.success && !this.stepResult.mandatory) {
        return 'grey-4';
      }
      return 'red-2';
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.source-destination-test-connection-step {
  margin: 8px 0;
  align-items: center;

  .icon-container {
    flex-shrink: 0;

    .fly-icon {
      margin-right: 16px;
      width: 16px;
      height: 16px;

      &.icon-warning {
        path {
          fill: $fly-color-yellow-3;
        }
      }
    }
  }

  .text-container {
    flex-direction: column;
    width: 100%;

    .fly-text--ui-xsmall {
      margin-top: 2px;
      color: $fly-color-grey-4;
    }

    .fly-text--ui-small {
      &.grey-4 {
        color: $fly-color-grey-4;
      }
      &.grey-1 {
        color: $fly-color-grey-1;
      }
      &.red-2 {
        color: $fly-color-red-2;
      }
    }
  }
}
</style>
