import FlySingleSelect from 'shared/components/Form/FlySingleSelect.vue';
import CLOUD_PROVIDER_REGION_ENUM from 'web/common/CLOUD_PROVIDER_REGION_ENUM';
import CLOUD_PROVIDER_ENUM from 'web/common/CLOUD_PROVIDER_ENUM';
import { markRaw } from 'vue';
import { find } from 'lodash';

export default (label, formKey) => {
  return {
    component: markRaw(FlySingleSelect),
    formKey: formKey,
    changeEventName: 'change',
    props: {
      class: 'fly-single-select--common-form',
      label,
      search: false,
      options: CLOUD_PROVIDER_REGION_ENUM[CLOUD_PROVIDER_ENUM.AWS],
    },
    allowEditAfterCreate: false,
    transformEventValue: (value) => value.value,
    transformValue: (value) =>
      find(CLOUD_PROVIDER_REGION_ENUM[CLOUD_PROVIDER_ENUM.AWS], { value }),
  };
};
