<template>
  <AppNavbar v-if="isAuthenticated && showSidebar" />
  <AppTopbar v-if="isAuthenticated && showSidebar" />
  <div class="fly-box fly-app-container"><router-view /></div>
  <ToastManager />
</template>

<script>
import { mapGetters } from 'vuex';
import { isAuthenticated } from './appGetterTypes.js';
import AppTopbar from 'web/common/nav/AppTopbar';
import AppNavbar from 'web/common/nav/AppNavbar';
import { getShowSidebar } from 'web/app/appGetterTypes';
import ToastManager from 'shared/stores/toast/ToastManager';

export default {
  name: 'App',
  components: { AppNavbar, ToastManager, AppTopbar },
  computed: {
    ...mapGetters({ isAuthenticated, showSidebar: getShowSidebar }),
  },
};
</script>

<style lang="scss">
@import '../../../../../node_modules/normalize.css';
@import '../../../shared/components/fly-variables';
@import '../../../shared/fonts/centra.css';
@import '../../../shared/fonts/source-sans.css';
@import '../../../shared/fonts/source-code.css';

body {
  overflow: hidden;
  font-family: 'Source Sans Pro', sans-serif;
  * {
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  background: map-get($--fly-colors, 'white-1');
  min-height: 100vh;
  display: flex;
}

.dt__filter {
  margin-right: 20px;

  .dt-w-64 {
    width: 100%;
  }
}

.dt__pagination_size_wrapper {
  margin-bottom: 0.75rem;
}

.dt__table_wrapper {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.dt__table__row {
  border-bottom: 1px solid #e5e7eb;
}

.dt__table__row:last-child {
  border-bottom: none;
}

.dt-bg-gray-50 {
  background-color: #f9fafb;
}

.dt-uppercase {
  text-transform: none;
}

.dt__pagination_wrapper--bottom {
  box-shadow: none;
}

table {
  border-collapse: collapse;
}

.fly-app-container {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow-y: auto;
  padding-top: 50px;
}
</style>
