const CONFIG = Object.freeze({
  SERVER_URL: process.env.VUE_APP_SERVER_URL,
  NODE_ENV: process.env.NODE_ENV,
  SENTRY_DSN: process.env.VUE_APP_SENTRY_DSN,
  CDN_URL: process.env.VUE_APP_CDN_URL,
  PUSHER_APP_KEY: process.env.VUE_APP_PUSHER_APP_KEY,
  PUSHER_CLUSTER: process.env.VUE_APP_PUSHER_CLUSTER,
  INTERCOM_APP_ID: process.env.VUE_APP_INTERCOM_APP_ID,
  RECAPTCHA_SITE_KEY: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  ENABLE_MIRAGE: process.env.VUE_APP_ENABLE_MIRAGE === 'true',
  STRIPE_PUBLISHABLE_KEY: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  S3_SOURCE_CONNECTOR_AWS_ACCOUNT_ID:
    process.env.VUE_APP_S3_SOURCE_CONNECTOR_AWS_ACCOUNT_ID,
  SYNC_CRON_VERSION: process.env.VUE_APP_SYNC_CRON_VERSION,
  AUTO_SYNC_NEW_TABLES_VERSION:
    process.env.VUE_APP_AUTO_SYNC_NEW_TABLES_VERSION,
  S3_FILE_FORMAT_PICKER_VERSION:
    process.env.VUE_APP_S3_FILE_FORMAT_PICKER_VERSION,
  // OAuth
  BING_ADS_OAUTH_CLIENT_ID: process.env.VUE_APP_BING_ADS_OAUTH_CLIENT_ID,
  SHOPIFY_OAUTH_CLIENT_ID: process.env.VUE_APP_SHOPIFY_OAUTH_CLIENT_ID,
  EBAY_SANDBOX_OAUTH_CLIENT_ID:
    process.env.VUE_APP_EBAY_SANDBOX_OAUTH_CLIENT_ID,
  EBAY_SANDBOX_OAUTH_REDIRECT_URI:
    process.env.VUE_APP_EBAY_SANDBOX_OAUTH_REDIRECT_URI,
  EBAY_PRODUCTION_OAUTH_CLIENT_ID:
    process.env.VUE_APP_EBAY_PRODUCTION_OAUTH_CLIENT_ID,
  EBAY_PRODUCTION_OAUTH_REDIRECT_URI:
    process.env.VUE_APP_EBAY_PRODUCTION_OAUTH_REDIRECT_URI,
  GOOGLE_SHEETS_OAUTH_CLIENT_ID:
    process.env.VUE_APP_GOOGLE_SHEETS_OAUTH_CLIENT_ID,
  ZENDESK_CHAT_OAUTH_CLIENT_ID:
    process.env.VUE_APP_ZENDESK_CHAT_OAUTH_CLIENT_ID,
  ADROLL_OAUTH_REDIRECT_URI: process.env.VUE_APP_ADROLL_OAUTH_REDIRECT_URI,
  ADROLL_OAUTH_CLIENT_ID: process.env.VUE_APP_ADROLL_OAUTH_CLIENT_ID,
  LINKEDIN_ADS_OAUTH_REDIRECT_URI:
    process.env.VUE_APP_LINKEDIN_ADS_OAUTH_REDIRECT_URI,
  LINKEDIN_ADS_OAUTH_CLIENT_ID:
    process.env.VUE_APP_LINKEDIN_ADS_OAUTH_CLIENT_ID,
  SALESFORCE_OAUTH_CLIENT_ID: process.env.VUE_APP_SALESFORCE_OAUTH_CLIENT_ID,
  SQUARE_OAUTH_CLIENT_ID: process.env.VUE_APP_SQUARE_OAUTH_CLIENT_ID,
  HUBSPOT_OAUTH_CLIENT_ID: process.env.VUE_APP_HUBSPOT_OAUTH_CLIENT_ID,
  PINTEREST_OAUTH_CLIENT_ID: process.env.VUE_APP_PINTEREST_OAUTH_CLIENT_ID,
  SNAPCHAT_OAUTH_CLIENT_ID: process.env.VUE_APP_SNAPCHAT_OAUTH_CLIENT_ID,
  SNAPCHAT_OAUTH_CLIENT_SECRET:
    process.env.VUE_APP_SNAPCHAT_OAUTH_CLIENT_SECRET,
  ZENDESK_SUPPORT_OAUTH_CLIENT_ID:
    process.env.VUE_APP_ZENDESK_SUPPORT_OAUTH_CLIENT_ID,
  MAILCHIMP_OAUTH_CLIENT_ID: process.env.VUE_APP_MAILCHIMP_OAUTH_CLIENT_ID,
  TIKTOK_ADS_OAUTH_APP_ID: process.env.VUE_APP_TIKTOK_ADS_OAUTH_APP_ID,
  TIKTOK_ADS_OAUTH_APP_RID: process.env.VUE_APP_TIKTOK_ADS_OAUTH_APP_RID,
  INTUIT_QUICKBOOKS_OAUTH_CLIENT_ID:
    process.env.VUE_APP_INTUIT_QUICKBOOKS_OAUTH_CLIENT_ID,
  FACEBOOK_OAUTH_CLIENT_ID: process.env.VUE_APP_FACEBOOK_OAUTH_CLIENT_ID,
  FACEBOOK_OAUTH_CONFIG_ID: process.env.VUE_APP_FACEBOOK_OAUTH_CONFIG_ID,
  NETSUITE_OAUTH_CLIENT_ID: process.env.VUE_APP_NETSUITE_OAUTH_CLIENT_ID,
  SEGMENT_KEY: process.env.VUE_APP_SEGMENT_KEY,
  YOUTUBE_OAUTH_CLIENT_ID: process.env.VUE_APP_YOUTUBE_OAUTH_CLIENT_ID,
  GITHUB_OAUTH_CLIENT_ID: process.env.VUE_APP_GITHUB_OAUTH_CLIENT_ID,
  RINGCENTRAL_OAUTH_CLIENT_ID: process.env.VUE_APP_RINGCENTRAL_OAUTH_CLIENT_ID,
  RINGCENTRAL_OAUTH_CLIENT_SECRET:
    process.env.VUE_RINGCENTRAL_OAUTH_CLIENT_SECRET,
  DRIP_OAUTH_CLIENT_ID: process.env.VUE_APP_DRIP_OAUTH_CLIENT_ID,
  SLACK_OAUTH_CLIENT_ID: process.env.VUE_APP_SLACK_OAUTH_CLIENT_ID,
  AMAZON_OAUTH_CLIENT_ID: process.env.VUE_APP_AMAZON_OAUTH_CLIENT_ID,
  AMAZON_MARKETPLACE_OAUTH_APPLICATION_ID:
    process.env.VUE_APP_AMAZON_MARKETPLACE_OAUTH_APPLICATION_ID,
  AMAZON_MARKETPLACE_OAUTH_CLIENT_ID:
    process.env.VUE_APP_AMAZON_MARKETPLACE_OAUTH_CLIENT_ID,
  AMAZON_MARKETPLACE_MODE: process.env.VUE_APP_AMAZON_MARKETPLACE_MODE,
  INTEGRATEIO_URL: process.env.VUE_APP_INTEGRATEIO_URL,
  HUBSPOT_URL: process.env.VUE_APP_HUBSPOT_URL,
  ZENDESK_SELL_OAUTH_CLIENT_ID:
    process.env.VUE_APP_ZENDESK_SELL_OAUTH_CLIENT_ID,
  AIRTABLE_OAUTH_CLIENT_ID: process.env.VUE_APP_AIRTABLE_OAUTH_CLIENT_ID,
  AIRTABLE_OAUTH_REDIRECT_URI: process.env.VUE_APP_AIRTABLE_OAUTH_REDIRECT_URI,
  S3_EXTERNAL_ID_SECRET_KEY: process.env.VUE_APP_S3_EXTERNAL_ID_SECRET_KEY,
});

export default CONFIG;
