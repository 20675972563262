import CLOUD_PROVIDER_ENUM from 'web/common/CLOUD_PROVIDER_ENUM';
export default Object.freeze({
  [CLOUD_PROVIDER_ENUM.AWS]: [
    {
      label: 'US East (N. Virginia) / us-east-1',
      regionCode: 'us-east-1',
      value: 1,
    },
    { label: 'US East (Ohio) / us-east-2', regionCode: 'us-east-2', value: 2 },
    {
      label: 'US West (N. California) / us-west-1',
      regionCode: 'us-west-1',
      value: 3,
    },
    {
      label: 'US West (Oregon) / us-west-2',
      regionCode: 'us-west-2',
      value: 4,
    },
    {
      label: 'Africa (Cape Town) / af-south-1',
      regionCode: 'af-south-1',
      value: 5,
    },
    {
      label: 'Asia Pacific (Hong Kong) / ap-east-1',
      regionCode: 'ap-east-1',
      value: 6,
    },
    {
      label: 'Asia Pacific (Mumbai) / ap-south-1',
      regionCode: 'ap-south-1',
      value: 7,
    },
    {
      label: 'Asia Pacific (Osaka) / ap-northeast-3',
      regionCode: 'ap-northeast-3',
      value: 8,
    },
    {
      label: 'Asia Pacific (Seoul) / ap-northeast-2',
      regionCode: 'ap-northeast-2',
      value: 9,
    },
    {
      label: 'Asia Pacific (Singapore) / ap-southeast-1',
      regionCode: 'ap-southeast-1',
      value: 10,
    },
    {
      label: 'Asia Pacific (Sydney) / ap-southeast-2',
      regionCode: 'ap-southeast-2',
      value: 11,
    },
    {
      label: 'Asia Pacific (Tokyo) / ap-northeast-1',
      regionCode: 'ap-northeast-1',
      value: 12,
    },
    {
      label: 'Canada (Central) / ca-central-1',
      regionCode: 'ca-central-1',
      value: 13,
    },
    {
      label: 'China (Beijing) / cn-north-1',
      regionCode: 'cn-north-1',
      value: 14,
    },
    {
      label: 'China (Ningxia) / cn-northwest-1',
      regionCode: 'cn-northwest-1',
      value: 15,
    },
    {
      label: 'Europe (Frankfurt) / eu-central-1',
      regionCode: 'eu-central-1',
      value: 16,
    },
    {
      label: 'Europe (Ireland) / eu-west-1',
      regionCode: 'eu-west-1',
      value: 17,
    },
    {
      label: 'Europe (London) / eu-west-2',
      regionCode: 'eu-west-2',
      value: 18,
    },
    {
      label: 'Europe (Milan) / eu-south-1',
      regionCode: 'eu-south-1',
      value: 19,
    },
    { label: 'Europe (Paris) / eu-west-3', regionCode: 'eu-west-3', value: 20 },
    {
      label: 'Europe (Stockholm) / eu-north-1',
      regionCode: 'eu-north-1',
      value: 21,
    },
    {
      label: 'Middle East (Bahrain) / me-south-1',
      regionCode: 'me-south-1',
      value: 22,
    },
    {
      label: 'South America (São Paulo) / sa-east-1',
      regionCode: 'sa-east-1',
      value: 23,
    },
  ],
});
