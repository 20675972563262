import createService from 'shared/utils/createService.js';
import { USER_API_URL, UPDATE_PASSWORD_URL } from './userEnum.js';

export default createService(USER_API_URL, {
  extraMethods: {
    async updatePassword(data) {
      return this.request({
        url: UPDATE_PASSWORD_URL,
        method: 'post',
        data,
      });
    },
    async inviteUser(data) {
      return this.request({
        url: `${USER_API_URL}invite/`,
        method: 'post',
        data,
      });
    },
    async resendUserInvite(id) {
      return this.request({
        url: `${USER_API_URL}resend-invite/${id}/`,
        method: 'post',
      });
    },
    async deleteUserInvite(id) {
      return this.request({
        url: `${USER_API_URL}delete-invite/${id}/`,
        method: 'post',
      });
    },
    async makeAdmin(id) {
      return this.request({
        url: `${USER_API_URL}make-admin/${id}/`,
        method: 'post',
      });
    },
    async activateUser(id) {
      return this.request({
        url: `${USER_API_URL}activate/${id}/`,
        method: 'post',
      });
    },
    async updateClientNotificationSettings(data) {
      return this.request({
        url: `${USER_API_URL}update-client-notification-settings/`,
        method: 'post',
        data,
      });
    },
  },
});
