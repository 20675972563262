<template>
  <div class="fly-box pipeline-ssh-connect-secure-tunnel">
    <FlyRadio
      :value="true"
      :disabled="areTunnelsEmpty"
      :selected-value="useExistingTunnel"
      label="Use an existing tunnel"
      :help-text="`Choose an existing tunnel which can connect to this ${sourceOrDestination}`"
      @change="onUseExistingChange"
    />
    <FlyLink
      v-if="useExistingTunnel"
      class="fly-text fly-text--caption"
      target="_blank"
      href="https://www.integrate.io/docs/cdc/secure-vpn-tunnel-for-rds-and-redshift/"
    >
      More information on Secure Tunnel
    </FlyLink>
    <div
      v-if="useExistingTunnel && !areTunnelsEmpty"
      class="fly-box existing-tunnel-container"
    >
      <FlySingleSelect
        :value="selectedTunnel"
        placeholder="Select a tunnel"
        label-key="name"
        sub-text-key="active_label"
        :options="tunnelsWithLabels"
        :filter-properties="['name']"
        :search="false"
        @change="onTunnelSelectionChange"
      />
      <template v-if="selectedTunnel && !isSSH">
        <FlyLink
          class="fly-text fly-text--ui-small download-link"
          :href="downloadLink"
          target="_blank"
          >Download CloudFormation template</FlyLink
        >
        <div class="fly-text fly-text--ui-small">|</div>
        <FlyLink
          class="fly-text fly-text--ui-small download-link"
          :href="downloadLinkScript"
          target="_blank"
          >Download install script</FlyLink
        >
      </template>
    </div>
    <div
      v-if="selectedTunnel && isSSH"
      class="fly-box selected-tunnel-container"
    >
      <div v-if="selectedTunnel.ssh_host" class="fly-box value-container">
        <div class="fly-text fly-text--ui-medium value-title">SSH Host</div>
        <div class="fly-text fly-text--ui-medium value">
          {{ selectedTunnel.ssh_host }}
        </div>
      </div>
      <div v-if="selectedTunnel.ssh_port" class="fly-box value-container">
        <div class="fly-text fly-text--ui-medium value-title">SSH Port</div>
        <div
          v-bind="TEXT_VARIANTS_ENUM.UI_MEDIUM"
          class="fly-text fly-text--ui-medium value"
        >
          {{ selectedTunnel.ssh_port }}
        </div>
      </div>
      <div v-if="selectedTunnel.ssh_user" class="fly-box value-container">
        <div class="fly-text fly-text--ui-medium value-title">SSH User</div>
        <div
          v-bind="TEXT_VARIANTS_ENUM.UI_MEDIUM"
          class="fly-text fly-text--ui-medium value"
        >
          {{ selectedTunnel.ssh_user }}
        </div>
      </div>
    </div>
    <div
      v-if="useExistingTunnel && selectedTunnel"
      class="fly-box existing-selected-tunnel-container"
    >
      <SecureTunnelStatus :tunnel="selectedTunnel" :show-download-link="true" />
      <FlyButtonRaw
        class="fly-button fly-button--secondary fly-button--small"
        type="button"
        @click="testTunnelConnection"
      >
        Test Tunnel Connection
      </FlyButtonRaw>
    </div>
    <FlyRadio
      :value="false"
      :selected-value="useExistingTunnel"
      label="Create a new tunnel"
      help-text="Setup a new tunnel using either CloudFormation or the bash installation script"
      @change="onUseExistingChange"
    />
    <FlyLink
      v-if="!useExistingTunnel"
      class="fly-text fly-text--caption more-info"
      target="_blank"
      href="https://www.integrate.io/docs/cdc/secure-vpn-tunnel-for-rds-and-redshift/"
    >
      More information on Secure Tunnel
    </FlyLink>
    <PipelineSSHConnectCreateSecureTunnelForm
      v-if="!useExistingTunnel"
      :region="region"
      :provider="provider"
      @create="onTunnelCreate"
    />
    <div
      v-if="!useExistingTunnel && selectedTunnel"
      class="fly-box non-existing-selected-tunnel-container"
    >
      <SecureTunnelStatus :tunnel="selectedTunnel" />
      <FlyButtonRaw
        class="fly-button fly-button--secondary fly-button--small"
        type="button"
        @click="testTunnelConnection"
      >
        Test Tunnel Connection
      </FlyButtonRaw>
    </div>
  </div>
  <SourceDestinationConnectionValidationModal
    v-if="showTunnelTestConnectionModal"
    :loading="isTestingTunnelConnection"
    :test-connection-result="testTunnelConnectionResult"
    @close="closeTestTunnelModal"
    ><template #statusText
      ><div
        v-if="!isTestingTunnelConnection && !isConnectionSuccessful"
        class="fly-text fly-text--ui-medium pipeline-ssh-connect-secure-tunnel-validation-modal-text"
      >
        {{ statusText }}
      </div></template
    ></SourceDestinationConnectionValidationModal
  >
</template>

<script>
import VueTypes from 'vue-types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FlyRadio from 'shared/components/Form/FlyRadio.vue';
import FlySingleSelect from 'shared/components/Form/FlySingleSelect.vue';
import { isLoading } from 'web/loading/loadingGetterTypes';
import PipelineSSHConnectCreateSecureTunnelForm from 'web/pipelines/create/PipelineSSHConnectCreateSecureTunnelForm';
import SourceDestinationConnectionValidationModal from 'web/pipelines/SourceDestinationConnectionValidationModal';
import { get, isEmpty } from 'lodash';
import { has } from 'lodash/object';
import SecureTunnelStatus from 'web/pipelines/create/SecureTunnelStatus';
import { TUNNEL_API_URL } from 'web/tunnel/tunnelEnum';
import FlyLink from 'shared/components/Link/FlyLink.vue';
import FlyButtonRaw from 'shared/components/Button/FlyButton.vue';
import { testTunnelConnectionAction } from 'web/tunnel/tunnelActionTypes';
import { setModalOpenState } from 'shared/store/modal/modalMutationTypes';
import { TUNNEL_CONNECTION_VALIDATION_MODAL } from 'web/modal/modalKeys';
import { showTunnelTestConnectionModal } from 'web/pipelines/pipelinesGetterTypes';
import {
  getTunnelTestConnectionResult,
  isTunnelTestConnectionResultSuccessful,
} from 'web/tunnel/tunnelGetterTypes';

export default {
  name: 'PipelineSSHConnectSecureTunnel',
  components: {
    SecureTunnelStatus,
    SourceDestinationConnectionValidationModal,
    PipelineSSHConnectCreateSecureTunnelForm,
    FlyRadio,
    FlySingleSelect,
    FlyLink,
    FlyButtonRaw,
  },
  props: {
    tunnels: VueTypes.array.isRequired,
    selectedTunnel: VueTypes.object,
    useExistingTunnel: VueTypes.bool.isRequired,
    sourceOrDestination: VueTypes.oneOf(['source', 'destination']).isRequired,
    region: VueTypes.number.def(null),
    provider: VueTypes.number.isRequired,
  },
  emits: [
    'use-existing-tunnel-change',
    'tunnel-selection-change',
    'refresh-tunnel-list',
  ],
  computed: {
    ...mapGetters({
      isLoading,
      testTunnelConnectionResult: getTunnelTestConnectionResult,
      isConnectionSuccessful: isTunnelTestConnectionResultSuccessful,
      showTunnelTestConnectionModal,
    }),
    areTunnelsEmpty() {
      return isEmpty(this.tunnels);
    },
    tunnelId() {
      return get(this.selectedTunnel, 'id');
    },
    downloadLink() {
      return `${TUNNEL_API_URL}${this.tunnelId}/`;
    },
    downloadLinkScript() {
      return `${TUNNEL_API_URL}${this.tunnelId}/?config_type=install_script`;
    },
    tunnelsWithLabels() {
      return this.tunnels
        .map((obj) => ({
          ...obj,
          active_label: `${obj.type === 0 ? 'Wireguard' : 'SSH Tunnel'} · ${
            get(obj, 'is_active') ? 'Active' : 'Inactive'
          }`,
        }))
        .sort((a, b) => {
          return a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1;
        });
    },
    isTestingTunnelConnection() {
      return this.isLoading([testTunnelConnectionAction]);
    },
    hasValidationResults() {
      return (
        !this.isTestingTunnelConnection &&
        has(this.testTunnelConnectionResult, 'results') &&
        !isEmpty(this.testTunnelConnectionResult.results)
      );
    },
    statusText() {
      if (!this.hasValidationResults) {
        return 'An unexpected error occurred. Please try again or contact support if the issue persists.';
      }
      return 'Please check and fix the issues below.';
    },
    isSSH() {
      console.log(this.selectedTunnel);
      return this.selectedTunnel && !!this.selectedTunnel.ssh_public_key;
    },
  },
  methods: {
    ...mapMutations({
      setModalOpenState,
    }),
    ...mapActions({
      testTunnelConnectionAction,
    }),
    onUseExistingChange(e) {
      this.$emit('use-existing-tunnel-change', e);
    },
    onTunnelSelectionChange(tunnel) {
      this.$emit('tunnel-selection-change', tunnel);
    },
    onTunnelCreate(tunnel) {
      this.$emit('tunnel-selection-change', tunnel);
    },
    closeTestTunnelModal() {
      this.setModalOpenState({
        key: TUNNEL_CONNECTION_VALIDATION_MODAL,
        open: false,
      });
    },
    async testTunnelConnection() {
      this.setModalOpenState({
        key: TUNNEL_CONNECTION_VALIDATION_MODAL,
        open: true,
      });
      await this.testTunnelConnectionAction(this.tunnelId);
      this.$emit('refresh-tunnel-list');
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.pipeline-ssh-connect-secure-tunnel {
  flex-direction: column;
  padding: 16px 0;

  .fly-radio {
    padding: 20px 0;
    margin-right: 32px;

    .fly-radio--label-box {
      .fly-radio--label {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 8px;
        font-weight: 500;
      }
    }
  }

  .fly-text--caption {
    font-family: $--fly-font-family-body;
    color: $fly-color-blue-1;
    margin-left: 36px;
    margin-bottom: 20px;

    &.more-info {
      margin-bottom: 12px;
    }
  }

  .existing-tunnel-container {
    margin-left: 36px;
    margin-bottom: 20px;
    align-items: center;

    .download-link {
      color: $fly-color-blue-1;
      margin-left: 16px;
    }

    .fly-text--ui-small {
      color: $fly-color-grey-4;
      margin-left: 16px;
    }
  }

  .selected-tunnel-container {
    flex-direction: column;
    padding-left: 36px;

    &:last-child {
      padding-left: 0;
      margin-bottom: 12px;
    }

    .value-container {
      flex-direction: column;
      margin-bottom: 20px;

      .value-title {
        font-weight: 500;
      }

      .value {
        margin-top: 8px;
        color: $fly-color-grey-3;
      }
    }
  }

  .existing-selected-tunnel-container {
    margin-bottom: 20px;
    margin-top: 12px;
    flex-direction: column;
    align-items: flex-start;

    .secure-tunnel-status {
      margin: 0 12px 16px 36px;
    }

    .fly-button--secondary {
      margin-left: 36px;
    }
  }

  .non-existing-selected-tunnel-container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;

    .secure-tunnel-status {
      margin-left: 36px;
      margin-right: 12px;
      margin-bottom: 16px;
    }

    .fly-button--secondary {
      margin-left: 36px;
    }
  }
}

.pipeline-ssh-connect-secure-tunnel-validation-modal-text {
  padding: 24px 0;
  color: $fly-color-grey-2;
  margin-bottom: 16px;
}
</style>
