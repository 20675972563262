export default Object.freeze({
  SINGER_TAPS_ENABLED: 'SINGER_TAPS_ENABLED',
  NATIVE_TAPS_ENABLED: 'NATIVE_TAPS_ENABLED',
  GENERIC_TAPS_ENABLED: 'GENERIC_TAPS_ENABLED',
  RESYNC_TOOL: 'RESYNC_TOOL',
  BIGQUERY_SINK_ENABLED: 'BIGQUERY_SINK_ENABLED',
  DATA_OBSERVABILITY_ENABLED: 'DATA_OBSERVABILITY_ENABLED',
  SANDBOX_TESTING: 'SANDBOX_TESTING',
  ETL_SCRIPT: 'ETL_SCRIPT',
  S3_FILE_FORMAT_PICKER: 'S3_FILE_FORMAT_PICKER',
});
