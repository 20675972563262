<template>
  <div
    class="fly-box file-storage-schema-importer"
    flex-direction="column"
    margin-left="2"
  >
    <div class="fly-box header-container">
      <FlyLink
        class="fly-text fly-text--ui-small fly-link"
        href="#"
        @click.prevent="refreshSchema"
      >
        <FlyIcon name="Refresh" class="fly-icon" />
        Sample Files and Refresh Schema
      </FlyLink>
    </div>
    <div
      v-if="!isFetchingSchema && !sampleFilesHasError"
      class="fly-box table-container"
    >
      <data-table
        :rows="columnsData"
        :pagination="pagination"
        :query="query"
        filter
        @load-data="loadData"
      >
        <template #thead>
          <table-head
            ><div class="fly-text table-header">Column Name</div></table-head
          >
          <table-head>
            <div class="fly-text table-header">Column Type</div>
          </table-head>
          <table-head>
            <FlyCheckbox
              label="Is Nullable"
              :checked="allColumnsNullable"
              @change="onNullableAllClick"
            />
          </table-head>
        </template>

        <template #tbody="{ row }">
          <table-body>
            <span>{{ row.column_name }}</span>
          </table-body>
          <table-body>
            <FlySingleSelect
              :search="false"
              :options="dataTypeOptions"
              :value="getRowDataType(row)"
              @change="onDataTypeChange(row, $event)"
            />
          </table-body>
          <table-body>
            <FlyCheckbox
              label="Is Nullable"
              :checked="row.is_nullable === 'YES'"
              @change="onIsNullableChange(row, $event)"
            />
          </table-body>
        </template>
      </data-table>
    </div>
    <h3 v-if="isFetchingSchema" class="fly-text fly-text--h3">
      Loading schema...
    </h3>
    <h3 v-if="sampleFilesHasError && !isFetchingSchema">
      {{ sampleFilesError }}
    </h3>
  </div>
</template>

<script>
import { ref } from 'vue';
import FormStoreMixin from 'shared/stores/form/FormStoreMixin';
import { CREATE_SOURCE_FORM } from 'web/form/formKeys';
import FlyCheckbox from 'shared/components/Form/FlyCheckbox.vue';
import {
  get,
  every,
  each,
  findIndex,
  find,
  isEmpty,
  uniqBy,
  omit,
} from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import FlyLink from 'shared/components/Link/FlyLink.vue';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import { DataTable, TableBody, TableHead } from '@jobinsjp/vue3-datatable';
import { getSchemaAction } from 'web/sources/sourcesActionTypes';
import FlySingleSelect from 'shared/components/Form/FlySingleSelect.vue';
import { isLoading } from 'web/loading/loadingGetterTypes';
import dataTypeOptions from './fileStorageDataTypesOptions.js';
import { hasError } from 'web/error/errorGetterTypes.js';

function paginate(data, page, perPage) {
  const start = (page - 1) * perPage;
  const end = page * perPage;
  return data.slice(start, end);
}

export default {
  name: 'FileStorageSchemaImporter',
  components: {
    FlyCheckbox,
    DataTable,
    TableBody,
    TableHead,
    FlyLink,
    FlyIcon,
    FlySingleSelect,
  },
  mixins: [FormStoreMixin(CREATE_SOURCE_FORM)],
  emits: ['columnsChange'],
  setup() {
    const query = ref({
      search: '',
    });

    return { query };
  },
  data() {
    return {
      columnsData: [],
      pagination: {
        page: 1,
        total: 20,
      },
      ...dataTypeOptions,
      sampleFilesError: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoading,
      hasError,
    }),
    isFetchingSchema() {
      return this.isLoading(getSchemaAction);
    },
    columns() {
      return get(this.formData, 'config.columns', []);
    },
    config() {
      return get(this.formData, 'config', {});
    },
    sampleOptions() {
      return {
        num_files_to_sample: get(
          this.config,
          'sample_options.num_files_to_sample',
          5,
        ),
        sample_length: get(this.config, 'sample_options.sample_length', 50),
        sample_rate: get(this.config, 'sample_options.sample_rate', 5),
        has_headers: get(this.config, 'sample_options.has_headers', true),
      };
    },
    allColumnsNullable() {
      if (!this.columns.length) {
        return false;
      }
      let queryCollections = this.query.search
        ? this.columns.filter((item) =>
            item.column_name
              .toLowerCase()
              .includes(this.query.search.toLowerCase()),
          )
        : this.columns;
      if (isEmpty(queryCollections)) return false;
      return every(queryCollections, ({ column_name }) => {
        return find(this.columns, { column_name }).is_nullable === 'YES';
      });
    },
    sampleFilesHasError() {
      return this.hasError(getSchemaAction);
    },
  },
  mounted() {
    this.loadData({ page: 1, total: 10, per_page: 10 });
  },
  methods: {
    ...mapActions({
      getSchemaAction,
    }),
    loadData(query) {
      this.query.search = query.search;
      this.columnsData = query.search
        ? this.columns.filter((item) =>
            item.column_name.toLowerCase().includes(query.search.toLowerCase()),
          )
        : this.columns;
      const total = this.columnsData.length;
      this.columnsData = paginate(this.columnsData, query.page, query.per_page);
      this.pagination = {
        ...this.pagination,
        page: query.page,
        total,
      };
    },
    onDataTypeChange({ column_name }, e) {
      const index = findIndex(this.columns, { column_name: column_name });
      this.setFormFieldAtPath(`config.columns[${index}].data_type`, e.value);
      this.emitColumnsFormChange();
    },
    onIsNullableChange({ column_name }, e) {
      const index = findIndex(this.columns, { column_name: column_name });
      this.setFormFieldAtPath(
        `config.columns[${index}].is_nullable`,
        e.target.checked ? 'YES' : 'NO',
      );
      this.emitColumnsFormChange();
    },
    setPropertyForAll(propertyName, targetValue) {
      let defaultValue = [];
      let formColumns =
        get(this.formData, 'config.columns', defaultValue) || defaultValue;
      let queryColumns = this.query.search
        ? this.columns.filter((item) =>
            item.column_name
              .toLowerCase()
              .includes(this.query.search.toLowerCase()),
          )
        : this.columns;
      queryColumns.forEach(({ column_name }) => {
        formColumns[findIndex(this.columns, ['column_name', column_name])][
          propertyName
        ] = targetValue;
      });
      this.setFormFieldAtPath('config.columns', formColumns);
      this.emitColumnsFormChange();
    },
    onNullableAllClick(e) {
      this.setPropertyForAll('is_nullable', e.target.checked ? 'YES' : 'NO');
    },
    emitColumnsFormChange() {
      this.$emit('columnsChange', this.columns);
    },
    getRowDataType({ data_type }) {
      return find(this.dataTypeOptions, { value: data_type });
    },
    async refreshSchema() {
      try {
        const oldSchema = get(this.formData, 'config.columns', []);
        const configWithoutColumns = omit(this.config, 'columns');
        const getSchemaResponse = await this.getSchemaAction({
          config: configWithoutColumns,
          data_store: this.formData.data_store,
          sample_options: this.sampleOptions,
        });
        const newSchemaResponse = get(getSchemaResponse, 'data', {});
        const newSchema = [];
        each(newSchemaResponse, (value, key) => {
          newSchema.push({
            column_name: key,
            data_type: value.type,
            is_nullable: 'YES',
          });
        });
        const resultSchema = uniqBy(
          [...oldSchema, ...newSchema],
          'column_name',
        );
        this.setFormFieldAtPath('config.columns', resultSchema);
        this.loadData({ page: 1, total: 10, per_page: 10 });
      } catch (error) {
        this.setFormFieldAtPath('config.columns', []);
        const errorResponseDetail = get(
          error,
          'response.data.detail.message',
          'An error occurred while fetching schema, please check the configuration and try again.',
        );
        this.sampleFilesError = JSON.stringify(errorResponseDetail);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.file-storage-schema-importer {
  flex-direction: column;
  margin-left: 8px;
  width: 100%;

  .header-container {
    align-items: center;
    justify-content: space-between;
    position: sticky;
    padding-top: 4px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    top: 0;
    z-index: 1;

    .fly-link {
      color: $fly-color-blue-1;
      margin-right: 8px;

      .fly-icon {
        path {
          fill: $fly-color-blue-1;
        }
      }
    }
  }

  .table-container {
    .data-table {
      width: 100%;

      .table-header {
        color: $fly-color-grey-1;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .fly-text--h3 {
    color: $fly-color-grey-4;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

select.dt__pagination_size {
  width: 80px;
}
</style>
