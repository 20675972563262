export const archiveHookAction = 'archiveHookAction';
export const changeHookStatusAction = 'changeHookStatusAction';
export const openHookCreateModalAction = 'openHookCreateModalAction';
export const openHookAttachModalAction = 'openHookAttachModalAction';
export const createHookAction = 'createHookAction';
export const updateHookAction = 'updateHookAction';
export const attachHookAction = 'attachHookAction';
export const getHookAttachmentAction = 'getHookAttachmentAction';
export const detachHookAction = 'detachHookAction';
export const getHookAction = 'getHookAction';
export const editHookAttachmentAction = 'editHookAttachmentAction';
