import axios from 'axios';
import qs from 'qs';
import { isString, isNumber, each, set } from 'lodash';
import CONFIG from '../config';
import _getCSRFToken from 'shared/utils/getCSRFToken.js';

const getRouteAndParams = (idOrQuery, originalRoute) => {
  let route = originalRoute;
  let params = {};
  if (isString(idOrQuery) || isNumber(idOrQuery)) {
    route = `${route}${idOrQuery}/`;
  } else {
    params = idOrQuery;
  }
  return { route, params };
};

const resourceMethods = {
  async list(params, config = {}) {
    const response = await this.axiosInstance.request({
      url: this.route,
      params,
      ...config,
    });
    return response.data;
  },
  async get(id, config = {}) {
    const response = await this.axiosInstance.request({
      url: `${this.route}${id}/`,
      ...config,
    });
    return response.data;
  },
  async update(idOrQuery, data, config) {
    const { route: computedRoute, params } = getRouteAndParams(
      idOrQuery,
      this.route
    );

    const requestConfig = {
      url: computedRoute,
      method: 'patch',
      params,
      data,
      ...config,
    };

    this.setCSRFTokenInRequestConfig(requestConfig);

    const response = await this.axiosInstance.request(requestConfig);
    return response.data;
  },
  async create(data, config) {
    const requestConfig = {
      url: this.route,
      method: 'post',
      data,
      ...config,
    };

    this.setCSRFTokenInRequestConfig(requestConfig);

    const response = await this.axiosInstance.request(requestConfig);
    return response.data;
  },
  async remove(idOrQuery, config) {
    const { route: computedRoute, params } = getRouteAndParams(
      idOrQuery,
      this.route
    );

    const requestConfig = {
      url: computedRoute,
      method: 'delete',
      params,
      ...config,
    };

    this.setCSRFTokenInRequestConfig(requestConfig);

    const response = await this.axiosInstance.request(requestConfig);
    return response.data;
  },
  async request(config) {
    this.setCSRFTokenInRequestConfig(config);
    const response = await this.axiosInstance.request(config);
    return response.data;
  },
};

export default (
  route,
  {
    extraMethods = {},
    subResources = {},
    getCSRFToken = _getCSRFToken,
    withCredentials = true,
  } = {}
) => {
  const axiosInstance = axios.create({
    baseURL: CONFIG.SERVER_URL || '',
    paramsSerializer: (params) => {
      return qs.stringify(params, { indices: false });
    },
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials,
  });

  function setCSRFTokenInRequestConfig(requestConfig) {
    set(requestConfig, 'headers["X-CSRFToken"]', this.getCSRFToken());
    return requestConfig;
  }

  const wrappedSubResources = {};

  each(subResources, (subRoute, key) => {
    wrappedSubResources[key] = {
      axiosInstance,
      route: `${route}${subRoute}`,
      getCSRFToken,
      setCSRFTokenInRequestConfig,
      ...resourceMethods,
    };
  });

  return {
    axiosInstance,
    route,
    getCSRFToken,
    setCSRFTokenInRequestConfig,
    ...resourceMethods,
    ...extraMethods,
    ...wrappedSubResources,
  };
};
