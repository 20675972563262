import createServiceStore from 'shared/utils/createServiceStore.js';
import { PIPELINES_SERVICE_KEY } from './pipelinesEnum.js';
import pipelinesService from './pipelinesService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import { SOURCES_SERVICE_KEY } from 'web/sources/sourcesEnum';
import { DESTINATIONS_SERVICE_KEY } from 'web/destinations/destinationsEnum';
import {
  changePipelineStatusAction,
  downloadETLScriptAction,
  resyncCollectionsAction,
} from 'web/pipelines/pipelinesActionTypes';

export default createServiceStore({
  serviceName: PIPELINES_SERVICE_KEY,
  service: pipelinesService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  references: {
    source: SOURCES_SERVICE_KEY,
    destination: DESTINATIONS_SERVICE_KEY,
  },
  actions: {
    async [changePipelineStatusAction](
      { commit },
      { pipelineId, status, silent = false },
    ) {
      !silent &&
        commit(setLoading, { key: changePipelineStatusAction, value: status });
      commit(setError, { key: changePipelineStatusAction, value: null });

      try {
        await pipelinesService.changeStatus(pipelineId, status);
      } catch (err) {
        commit(setError, { key: changePipelineStatusAction, value: err });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, { key: changePipelineStatusAction, value: false });
      }
    },
    async [resyncCollectionsAction]({ commit }, { pipelineId, data }) {
      commit(setLoading, { key: resyncCollectionsAction, value: true });
      commit(setError, { key: resyncCollectionsAction, value: null });

      try {
        await pipelinesService.resyncCollections(pipelineId, data);
      } catch (err) {
        commit(setError, { key: resyncCollectionsAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: resyncCollectionsAction, value: false });
      }
    },
    async [downloadETLScriptAction](
      { commit },
      { pipelineId, etl_source_id, etl_destination_id },
    ) {
      commit(setLoading, { key: downloadETLScriptAction, value: true });
      commit(setError, { key: downloadETLScriptAction, value: null });

      try {
        return await pipelinesService.downloadETLScript(
          pipelineId,
          etl_source_id,
          etl_destination_id,
        );
      } catch (err) {
        commit(setError, { key: downloadETLScriptAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: downloadETLScriptAction, value: false });
      }
    },
  },
});
