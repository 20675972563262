import { filter, isUndefined, isArray } from 'lodash';
import {
  setTableSearchQuery,
  setUseExistingTunnel,
} from 'web/pipelines/pipelinesMutationTypes';
import {
  canDestinationBeFullyEdited,
  canNodeBeFullyEdited,
  canSourceBeFullyEdited,
  destinationFormFieldConfig,
  destinationFormFieldToRender,
  getActivePipelineSetupStepIndex,
  getCompletedPipelineSetupSteps,
  getCurrentPipeline,
  getPipelineForDestination,
  getPipelineForSource,
  getPipelineSetupSteps,
  getTableSearchQuery,
  getUseExistingTunnel,
  isFetchingPipeline,
  nodeFormFieldConfig,
  nodeFormFieldGroupsToRender,
  nodeFormFields,
  previousPipelineSetupStepLink,
  showTestConnectionModal,
  showTunnelTestConnectionModal,
  sourceFormFieldConfig,
  sourceFormFieldToRender,
} from 'web/pipelines/pipelinesGetterTypes';
import createSourceFormConfig from 'web/pipelines/create/source/createSourceFormConfig';
import createDestinationFormConfig from 'web/pipelines/create/destination/createDestinationFormConfig';
import { isModalOpen } from 'shared/store/modal/modalGetterTypes';
import {
  SOURCE_DESTINATION_CONNECTION_VALIDATION_MODAL,
  TUNNEL_CONNECTION_VALIDATION_MODAL,
} from 'web/modal/modalKeys';
import pipelinesServiceGetters from 'web/pipelines/pipelinesServiceGetters';
import { isLoading } from 'web/loading/loadingGetterTypes';
import pipelinesServiceActions from 'web/pipelines/pipelinesServiceActions';
import { map } from 'lodash/collection';
import { flatten } from 'lodash/array';
import PIPELINE_STATUS_ENUM from 'web/pipelines/PIPELINE_STATUS_ENUM';

export default {
  state: {
    useExistingTunnel: false,
    tableSearchQuery: '',
    pipelineSetup: {
      stepConfig: [
        {
          title: 'Configure Source',
          to: {
            name: 'pipelineCreateSourceReview',
          },
        },
        {
          title: 'Configure Destination',
          backTo: {
            name: 'pipelineCreateSourceReview',
          },
          to: {
            name: 'pipelineCreateDestinationReview',
          },
        },
        {
          title: 'Schema Settings',
          backTo: {
            name: 'pipelineCreateDestinationReview',
          },
          to: {
            name: 'pipelineCreateSchemaSettings',
          },
        },
        {
          title: 'Sync Settings',
          backTo: {
            name: 'pipelineCreateSchemaSettings',
          },
        },
      ],
      stepGroupIndex: {
        source: 0,
        destination: 1,
        schema: 2,
      },
    },
  },
  getters: {
    [getActivePipelineSetupStepIndex](state, getters, rootState) {
      const index =
        state.pipelineSetup.stepGroupIndex[rootState.route.meta.stepGroup];
      return !isUndefined(index) ? index : 3;
    },
    [getCompletedPipelineSetupSteps](state, getters) {
      return getters[getActivePipelineSetupStepIndex] + 1;
    },
    [getPipelineSetupSteps](state, getters) {
      return state.pipelineSetup.stepConfig.map((step, index) => {
        return {
          ...step,
          isActive: index === getters[getActivePipelineSetupStepIndex],
          isDone: index < getters[getActivePipelineSetupStepIndex],
        };
      });
    },
    [previousPipelineSetupStepLink](state, getters) {
      return state.pipelineSetup.stepConfig[
        getters[getActivePipelineSetupStepIndex]
      ].backTo;
    },
    [getUseExistingTunnel](state) {
      return state.useExistingTunnel;
    },
    [nodeFormFieldConfig]: (state, getters, rootState) => (type, nodeType) => {
      if (nodeType === 'source') {
        return createSourceFormConfig[type || rootState.route.query.type];
      }

      if (nodeType === 'destination') {
        return createDestinationFormConfig[type || rootState.route.query.type];
      }

      return {};
    },
    [nodeFormFieldGroupsToRender]: (state, getters) => (type, nodeType) => {
      return map(
        getters[nodeFormFieldConfig](type, nodeType).fieldGroups,
        (fieldGroup) => {
          return {
            ...fieldGroup,
            fields: filter(
              fieldGroup.fields,
              (field) => isArray(field) || !!field.component
            ),
          };
        }
      );
    },
    [nodeFormFields]: (state, getters) => (type, nodeType) => {
      return flatten(
        map(
          getters[nodeFormFieldConfig](type, nodeType).fieldGroups,
          (fieldGroup) => flatten(fieldGroup.fields)
        )
      );
    },
    [sourceFormFieldConfig]: (state, getters) => (type) =>
      getters[nodeFormFieldConfig](type, 'source'),
    [sourceFormFieldToRender]: (state, getters) => (type) =>
      getters[nodeFormFieldGroupsToRender](type, 'source'),
    [destinationFormFieldConfig]: (state, getters) => (type) =>
      getters[nodeFormFieldConfig](type, 'destination'),
    [destinationFormFieldToRender]: (state, getters) => (type) =>
      getters[nodeFormFieldGroupsToRender](type, 'destination'),
    [showTestConnectionModal](state, getters, rootState, rootGetters) {
      return rootGetters[isModalOpen](
        SOURCE_DESTINATION_CONNECTION_VALIDATION_MODAL
      );
    },
    [showTunnelTestConnectionModal](state, getters, rootState, rootGetters) {
      return rootGetters[isModalOpen](TUNNEL_CONNECTION_VALIDATION_MODAL);
    },
    [getCurrentPipeline](state, getters, rootState, rootGetters) {
      return rootGetters[pipelinesServiceGetters.get](
        rootState.route.params.id
      );
    },
    [isFetchingPipeline](state, getters, rootState, rootGetters) {
      return rootGetters[isLoading](pipelinesServiceActions.get);
    },
    [getTableSearchQuery](state) {
      return state.tableSearchQuery;
    },
    [getPipelineForSource]: (state, getters, rootState, rootGetters) => (
      sourceId,
      query = {}
    ) => {
      return rootGetters[pipelinesServiceGetters.get]({
        source_id: sourceId,
        ...query,
      });
    },
    [getPipelineForDestination]: (state, getters, rootState, rootGetters) => (
      destinationId,
      query = {}
    ) => {
      return rootGetters[pipelinesServiceGetters.get]({
        destination_id: destinationId,
        ...query,
      });
    },
    [canNodeBeFullyEdited]: (state, getters) => (nodeId, nodeType) => {
      if (!nodeId) {
        return true;
      }
      const getter =
        nodeType === 'source'
          ? getPipelineForSource
          : getPipelineForDestination;

      const pipelines = getters[getter](nodeId, {
        id: {
          $ne: (getters[getCurrentPipeline] || {}).id,
        },
      });

      return !pipelines.length;
    },
    [canSourceBeFullyEdited]: (state, getters) => (sourceId) => {
      if (!sourceId) {
        return true;
      }
      const pipelines = getters[getPipelineForSource](sourceId, {
        id: {
          $ne: (getters[getCurrentPipeline] || {}).id,
        },
        status: {
          $ne: PIPELINE_STATUS_ENUM.ARCHIVED,
        },
      });

      return !pipelines.length;
    },
    [canDestinationBeFullyEdited]: (state, getters) => (destinationId) => {
      if (!destinationId) {
        return true;
      }
      const pipelines = getters[getPipelineForDestination](destinationId, {
        id: {
          $ne: (getters[getCurrentPipeline] || {}).id,
        },
        status: {
          $ne: PIPELINE_STATUS_ENUM.ARCHIVED,
        },
      });

      return !pipelines.length;
    },
  },
  mutations: {
    [setUseExistingTunnel](state, value) {
      state.useExistingTunnel = value;
    },
    [setTableSearchQuery](state, value) {
      state.tableSearchQuery = value;
    },
  },
  actions: {},
};
