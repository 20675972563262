<template>
  <div
    class="fly-box fly-dropdown"
    @click="onTriggerClick"
    @mouseenter="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <div v-click-away="onClickAway" class="fly-box fly-dropdown-trigger">
      <slot name="trigger"></slot>
    </div>
    <teleport to="body">
      <div
        v-if="showDropdown"
        :id="uniqueId"
        class="fly-box fly-dropdown-content"
      >
        <slot name="options"></slot>
      </div>
    </teleport>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { createPopper } from '@popperjs/core';
import { directive as VueClickAway } from 'vue3-click-away';
import { uniqueId } from 'lodash';

export default {
  name: 'FlyDropdown',
  props: {
    triggerSelector: VueTypes.string.def('.fly-dropdown-trigger'),
    trigger: VueTypes.oneOf(['hover', 'click']).def('click'),
    triggerMode: VueTypes.oneOf(['manual', 'auto']).def('auto'),
    show: VueTypes.bool.def(false),
    popperOptions: VueTypes.object.def({}),
    mountOn: VueTypes.string.def(''),
  },
  directives: {
    ClickAway: VueClickAway,
  },
  data() {
    return {
      uniqueId: uniqueId('dropdown-content-'),
      autoShow: false,
      popperInstance: null,
      mountPoint: null,
    };
  },
  computed: {
    isManualTriggerMode() {
      return this.triggerMode === 'manual';
    },
    showDropdown() {
      return this.isManualTriggerMode ? this.show : this.autoShow;
    },
  },
  watch: {
    show(newVal) {
      this.autoShow = newVal;
      this.createPopperInstance();
    },
    mountOn(newVal) {
      this.mountPoint = newVal;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mountPoint = this.mountOn;
      if (this.trigger !== 'hover') {
        this.createPopperInstance();
      }
    });
  },
  beforeUnmount() {
    this.destroyPopperInstance();
  },
  methods: {
    createPopperInstance() {
      this.destroyPopperInstance();
      this.$nextTick(() => {
        const triggerEl = this.$el.querySelector(this.triggerSelector);
        const dropdownContentEl = document.querySelector(`#${this.uniqueId}`);
        if (triggerEl && dropdownContentEl) {
          this.popperInstance = createPopper(
            triggerEl,
            dropdownContentEl,
            this.popperOptions,
          );
          this.popperInstance.update();
        }
      });
    },
    onMouseOver() {
      if (!this.isManualTriggerMode && this.trigger === 'hover') {
        this.autoShow = true;
        this.createPopperInstance();
        if (this.popperInstance) {
          this.popperInstance.update();
        }
      }
    },
    onMouseLeave() {
      if (!this.isManualTriggerMode && this.trigger === 'hover') {
        this.autoShow = false;
        this.destroyPopperInstance();
      }
    },
    onTriggerClick() {
      if (!this.isManualTriggerMode && this.trigger === 'click') {
        this.autoShow = !this.autoShow;
        this.createPopperInstance();
      }
    },
    onClickAway() {
      if (this.autoShow) {
        this.autoShow = false;
        this.createPopperInstance();
      }
    },
    destroyPopperInstance() {
      this.popperInstance && this.popperInstance.destroy();
      this.popperInstance = null;
    },
  },
};
</script>

<style lang="scss">
.fly-dropdown-content {
  z-index: 999;
}

.fly-dropdown {
  position: relative;
}

.fly-dropdown-trigger {
  width: 100%;
}
</style>
