import { BILLING_API_URL } from './billingEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(BILLING_API_URL, {
  extraMethods: {
    async getSetupIntentToken() {
      return this.request({
        url: `${BILLING_API_URL}setup-intent/`,
        method: 'post',
      });
    },
    async getManageSubscriptionLink() {
      return this.request({
        url: `${BILLING_API_URL}create-portal-session/`,
        method: 'post',
      });
    },
    async setDefaultPaymentMethod(paymentMethod) {
      console.log('payment_method', paymentMethod);
      return this.request({
        url: `${BILLING_API_URL}set-default-payment-method/`,
        method: 'post',
        data: {
          payment_method: paymentMethod,
        },
      });
    },
  },
});
