<template>
  <FlyModalRaw
    ref="modal"
    class="source-destination-connection-validation-modal"
    :show-footer="!loading"
    v-bind="$attrs"
    @close="onModalClose"
  >
    <template #header>
      <FlyIcon :name="titleIcon" />
      <h3 class="fly-text fly-text--h3">
        <slot name="title">{{ title }}</slot>
      </h3>
    </template>
    <template #default>
      <div v-if="loading" class="loading-text fly-text fly-text--ui-medium">
        This may take few seconds...
      </div>
      <div v-if="!loading" class="fly-box step-container">
        <slot name="statusText"></slot>
        <SourceDestinationTestConnectionStep
          v-for="(result, index) in testConnectionResult.results"
          :key="index"
          :step-result="result"
        />
      </div>
    </template>
    <template #footer>
      <FlyButtonRaw
        v-if="!loading"
        type="button"
        class="fly-button fly-button--tertiary"
        @click="onCloseClick"
      >
        <slot name="closeBtnText">{{ closeBtnText }}</slot>
      </FlyButtonRaw>
    </template>
  </FlyModalRaw>
</template>

<script>
import FlyModalRaw from 'shared/components/Modal/FlyModal.vue';
import VueTypes from 'vue-types';
import FlyButtonRaw from 'shared/components/Button/FlyButton.vue';
import { get } from 'lodash';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import SourceDestinationTestConnectionStep from 'web/pipelines/SourceDestinationTestConnectionStep';

export default {
  name: 'SourceDestinationConnectionValidationModal',
  components: {
    FlyModalRaw,
    FlyButtonRaw,
    FlyIcon,
    SourceDestinationTestConnectionStep,
  },
  props: {
    closeBtnText: VueTypes.string.def('Close'),
    loading: VueTypes.bool.isRequired,
    testConnectionResult: VueTypes.object.def({}),
  },
  emits: ['close'],
  computed: {
    isConnectionSuccessful() {
      return get(this.testConnectionResult, 'success') === true;
    },
    title() {
      if (this.loading) {
        return 'Testing connection...';
      }

      if (this.isConnectionSuccessful) {
        return 'Connection successful';
      }

      return 'Connection failed';
    },
    titleIcon() {
      if (this.loading) {
        return 'Spinner';
      }

      if (this.isConnectionSuccessful) {
        return 'TickCircle';
      }

      return 'WarningCircle';
    },
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    onCloseClick() {
      this.emitClose();
    },
    onModalClose() {
      this.emitClose();
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.fly-modal {
  .source-destination-connection-validation-modal {
    .fly-modal--header {
      padding: 18px 60px 18px 24px;
      flex-shrink: 0;
      position: relative;
      align-items: center;
      width: 100%;

      .fly-text--h3 {
        margin-left: 10px;
      }
    }

    .fly-modal--content {
      .loading-text {
        padding: 16px 24px;
        margin-bottom: 8px;
        width: 100%;
        color: $fly-color-grey-2;
      }

      .step-container {
        padding: 0 24px;
        flex-direction: column;
      }
    }

    .fly-modal--footer {
      padding: 16px 20px;
      border: 1px solid $fly-color-grey-7;
      justify-content: flex-end;

      .fly-button--tertiary {
        margin-right: 16px;
      }
    }
  }
}
</style>
