export default Object.freeze({
  MYSQL: 'mysql',
  POSTGRES: 'postgres',
  MARIA: 'maria',
  SQLSRV: 'sqlserver',
  SALESFORCE: 'salesforce',
  SALESFORCEV2: 'salesforce_v2',
  BIGCOMMERCE: 'bigcommerce',
  KLAVIYO: 'klaviyo',
  KLAVIYO_GENERIC: 'klaviyo_generic',
  ALGOLIA: 'algolia',
  OMNISEND: 'omnisend',
  ADOBE_ANALYTICS: 'adobe_analytics',
  FACEBOOK: 'facebook',
  SHIPPO: 'shippo',
  RECHARGE: 'recharge',
  ONFLEET: 'onfleet',
  GOOGLE_ANALYTICS: 'google_analytics',
  YOTPO: 'yotpo',
  STRIPE: 'stripe',
  BING_ADS: 'bing_ads',
  SHOPIFY: 'shopify',
  EBAY: 'ebay',
  BRAINTREE: 'braintree',
  GOOGLE_SHEETS: 'google_sheets',
  ZENDESK_CHAT: 'zendesk_chat',
  ADROLL: 'adroll',
  LINKEDIN_ADS: 'linkedin_ads',
  EXCHANGERATES_DATA_API: 'exchangerates_data_api',
  // disabling this in line with Exchange Rates Data API (generic) implementation
  // EXCHANGERATES: 'exchangerates',
  SENDGRID: 'sendgrid',
  SALESFORCE_MARKETING: 'salesforce_marketing',
  SURVEYMONKEY: 'surveymonkey',
  ZENDESKSUPPORT: 'zendesksupport',
  SQUARE: 'square',
  GOOGLE_ADS: 'google_ads',
  HUBSPOT: 'hubspot',
  SNAPCHAT: 'snapchat',
  GORGIAS: 'gorgias',
  DELIGHTED: 'delighted',
  PAYPAL: 'paypal',
  MAILCHIMP: 'mailchimp',
  TWITTER: 'twitter',
  YOUTUBE_ANALYTICS: 'youtube_analytics',
  YOUTUBE: 'youtube',
  PINTEREST: 'pinterest',
  TIKTOK_ADS: 'tiktok_ads',
  SHIPSTATION: 'shipstation',
  SALESFORCE_PARDOT: 'salesforce_pardot',
  MAGENTO: 'magento',
  PRESTASHOP: 'prestashop',
  SALESFORCE_EINSTEIN: 'salesforce_einstein',
  MANDRILL: 'mandrill',
  YOTPO_LOYALTY: 'yotpo_loyalty',
  INTUIT_QUICKBOOKS: 'intuit_quickbooks',
  FACEBOOK_PAGES: 'facebook_pages',
  JUDGEME: 'judgeme',
  NETSUITE: 'netsuite',
  RECURLY: 'recurly',
  NACELLE: 'nacelle',
  ALCHEMER: 'alchemer',
  RINGCENTRAL: 'ringcentral',
  CYBERSOURCE: 'cybersource',
  RETURNLY: 'returnly',
  GOOGLE_SEARCH_CONSOLE: 'google_search_console',
  ZENVENTORY: 'zenventory',
  CRITEO: 'criteo',
  MARKETO: 'marketo',
  AIRTABLE: 'airtable',
  GITHUB: 'github',
  GITLAB: 'gitlab',
  PICKZEN: 'pickzen',
  DRIP: 'drip',
  SLACK: 'slack',
  ENQUIRELABS: 'enquirelabs',
  VEND: 'vend',
  SMILE: 'smile',
  AMAZON_ADS: 'amazon_ads',
  ZENDESK_SELL: 'zendesk_sell',
  RAKUTEN_ADVERTISING: 'rakuten_advertising',
  AMAZON_MARKETPLACE: 'amazon_marketplace',
  GA4: 'ga4',
  GA4_EXPORT: 'ga4_export',
  GA360: 'ga360',
  GA360_BQ_STORAGE: 'ga360_bq_storage',
  S3: 's3',
});
