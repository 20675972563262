import createServiceStore from 'shared/utils/createServiceStore.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import { PRODUCT_SERVICE_KEY } from 'web/product/productEnum';
import productService from 'web/product/productService';

export default createServiceStore({
  serviceName: PRODUCT_SERVICE_KEY,
  service: productService,
  initialState: {},
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  getters: {},
  actions: {},
});
