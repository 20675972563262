import { DESTINATION_METRIC_SCHEMA_API_URL } from './destinationMetricSchemaEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(DESTINATION_METRIC_SCHEMA_API_URL, {
  extraMethods: {
    async get(id, config = {}) {
      const response = await this.axiosInstance.request({
        url: `${this.route}${id}/`,
        ...config,
      });
      return { id, schema: response.data };
    },
  },
});
