export const getLoggedInUserAction = 'getLoggedInUserAction';
export const updateUserPasswordAction = 'updateUserPasswordAction';
export const inviteUserAction = 'inviteUserAction';
export const resendUserInviteAction = 'resendUserInviteAction';
export const deleteUserInviteAction = 'deleteUserInviteAction';
export const openUserInviteModalAction = 'openUserInviteModalAction';
export const deactivateUserAction = 'deactivateUserAction';
export const activateUserAction = 'activateUserAction';
export const makeadminUserAction = 'makeadminUserAction';
export const updateClientNotificationSettingsAction =
  'updateClientNotificationSettingsAction';
