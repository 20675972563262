<template>
  <label :class="'fly-label ' + className">
    <span v-if="label" class="fly-label-text">
      {{ label }}
    </span>
    <div class="label-input-container">
      <slot></slot>
      <div v-if="lengthError" class="fly-input--length-error">
        {{ lengthError }}
      </div>
      <div v-if="error" class="fly-input--error">
        {{ error }}
      </div>
      <slot v-else name="helpText"></slot>
    </div>
  </label>
</template>

<script>
import VueTypes from 'vue-types';
export default {
  name: 'FlyInputLabel',
  props: {
    label: VueTypes.string.def(''),
    className: VueTypes.string.def(''),
    lengthError: VueTypes.string.def(''),
    error: VueTypes.string.def(''),
  },
};
</script>

<style lang="scss">
.label-input-container {
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
