<template>
  <label
    class="fly-checkbox"
    v-bind="$attrs"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <input
      type="checkbox"
      class="fly-input fly-input--type-checkbox"
      :disabled="isDisabled"
      :checked="checked"
      @focus="onFocus"
      @blur="onBlur"
      @change="$emit('change', $event)"
    />
    <slot name="checkBox">
      <div
        :class="{
          'fly-checkbox--box': true,
          checked: checked,
          disabled: isDisabled,
        }"
      >
        <FlyIcon
          :class="{
            'fly-checkbox--icon': true,
            checked: checked,
            disabled: isDisabled,
          }"
          name="Tick"
        />
      </div>
    </slot>
    <div class="fly-checkbox--label-box">
      <slot name="label">
        <div
          v-if="label"
          :class="
            'fly-checkbox--label fly-text fly-text--ui-small' +
            (isDisabled ? 'disabled' : '')
          "
        >
          {{ label }}
        </div>
      </slot>
      <slot>
        <div
          v-if="helpText"
          class="fly-text fly-text--ui-small fly-checkbox--help-text"
        >
          {{ helpText }}
        </div>
      </slot>
    </div>
  </label>
</template>

<script>
import VueTypes from 'vue-types';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
export default {
  name: 'FlyCheckbox',
  components: { FlyIcon },
  emits: ['change'],
  props: {
    checked: VueTypes.bool.isRequired,
    disabled: VueTypes.bool.def(false),
    label: VueTypes.string.def(''),
    helpText: VueTypes.string.def(''),
  },
  data() {
    return {
      hovered: false,
      focused: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading || this.disabled;
    },
  },
  methods: {
    onMouseEnter() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
  inheritAttrs: false,
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.fly-checkbox {
  cursor: pointer;
  position: relative;
  align-items: center;
  display: flex;

  &.fly-checkbox--large {
    .fly-checkbox--box {
      width: 20px;
      height: 20px;
    }
  }

  .fly-checkbox--box {
    border-radius: 4px;
    background-color: #ffffff;
    border: 2px solid $fly-color-grey-5;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    min-width: 16px;
    display: flex;

    &.checked {
      background-color: $fly-color-blue-1;
      border-color: $fly-color-blue-1;
    }

    &.disabled {
      background-color: $fly-color-grey-7;
    }

    &:hover,
    &:focus {
      border-color: $fly-color-blue-1;
    }
  }

  .fly-input--type-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .fly-checkbox--label-box {
    flex-direction: column;
    margin-left: 16px;
  }
  .fly-checkbox--label {
    cursor: pointer;
    font-weight: 500;

    &.disabled {
      cursor: text;
    }
  }
  .fly-checkbox--help-text {
    color: $fly-color-grey-4;
  }

  .fly-checkbox--icon {
    display: none;
    width: 10px;
    height: 10px;

    &.checked {
      display: block;
      color: #ffffff;
    }

    &.disabled {
      display: none;
    }
  }
}
</style>
