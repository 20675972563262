import { throttle } from 'lodash';

export default (
  selectedIndexKey = 'selectedIndex',
  listKey = 'options',
  throttleDuration = 100
) => ({
  methods: {
    upHandler: throttle(function () {
      this[selectedIndexKey] =
        (this[selectedIndexKey] + this[listKey].length - 1) %
        this[listKey].length;
    }, throttleDuration),
    downHandler: throttle(function () {
      this[selectedIndexKey] =
        (this[selectedIndexKey] + 1) % this[listKey].length;
    }, throttleDuration),
  },
});
