import Pusher from 'pusher-js';
import CONFIG from 'shared/config';
import pusherAuthService from 'shared/clients/pusher/pusherAuthService.js';

export const createPusher = options => {
  return new Pusher(CONFIG.PUSHER_APP_KEY, {
    cluster: CONFIG.PUSHER_CLUSTER,
    authorizer: channel => ({
      authorize: async (socketId, callback) => {
        try {
          const response = await pusherAuthService.create({
            channel_name: channel.name,
            socket_id: socketId
          });
          callback(false, response);
        } catch (e) {
          callback(true);
        }
      }
    }),
    forceTLS: true,
    ...options
  });
};
