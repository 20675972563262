import FEATURE_TOGGLE_ENUM from './FEATURE_TOGGLE_ENUM';

const ownProdClients = [
  1, // flydata
  603, // xplenty demo account
];
const resyncToolClients = [
  1452, // Soaren
];

const etlScriptClients = [
  150, // Vividseats
  2153, // Anaswara
];

const s3FileFormatClients = [
  150, // Vividseats
];

export default Object.freeze({
  [FEATURE_TOGGLE_ENUM.SINGER_TAPS_ENABLED]: {
    $or: [
      {
        'user.client.id': {
          $in: ownProdClients,
        },
      },
      {
        env: 'development',
      },
    ],
  },
  [FEATURE_TOGGLE_ENUM.NATIVE_TAPS_ENABLED]: {
    $or: [
      {
        'user.client.id': {
          $in: ownProdClients,
        },
      },
      {
        env: 'development',
      },
    ],
  },
  [FEATURE_TOGGLE_ENUM.GENERIC_TAPS_ENABLED]: {
    $or: [
      {
        'user.client.id': {
          $in: ownProdClients,
        },
      },
      {
        env: 'development',
      },
    ],
  },
  [FEATURE_TOGGLE_ENUM.RESYNC_TOOL]: {
    $or: [
      {
        'user.client.id': {
          $in: [...ownProdClients, ...resyncToolClients],
        },
      },
      {
        env: 'development',
      },
    ],
  },
  [FEATURE_TOGGLE_ENUM.BIGQUERY_SINK_ENABLED]: {
    $or: [
      {
        'user.client.id': {
          $in: ownProdClients,
        },
      },
      {
        env: 'development',
      },
    ],
  },
  [FEATURE_TOGGLE_ENUM.DATA_OBSERVABILITY_ENABLED]: {
    $or: [
      {
        'user.client.id': {
          $in: ownProdClients,
        },
      },
      {
        env: 'development',
      },
      {
        env: 'production',
      },
    ],
  },
  [FEATURE_TOGGLE_ENUM.SANDBOX_TESTING]: {
    $or: [
      {
        'user.client.id': {
          $in: ownProdClients,
        },
      },
      {
        env: 'development',
      },
    ],
  },
  [FEATURE_TOGGLE_ENUM.ETL_SCRIPT]: {
    $or: [
      {
        'user.client.id': {
          $in: [...ownProdClients, ...etlScriptClients],
        },
      },
      {
        env: 'development',
      },
    ],
  },
  [FEATURE_TOGGLE_ENUM.S3_FILE_FORMAT_PICKER]: {
    $or: [
      {
        'user.client.id': {
          $in: [...ownProdClients, ...s3FileFormatClients],
        },
      },
      {
        env: 'development',
      },
    ],
  },
});
