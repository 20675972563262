import { get, some, values } from 'lodash';
import CONFIG from 'shared/config';

const registerAuthGuardForRouter = (
  router,
  store,
  {
    isAuthenticatedGetter,
    initAction,
    allErrorsGetter,
    updateIntercomAction,
    shouldRefreshApp,
    isUserTrialExpiredGetter,
    trialExpiredView,
    hasActiveSubscriptionGetter,
    isInActiveTrialGetter,
  },
) => {
  router.beforeEach(async (to, from, next) => {
    const isAuthenticated = store.getters[isAuthenticatedGetter];
    const requiresAuth = get(to, 'meta.requiresAuth', true);
    const preventWhenLoggedIn = get(to, 'meta.preventWhenLoggedIn', false);

    if (!isAuthenticated) {
      try {
        await store.dispatch(initAction);
      } catch (e) {
        if (
          requiresAuth &&
          get(e, 'response.data.detail', '') === 'Email is not verified'
        ) {
          return next({ name: 'verifyEmail' });
        }
        if (!requiresAuth) {
          return next();
        }
        window.location = `${CONFIG.INTEGRATEIO_URL}/auth/login`;
        return;
      }
    }

    if (updateIntercomAction) {
      await store.dispatch(updateIntercomAction);
    }

    if (preventWhenLoggedIn) {
      return next('/');
    }

    // store.commit(removeAllEnqueuedRealtimeUpdate);

    // pushed event for app refresh
    if (store.getters[shouldRefreshApp]) {
      window.location.reload();
    } else {
      // if 401, then session has expired. So Logout
      const errors = values(store.getters[allErrorsGetter]);
      const shouldLogout = some(
        errors,
        (err) => get(err, 'response.status') === 401,
      );

      if (shouldLogout && requiresAuth) {
        window.location.assign(window.location.origin);
      }

      if (to.name !== trialExpiredView.name) {
        // check if path starts with alerts or observability
        const isAlertsOrObservability =
          to.path.startsWith('/alerts') || to.path.startsWith('/observability');
        if (isAlertsOrObservability) {
          return next();
        }

        // check user has active subscription
        if (store.getters[hasActiveSubscriptionGetter]) {
          return next();
        }

        // check user trial expiry
        if (store.getters[isUserTrialExpiredGetter]) {
          return next(trialExpiredView);
        }

        // check not in active trial and has Active subscription
        if (
          !store.getters[isInActiveTrialGetter] &&
          !store.getters[hasActiveSubscriptionGetter]
        ) {
          return next(trialExpiredView);
        }
      }

      next();
    }
  });
};

export default registerAuthGuardForRouter;
