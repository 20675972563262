<template>
  <div class="fly-box facebook-pages-table">
    <div class="fly-box header-container">
      <FlyLink
        class="fly-link fly-text fly-text--ui-small"
        href="#"
        @click.prevent="refreshAvailablePages"
      >
        <FlyIcon class="fly-icon" name="Refresh" />
        Refresh table list
      </FlyLink>
    </div>
    <div class="fly-box table-container">
      <data-table
        :rows="pagesData"
        :pagination="pagination"
        :query="query"
        filter
        @load-data="loadData"
      >
        <template #thead>
          <table-head><div class="table-header">Page Name</div></table-head>
          <table-head>
            <div class="table-header">Page ID</div>
          </table-head>
        </template>

        <template #tbody="{ row }">
          <table-body>
            <span>{{ row.name }}</span>
          </table-body>
          <table-body>
            <span>{{ row.id }}</span>
          </table-body>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import FormStoreMixin from 'shared/stores/form/FormStoreMixin';
import { SETUP_PIPELINE_FORM } from 'web/form/formKeys';
import { get } from 'lodash';
import { mapActions } from 'vuex';
import FlyLink from 'shared/components/Link/FlyLink';
import FlyIcon from 'shared/components/Icon/FlyIcon';
import { DataTable, TableBody, TableHead } from '@jobinsjp/vue3-datatable';
import { getFacebookPagesAction } from 'web/sources/sourcesActionTypes';

function paginate(data, page, perPage) {
  const start = (page - 1) * perPage;
  const end = page * perPage;
  return data.slice(start, end);
}

export default {
  name: 'FacebookPagesTable',
  components: {
    DataTable,
    TableBody,
    TableHead,
    FlyLink,
    FlyIcon,
  },
  mixins: [FormStoreMixin(SETUP_PIPELINE_FORM)],
  setup() {
    const query = ref({
      search: '',
    });

    return { query };
  },

  data() {
    return {
      pagination: {
        page: 1,
        total: 10,
      },
    };
  },
  computed: {
    pages() {
      return get(this.formData, 'config.selectedPages', []) || [];
    },
    accessToken() {
      return get(this.formData, 'config.static.accessToken');
    },
  },
  watch: {
    availableCollections: {
      handler() {
        this.setPagesInFormData();
        this.loadData({ page: 1, total: 10, per_page: 10 });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.setPagesInFormData();
    this.loadData({ page: 1, total: 10, per_page: 10 });
  },
  methods: {
    ...mapActions({
      getFacebookPagesAction,
    }),
    loadData(query) {
      this.query.search = query.search;
      this.pagesData = query.search
        ? this.pages.filter((item) =>
            item.name.toLowerCase().includes(query.search.toLowerCase()),
          )
        : this.pages;
      const total = this.pagesData.length;
      this.pagesData = paginate(this.pagesData, query.page, query.per_page);
      this.pagination = {
        ...this.pagination,
        page: query.page,
        total,
      };
    },
    setPagesInFormData() {
      let facebookPages = get(
        this.formData,
        'config.extra_args.facebookPages',
        [],
      );
      this.setFormFieldAtPath('config.selectedPages', facebookPages);
    },
    async refreshAvailablePages() {
      const newPages = await this.getFacebookPagesAction({
        accessToken: this.accessToken,
      });
      this.setFormFieldAtPath(
        'config.extra_args.facebookPages',
        get(newPages, ['facebookPages'], []),
      );
      this.setPagesInFormData();
      this.loadData({ page: 1, total: 10, per_page: 10 });
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.facebook-pages-table {
  flex-direction: column;
  margin-left: 8px;
  width: 100%;

  .header-container {
    align-items: center;
    justify-content: space-between;
    position: sticky;
    padding-top: 4px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    top: 0;
    z-index: 1;

    .fly-link {
      color: $fly-color-blue-1;
      margin-right: 8px;

      .fly-icon {
        margin-right: 8px;
        path {
          fill: $fly-color-blue-1;
        }
      }
    }
  }

  .table-container {
    .data-table {
      width: 100%;

      .table-header {
        color: $fly-color-grey-1;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

select.dt__pagination_size {
  width: 80px;
}
</style>
