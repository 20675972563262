import { castArray, each, includes } from 'lodash';
import resolvePusherEventHandler from 'shared/clients/pusher/resolvePusherEventHandler.js';

export default ({ pusher, subscriptionMutationType, eventHandlers }) => {
  return (store) => {
    store.subscribe((mutation) => {
      if (mutation.type === subscriptionMutationType) {
        if (pusher.connection.state === 'disconnected') {
          pusher.connect();
        }

        setInterval(() => {
          if (!includes(['connected', 'connecting'], pusher.connection.state)) {
            // eslint-disable-next-line no-console
            console.log(`Pusher ${pusher.connection.state}: Reconnecting...`);
            pusher.connect();
          }
        }, 5000);
        each(castArray(mutation.payload), (channel) =>
          pusher.subscribe(channel)
        );
      }
    });
    each(eventHandlers, (handler, eventName) => {
      pusher.bind(eventName, resolvePusherEventHandler(handler, store));
    });
  };
};
