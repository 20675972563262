<template>
  <div :class="classes">
    <transition-group
      name="fly-toast-list"
      tag="div"
      class="fly-box fly-toast-list fly-box--transition"
    >
      <FlyToast
        v-for="toast in toasts"
        :key="toast.key"
        :title="toast.title"
        :description="toast.description"
        :style="{ width: toastWidth }"
        :type="toast.type"
        @close="() => onToastClose(toast)"
      />
    </transition-group>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import FlyToast from 'shared/components/Toast/FlyToast';

export default {
  name: 'FlyToastPortal',
  components: {
    FlyToast,
  },
  props: {
    toasts: VueTypes.array,
    toastWidth: VueTypes.string.def('400px'),
  },
  emits: ['close'],
  computed: {
    classes() {
      return ['fly-box', 'fly-toast-portal'];
    },
  },
  methods: {
    onToastClose(toast) {
      this.$emit('close', toast);
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.fly-toast-portal {
  position: fixed;
  z-index: 999999;
  top: 20px;
  right: 20px;
}

.fly-toast-list {
  flex-direction: column;
}

.fly-toast-list-enter-active {
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.fly-toast-list-leave-active {
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.fly-toast-list-enter,
.fly-toast-list-leave-to {
  opacity: 0.3;
  transform: translate(4px * 100);
}
</style>
