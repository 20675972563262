import URI from 'urijs';
import {
  each,
  isArray,
  values,
  orderBy,
  map,
  isPlainObject,
  castArray,
  extend,
  head,
  get,
  compact,
  isEmpty,
  isNil,
  keys,
  omit,
} from 'lodash';
import sift from 'sift';
import { pascalCase } from 'change-case';

function resolveReferences({
  obj,
  rootGetters,
  references,
  referencesGetterKeys,
}) {
  if (!isEmpty(references) && !isEmpty(obj) && !isNil(obj)) {
    let _obj = { ...obj };
    each(references, (serviceName, referenceKey) => {
      _obj[referenceKey] = rootGetters[referencesGetterKeys[referenceKey].get](
        _obj[`${referenceKey}_id`],
      );
    });
    return _obj;
  }

  return obj;
}

function denormalizeReferences({ obj, references }) {
  if (!isEmpty(references) && !isEmpty(obj) && !isNil(obj)) {
    let _obj = omit(obj, keys(references));
    let referencesData = {};

    each(references, (serviceName, referenceKey) => {
      const referenceKeyId = get(obj, `${referenceKey}.id`);
      if (referenceKeyId) {
        _obj[`${referenceKey}_id`] = referenceKeyId;

        referencesData[referenceKey] = obj[referenceKey];
      }
    });
    return { obj: _obj, referencesData };
  }

  return { obj, referencesData: {} };
}

export default function ({
  serviceName,
  service,
  setLoadingMutation = '',
  setErrorMutation = '',
  setPaginationMutation = '',
  idPath = 'id',
  initialState = {},
  getters = {},
  actions = {},
  mutations = {},
  references = {},
}) {
  const actionKeys = getActionTypesForService(serviceName);
  const mutationKeys = getMutationTypesForService(serviceName);
  const getterKeys = getGetterTypesForService(serviceName);
  let referencesActionKeys = {};
  let referencesMutationKeys = {};
  let referencesGetterKeys = {};
  const hasReferences = !isEmpty(references);

  if (hasReferences) {
    each(references, (serviceName, referenceKey) => {
      referencesActionKeys[referenceKey] =
        getActionTypesForService(serviceName);
      referencesMutationKeys[referenceKey] =
        getMutationTypesForService(serviceName);
      referencesGetterKeys[referenceKey] =
        getGetterTypesForService(serviceName);
    });
  }

  return {
    state: { ...initialState },
    getters: Object.assign(
      {},
      {
        [getterKeys.get]:
          (state, getters, rootState, rootGetters) =>
          (query, getOne = false) => {
            if (isArray(query)) {
              return compact(
                map(query, (id) =>
                  resolveReferences({
                    obj: get(state, id),
                    references,
                    referencesGetterKeys,
                    rootGetters,
                  }),
                ),
              );
            }
            if (isPlainObject(query)) {
              const objects = values(state).filter(sift(query));
              return getOne
                ? resolveReferences({
                    obj: head(objects),
                    referencesGetterKeys,
                    rootGetters,
                    references,
                  })
                : map(objects, (obj) =>
                    resolveReferences({
                      obj,
                      rootGetters,
                      references,
                      referencesGetterKeys,
                    }),
                  );
            }
            return resolveReferences({
              obj: get(state, query),
              references,
              referencesGetterKeys,
              rootGetters,
            });
          },
        [getterKeys.getAll]:
          (state, getters, rootState, rootGetters) =>
          (sortBy = [idPath], sortOrder = ['asc']) =>
            map(orderBy(values(state), sortBy, sortOrder), (obj) =>
              resolveReferences({
                obj,
                rootGetters,
                references,
                referencesGetterKeys,
              }),
            ),
      },
      getters,
    ),
    mutations: Object.assign(
      {},
      {
        [mutationKeys.set](state, payload) {
          each(
            castArray(payload),
            (entity) => (state[get(entity, idPath)] = entity),
          );
        },
        [mutationKeys.update](state, { id, payload }) {
          state[id] = extend({}, state[id], payload);
        },
        [mutationKeys.remove](state, id) {
          delete state[id];
        },
        [mutationKeys.extend](state, payload) {
          each(castArray(payload), (entity) => {
            const id = get(entity, idPath);
            state[id] = extend({}, state[id], entity);
          });
        },
        [mutationKeys.trimSet](state, { trimQuery, payload }) {
          const entitiesToTrim = values(state).filter(sift(trimQuery));
          each(entitiesToTrim, (entity) => delete state[get(entity, idPath)]);
          each(
            castArray(payload),
            (entity) => (state[get(entity, idPath)] = entity),
          );
        },
      },
      mutations,
    ),
    actions: Object.assign(
      {},
      {
        async [actionKeys.list](
          { commit },
          {
            query,
            config,
            silent = false,
            extend = false,
            trimQuery = null,
          } = {},
        ) {
          !silent &&
            commit(setLoadingMutation, { key: actionKeys.list, value: true });
          commit(setErrorMutation, { key: actionKeys.list, value: null });

          try {
            const response = await service.list(query, config);
            let results = response.results || response;
            const denormalizedDataArray = map(results, (result) =>
              denormalizeReferences({
                obj: result,
                references,
              }),
            );
            results = denormalizedDataArray.map((item) => item.obj);
            const referencesData = denormalizedDataArray
              .map((item) => item.referencesData)
              .reduce((acc, item) => {
                Object.keys(item).forEach((key) => {
                  if (!acc[key]) {
                    acc[key] = [];
                  }
                  acc[key] = [...acc[key], item[key]];
                });
                return acc;
              }, {});

            Object.keys(referencesData).forEach((key) => {
              commit(referencesMutationKeys[key].extend, referencesData[key]);
            });

            if (extend) {
              commit(mutationKeys.extend, results);
            } else if (trimQuery) {
              commit(mutationKeys.trimSet, {
                trimQuery,
                payload: results,
              });
            } else {
              commit(mutationKeys.set, results);
            }
            return response;
          } catch (err) {
            commit(setErrorMutation, { key: actionKeys.list, value: err });
            throw err;
          } finally {
            !silent &&
              commit(setLoadingMutation, {
                key: actionKeys.list,
                value: false,
              });
          }
        },
        async [actionKeys.listWithPaginationUpdate](
          { commit, dispatch },
          { query, config, ...otherParams } = {},
        ) {
          const response = await dispatch(actionKeys.list, {
            query,
            config,
            ...otherParams,
          });
          const total = response.count;
          const nextPage = URI(response.next || '').query(true).page;
          const nextPageNum = nextPage ? parseInt(nextPage, 10) : null;
          const previousPage = response.previous
            ? URI(response.previous).query(true).page ||
              parseInt(query.page, 10) - 1
            : '';
          const previousPageNum = previousPage
            ? parseInt(previousPage, 10)
            : null;
          const currentPage = map(response.results, (entity) =>
            get(entity, idPath),
          );
          const currentPageNum = parseInt(query.page, 10) || 1;

          commit(setPaginationMutation, {
            total,
            nextPageNum,
            previousPageNum,
            currentPageNum,
            query,
            currentPage,
            key: serviceName,
          });
          return response;
        },
        async [actionKeys.get](
          { commit },
          { id, query = {}, config, silent = false } = {},
        ) {
          !silent &&
            commit(setLoadingMutation, { key: actionKeys.get, value: true });
          commit(setErrorMutation, { key: actionKeys.get, value: null });

          try {
            let response = await service.get(id, {
              params: query,
              ...config,
            });

            const denormalizedData = denormalizeReferences({
              obj: response,
              references,
            });
            response = denormalizedData.obj;

            Object.keys(denormalizedData.referencesData).forEach((key) => {
              commit(
                referencesMutationKeys[key].update,
                denormalizedData.referencesData[key],
              );
            });

            commit(mutationKeys.update, {
              id: get(response, idPath),
              payload: response,
            });
            return response;
          } catch (err) {
            commit(setErrorMutation, { key: actionKeys.get, value: err });
            throw err;
          } finally {
            !silent &&
              commit(setLoadingMutation, { key: actionKeys.get, value: false });
          }
        },
        async [actionKeys.create](
          { commit },
          { data, config, silent = false } = {},
        ) {
          !silent &&
            commit(setLoadingMutation, { key: actionKeys.create, value: true });
          commit(setErrorMutation, { key: actionKeys.create, value: null });

          try {
            let response = await service.create(data, config);

            const denormalizedData = denormalizeReferences({
              obj: response,
              references,
            });

            response = denormalizedData.obj;

            Object.keys(denormalizedData.referencesData).forEach((key) => {
              commit(
                referencesMutationKeys[key].update,
                denormalizedData.referencesData[key],
              );
            });

            if (isArray(response)) {
              commit(mutationKeys.set, response);
            } else {
              commit(mutationKeys.update, {
                id: get(response, idPath),
                payload: response,
              });
            }
            return response;
          } catch (err) {
            commit(setErrorMutation, { key: actionKeys.create, value: err });
            throw err;
          } finally {
            !silent &&
              commit(setLoadingMutation, {
                key: actionKeys.create,
                value: false,
              });
          }
        },
        async [actionKeys.update](
          { commit },
          { idOrQuery, data, config, silent = false } = {},
        ) {
          !silent &&
            commit(setLoadingMutation, { key: actionKeys.update, value: true });
          commit(setErrorMutation, { key: actionKeys.update, value: null });

          try {
            let response = await service.update(idOrQuery, data, config);

            const denormalizedData = denormalizeReferences({
              obj: response,
              references,
            });

            response = denormalizedData.obj;

            Object.keys(denormalizedData.referencesData).forEach((key) => {
              commit(
                referencesMutationKeys[key].update,
                denormalizedData.referencesData[key],
              );
            });

            if (isArray(response)) {
              commit(mutationKeys.extend, response);
            } else {
              commit(mutationKeys.update, {
                id: get(response, idPath),
                payload: response,
              });
            }
            return response;
          } catch (err) {
            commit(setErrorMutation, { key: actionKeys.update, value: err });
            throw err;
          } finally {
            !silent &&
              commit(setLoadingMutation, {
                key: actionKeys.update,
                value: false,
              });
          }
        },
        async [actionKeys.remove](
          { commit },
          { idOrQuery, config, silent = false } = {},
        ) {
          !silent &&
            commit(setLoadingMutation, { key: actionKeys.remove, value: true });
          commit(setErrorMutation, { key: actionKeys.remove, value: null });

          try {
            const response = await service.remove(idOrQuery, config);
            if (isArray(response)) {
              response.forEach((entity) =>
                commit(mutationKeys.remove, get(entity, idPath)),
              );
            } else {
              commit(mutationKeys.remove, get(response, idPath));
            }
            return response;
          } catch (err) {
            commit(setErrorMutation, { key: actionKeys.remove, value: err });
            throw err;
          } finally {
            !silent &&
              commit(setLoadingMutation, {
                key: actionKeys.remove,
                value: false,
              });
          }
        },
      },
      actions,
    ),
  };
}

export const getActionTypesForService = (serviceName) => {
  serviceName = pascalCase(serviceName);
  const list = `list${serviceName}Action`;
  const listWithPaginationUpdate = `list${serviceName}WithPaginationUpdateAction`;
  const get = `get${serviceName}Action`;
  const update = `update${serviceName}Action`;
  const create = `create${serviceName}Action`;
  const remove = `remove${serviceName}Action`;

  return {
    list,
    listWithPaginationUpdate,
    get,
    update,
    create,
    remove,
  };
};

export const getMutationTypesForService = (serviceName) => {
  serviceName = pascalCase(serviceName);
  const set = `set${serviceName}`;
  const update = `update${serviceName}`;
  const remove = `remove${serviceName}`;
  const trimSet = `trimSet${serviceName}`;
  const extend = `extend${serviceName}`;
  return {
    set,
    update,
    remove,
    trimSet,
    extend,
  };
};

export const getGetterTypesForService = (serviceName) => {
  serviceName = pascalCase(serviceName);
  const get = `get${serviceName}`;
  const getAll = `getAll${serviceName}`;

  return {
    get,
    getAll,
  };
};
