<template>
  <form class="fly-box pipeline-ssh-connect-create-secure-tunnel-form">
    <div class="fly-box cards-container">
      <FlyRadioCard
        label="Use SSH"
        name="ssh"
        help-text="SSH is a secure protocol that has a wider range of support."
        :value="1"
        :selected-value="formFieldValue('type')"
        @change="setFormField('type', $event)"
      />
      <FlyRadioCard
        label="Use Wireguard"
        name="wireguard"
        help-text="Wireguard is a modern VPN protocol that is fast and secure."
        :value="0"
        :selected-value="formFieldValue('type')"
        @change="setFormField('type', $event)"
      />
    </div>
    <CloudRegionSingleSelect
      v-if="!region"
      :value="_region"
      :provider="provider"
      :filter="{
        regionCode: { $in: ['us-east-1', 'us-west-2', 'ap-northeast-1'] },
      }"
      :error="formFieldError('region')"
      @change="onRegionSelect"
    />
    <FlyInput
      class="fly-input fly-input--column"
      :auto-focus="true"
      label="Tunnel name"
      inputtype="node_name"
      :value="formFieldValue('name')"
      :error="formFieldError('name')"
      @input="setFormField('name', $event)"
    />
    <div class="fly-box divider" />
    <FlyButtonRaw
      v-if="!isSSH"
      icon-placement="left"
      class="fly-button fly-button--secondary fly-button--small fly-button--advanced-options-toggle"
      :icon="advancedOptionsToggle ? 'ChevronUp' : 'ChevronDown'"
      type="button"
      @click.prevent="onAdvancedOptionsSecureTunnelClick"
      >Advanced Options (Optional)</FlyButtonRaw
    >
    <div class="fly-box divider" />
    <FlyInput
      v-if="advancedOptionsToggle"
      class="fly-input fly-input--column"
      label="SSH User"
      :required="true"
      :placeholder="defaultSSHUser"
      :value="formFieldValue('ssh_user')"
      :error="formFieldError('ssh_user')"
      @input="setFormField('ssh_user', $event)"
    />
    <div class="fly-box divider" />
    <FlyInput
      v-if="advancedOptionsToggle"
      class="fly-input fly-input--column"
      label="SSH Port"
      :required="true"
      :value="formFieldValue('ssh_port')"
      :error="formFieldError('ssh_port')"
      @input="setFormField('ssh_port', $event)"
    />
    <div class="fly-box divider" />
    <template v-if="isSSH">
      <FlyInput
        class="fly-input fly-input--column"
        label="SSH Host"
        :required="true"
        :value="formFieldValue('ssh_host')"
        :error="formFieldError('ssh_host')"
        @input="setFormField('ssh_host', $event)"
      />
      <div class="fly-box divider" />
      <FlyInput
        class="fly-input fly-input--column"
        label="SSH Port"
        :required="true"
        :value="formFieldValue('ssh_port')"
        :error="formFieldError('ssh_port')"
        @input="setFormField('ssh_port', $event)"
      />
      <div class="fly-box divider" />
      <FlyInput
        class="fly-input fly-input--column"
        label="SSH User"
        :required="true"
        :placeholder="defaultSSHUser"
        :value="formFieldValue('ssh_user')"
        :error="formFieldError('ssh_user')"
        @input="setFormField('ssh_user', $event)"
      />
      <div class="fly-box divider" />
    </template>
    <FlyButtonRaw
      v-if="isSSH"
      class="fly-button fly-button--download"
      type="button"
      @click="onSubmitSSH"
      >Create SSH Tunnel</FlyButtonRaw
    >
    <div v-else class="fly-box buttons-container">
      <FlyButtonRaw
        class="fly-button fly-button--download full-width"
        type="button"
        icon-placement="left"
        :icon="btnIcon"
        @click.prevent="onSubmitCloudFormation"
        >{{ btnTextCloudFormation }}</FlyButtonRaw
      >
      <FlyButtonRaw
        class="fly-button fly-button--download"
        :icon="btnIcon"
        icon-placement="left"
        type="button"
        @click.prevent="onSubmitInstallScript"
        >{{ btnTextInstallScript }}</FlyButtonRaw
      >
    </div>
  </form>
</template>

<script>
import VueTypes from 'vue-types';
import FormStoreMixin from 'shared/stores/form/FormStoreMixin';
import { CREATE_SECURE_TUNNEL_FORM } from 'web/form/formKeys';
import FlyInput from 'shared/components/Form/FlyInput.vue';
import FlyButtonRaw from 'shared/components/Button/FlyButton.vue';
import { mapActions } from 'vuex';
import tunnelServiceActions from 'web/tunnel/tunnelServiceActions';
import { downloadTunnelTemplateAction } from 'web/tunnel/tunnelActionTypes';
import CloudRegionSingleSelect from 'web/common/CloudRegionSingleSelect.vue';
import TUNNEL_CONFIG_TYPE_ENUM from 'web/tunnel/TUNNEL_CONFIG_TYPE_ENUM';
import FlyRadioCard from 'shared/components/Form/FlyRadioCard.vue';
import { pick } from 'lodash';

export default {
  name: 'PipelineSSHConnectCreateSecureTunnelForm',
  components: {
    FlyRadioCard,
    CloudRegionSingleSelect,
    FlyInput,
    FlyButtonRaw,
  },
  mixins: [FormStoreMixin(CREATE_SECURE_TUNNEL_FORM)],
  props: {
    region: VueTypes.number.isRequired,
    provider: VueTypes.number.isRequired,
  },
  emits: ['create'],
  data() {
    return {
      selectedRegion: null,
      advancedOptionsToggle: false,
    };
  },
  computed: {
    hasCreatedTunnel() {
      return this.formFieldValue('name') && !this.formHasChanges;
    },
    btnTextCloudFormation() {
      return 'Download CloudFormation template';
    },
    btnTextInstallScript() {
      return 'Download Bash Installation script';
    },
    btnIcon() {
      return this.hasCreatedTunnel ? 'Tick' : 'Download';
    },
    _region() {
      return this.region || this.selectedRegion;
    },
    isSSH() {
      return this.formFieldValue('type') === 1;
    },
    defaultSSHUser() {
      return this.isSSH ? '' : 'ubuntu';
    },
  },
  methods: {
    ...mapActions({
      createTunnel: tunnelServiceActions.create,
      downloadTunnelTemplate: downloadTunnelTemplateAction,
    }),
    async downloadTunnelFile(config_type) {
      if (this.hasCreatedTunnel && !this.isSSH && config_type) {
        await this.downloadTunnelTemplate({
          tunnelId: this.formFieldValue('tunnel_id'),
          config_type: config_type,
        });
        return;
      }
      this.setFormField('tunnel_id', null);
      this.clearFormErrors();
      if (!this.provider || !this._region) {
        this.setFormFieldError('region', 'Select a provider or region');
        return;
      }
      if (!this.formFieldValue('name')) {
        this.setFormFieldError('name', 'Enter a tunnel name');
        return;
      }

      const formData = this.isSSH
        ? pick(this.formData, [
            'name',
            'type',
            'ssh_host',
            'ssh_port',
            'ssh_user',
          ])
        : pick(this.formData, ['name', 'type', 'ssh_user', 'ssh_port']);

      if (!formData.ssh_user) {
        if (this.isSSH) {
          this.setFormFieldError('ssh_user', 'Enter a SSH user');
          return;
        } else {
          formData.ssh_user = 'ubuntu';
        }
      }
      if (!formData.ssh_port) {
        if (this.isSSH || this.advancedOptionsToggle) {
          this.setFormFieldError('ssh_port', 'Enter a SSH port');
          return;
        } else {
          formData.ssh_port = 22;
        }
      }

      if (this.isSSH) {
        if (!formData.ssh_host) {
          this.setFormFieldError('ssh_host', 'Enter a SSH host');
          return;
        }
      }
      const tunnel = await this.createTunnel({
        data: {
          ...formData,
          provider: this.provider,
          region: this._region,
        },
      });
      if (!this.isSSH && config_type) {
        await this.downloadTunnelTemplate({
          tunnelId: tunnel.id,
          config_type: config_type,
        });
      }
      this.$emit('create', tunnel);
      this.setInitialFormField('name', this.formFieldValue('name'));
      this.setInitialFormField('tunnel_id', tunnel.id);
      this.setInitialFormField('type', this.formFieldValue('type'));
      if (this.isSSH) {
        this.setInitialFormField('ssh_host', this.formFieldValue('ssh_host'));
        this.setInitialFormField('ssh_port', this.formFieldValue('ssh_port'));
        this.setInitialFormField('ssh_user', this.formFieldValue('ssh_user'));
      }
    },
    async onSubmitSSH() {
      return await this.downloadTunnelFile();
    },
    async onSubmitCloudFormation() {
      return await this.downloadTunnelFile(
        TUNNEL_CONFIG_TYPE_ENUM.CLOUD_FORMATION,
      );
    },
    async onSubmitInstallScript() {
      return await this.downloadTunnelFile(
        TUNNEL_CONFIG_TYPE_ENUM.INSTALL_SCRIPT,
      );
    },
    onAdvancedOptionsSecureTunnelClick() {
      this.advancedOptionsToggle = !this.advancedOptionsToggle;
    },
    onRegionSelect(region) {
      this.selectedRegion = region;
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.pipeline-ssh-connect-create-secure-tunnel-form {
  flex-direction: column;
  margin-left: 36px;

  .cards-container {
    margin-bottom: 16px;

    .fly-radio-card {
      padding: 24px 20px;
      width: 50%;

      &:first-child {
        margin-right: 32px;
      }
    }
  }

  .fly-single-select {
    margin-bottom: 16px;

    .fly-label {
      width: 100%;
    }
  }

  .divider {
    margin: 8px 0;
  }

  .fly-button--advanced-options-toggle,
  .fly-button--download {
    margin-top: 12px;
  }

  .full-width {
    width: 100%;

    &:first-child {
      margin-right: 28px;
    }
  }

  .buttons-container {
    justify-content: space-between;
  }
}
</style>
