<template>
  <component
    :is="tagName"
    :class="{
      'is-left-icon-button': isLeftIcon,
      'is-right-icon-button': isRightIcon,
      'is-center-icon-button': isCenterIcon,
    }"
    v-bind="{ ...$attrs, ...(isDisabled ? { disabled: isDisabled } : {}) }"
  >
    <FlyIcon v-if="isLeftIcon" :name="icon" class="fly-icon left" />
    <FlyIcon v-if="isCenterIcon" :name="icon" class="fly-icon" />
    <slot></slot>
    <FlyIcon v-if="isRightIcon" :name="icon" class="fly-icon right" />
  </component>
</template>

<script>
import VueTypes from 'vue-types';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';

export default {
  name: 'FlyButtonRaw',
  components: { FlyIcon },
  props: {
    disabled: VueTypes.bool.def(false),
    icon: VueTypes.string.def(''),
    iconPlacement: VueTypes.oneOf(['right', 'left', 'center', '']).def(''),
  },
  computed: {
    tagName() {
      if (!this.isDisabled) {
        if (this.$attrs.href) {
          return 'a';
        }

        if (this.$attrs.to) {
          return 'router-link';
        }
      }

      return 'button';
    },
    isDisabled() {
      return this.isLoading || this.disabled;
    },
    isLeftIcon() {
      return this.icon && this.iconPlacement === 'left';
    },
    isRightIcon() {
      return this.icon && this.iconPlacement === 'right';
    },
    isCenterIcon() {
      return this.icon && this.iconPlacement === 'center';
    },
  },
};
</script>

<style lang="scss">
.fly-button {
  outline: none !important;
  transition: all 150ms ease-in;
  text-decoration: none;
  position: relative;

  &[disabled],
  &.fly-button--loading {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .fly-icon {
    &.right {
      margin-left: 8px;
    }

    &.left {
      margin-right: 8px;
    }
  }
}
</style>
