import { DESTINATIONS_API_URL } from './destinationsEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(DESTINATIONS_API_URL, {
  extraMethods: {
    async testConnection({ data, ...config }) {
      return this.request({
        url: `${this.route}test-connection/`,
        method: 'post',
        data,
        ...config,
      });
    },
    async testExistingDestinationConnection(id, config = {}) {
      return this.request({
        url: `${this.route}${id}/test/`,
        method: 'get',
        ...config,
      });
    },
    async changeStatus(destinationId, status) {
      return this.request({
        url: `${DESTINATIONS_API_URL}${destinationId}/${status}/`,
        method: 'post',
      });
    },
  },
});
