export const getUseExistingTunnel = 'getUseExistingTunnel';
export const nodeFormFieldConfig = 'nodeFormFieldConfig';
export const nodeFormFieldGroupsToRender = 'nodeFormFieldGroupsToRender';
export const nodeFormFields = 'nodeFormFields';
export const sourceFormFieldConfig = 'sourceFormFieldConfig';
export const sourceFormFieldToRender = 'sourceFormFieldToRender';
export const destinationFormFieldConfig = 'destinationFormFieldConfig';
export const destinationFormFieldToRender = 'destinationFormFieldToRender';
export const showTestConnectionModal = 'showTestConnectionModal';
export const showTunnelTestConnectionModal = 'showTunnelTestConnectionModal';
export const getCurrentPipeline = 'getCurrentPipeline';
export const isFetchingPipeline = 'isFetchingPipeline';
export const getTableSearchQuery = 'getTableSearchQuery';
export const getPipelineForSource = 'getPipelineForSource';
export const getPipelineForDestination = 'getPipelineForDestination';
export const canNodeBeFullyEdited = 'canNodeBeFullyEdited';
export const canSourceBeFullyEdited = 'canSourceBeFullyEdited';
export const canDestinationBeFullyEdited = 'canDestinationBeFullyEdited';
export const destinationUsesSSL = 'destinationUsesSSL';
export const destinationUsesTunnel = 'destinationUsesTunnel';
export const getActivePipelineSetupStepIndex =
  'getActivePipelineSetupStepIndex';
export const getCompletedPipelineSetupSteps = 'getCompletedPipelineSetupSteps';
export const getPipelineSetupSteps = 'getPipelineSetupSteps';
export const previousPipelineSetupStepLink = 'previousPipelineSetupStepLink';
