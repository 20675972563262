import { APP_STORE_KEY } from '../app/appEnum.js';
import appStore from '../app/appStore.js';
import { USER_STORE_KEY } from '../user/userEnum.js';
import userStore from '../user/userStore.js';
import { LOADING_STORE_KEY } from '../loading/loadingEnum.js';
import loadingStore from '../loading/loadingStore.js';
import { ERROR_STORE_KEY } from '../error/errorEnum.js';
import errorStore from '../error/errorStore.js';
import { FORM_STORE_KEY } from 'shared/stores/form/formEnum';
import formStore from 'shared/stores/form/formStore';
import formInitialData from 'web/form/formInitialData';
import { createLogger } from 'vuex';
import { AUTH_STORE_KEY } from 'web/auth/authEnum';
import authStore from 'web/auth/authStore';
import modalStore from 'shared/store/modal/modalStore';
import { MODAL_STORE_KEY } from 'shared/store/modal/modalEnum';
import { PRODUCT_STORE_KEY } from '../product/productEnum.js';
import productStore from '../product/productStore';
import { PIPELINES_STORE_KEY } from 'web/pipelines/pipelinesEnum';
import pipelinesStore from 'web/pipelines/pipelinesStore';
import { SOURCES_STORE_KEY } from 'web/sources/sourcesEnum';
import sourcesStore from 'web/sources/sourcesStore';
import { DESTINATIONS_STORE_KEY } from 'web/destinations/destinationsEnum';
import destinationsStore from 'web/destinations/destinationsStore';
import { compact } from 'lodash';
import { DOCS_STORE_KEY } from 'web/docs/docsEnum';
import docsStore from 'web/docs/docsStore';
import { SOURCE_SCHEMA_STORE_KEY } from 'web/sourceSchema/sourceSchemaEnum';
import sourceSchemaStore from 'web/sourceSchema/sourceSchemaStore';
import pusherVuexPlugin from 'shared/clients/pusher/pusherVuexPlugin';
import { createPusher } from 'shared/clients/pusher';
import { REFRESH_APP } from 'shared/clients/pusher/EVENT_ENUM';
import sourcePusherEventHandlers from 'web/sources/sourcePusherEventHandlers';
import pipelinePusherEventHandlers from 'web/pipelines/pipelinePusherEventHandlers';
import destinationPusherEventHandlers from 'web/destinations/destinationPusherEventHandlers';
import {
  setPusherSubscriptionChannels,
  setRefreshApp,
} from 'web/app/appMutationTypes';
import CONFIG from 'shared/config';
import { TOAST_STORE_KEY } from 'shared/stores/toast/toastEnum';
import toastStore from 'shared/stores/toast/toastStore';
import { TUNNEL_STORE_KEY } from 'web/tunnel/tunnelEnum';
import tunnelStore from 'web/tunnel/tunnelStore';
import tunnelPusherEventHandlers from 'web/tunnel/tunnelPusherEventHandlers';
import { PUBLICIP_STORE_KEY } from 'web/publicIP/publicIPEnum';
import publicIPStore from 'web/publicIP/publicIPStore';
import { CONNECTOR_STORE_KEY } from 'web/connector/connectorEnum';
import connectorStore from 'web/connector/connectorStore';
import { BILLING_STORE_KEY } from 'web/billing/billingEnum';
import billingStore from 'web/billing/billingStore';
import { FEATURE_TOGGLE_STORE_KEY } from 'web/featureToggle/featureToggleEnum';
import featureToggleStore from 'web/featureToggle/featureToggleStore';
import { ALERTS_STORE_KEY } from 'web/alerts/alertsEnum.js';
import alertsStore from 'web/alerts/alertsStore.js';
import { METRICS_STORE_KEY } from 'web/metrics/metricsEnum.js';
import metricsStore from 'web/metrics/metricsStore.js';
import alertTriggerStore from 'web/alertTrigger/alertTriggerStore.js';
import { ALERTTRIGGER_STORE_KEY } from 'web/alertTrigger/alertTriggerEnum.js';
import { SOURCE_METRIC_SCHEMA_STORE_KEY } from 'web/sourceMetricSchema/sourceMetricSchemaEnum.js';
import sourceMetricSchemaStore from 'web/sourceMetricSchema/sourceMetricSchemaStore.js';
import { DESTINATION_METRIC_SCHEMA_STORE_KEY } from 'web/destinationMetricSchema/destinationMetricSchemaEnum.js';
import destinationMetricSchemaStore from 'web/destinationMetricSchema/destinationMetricSchemaStore.js';
import { HOOK_STORE_KEY } from 'web/hook/hookEnum.js';
import hookStore from 'web/hook/hookStore.js';

const storeOptions = {
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    [APP_STORE_KEY]: appStore,
    [USER_STORE_KEY]: userStore,
    [PRODUCT_STORE_KEY]: productStore,
    [LOADING_STORE_KEY]: loadingStore,
    [ERROR_STORE_KEY]: errorStore,
    [FORM_STORE_KEY]: formStore(formInitialData),
    [FEATURE_TOGGLE_STORE_KEY]: featureToggleStore,
    [AUTH_STORE_KEY]: authStore,
    [MODAL_STORE_KEY]: modalStore,
    [HOOK_STORE_KEY]: hookStore,
    [PIPELINES_STORE_KEY]: pipelinesStore,
    [SOURCES_STORE_KEY]: sourcesStore,
    [DESTINATIONS_STORE_KEY]: destinationsStore,
    [DOCS_STORE_KEY]: docsStore,
    [SOURCE_SCHEMA_STORE_KEY]: sourceSchemaStore,
    [SOURCE_METRIC_SCHEMA_STORE_KEY]: sourceMetricSchemaStore,
    [DESTINATION_METRIC_SCHEMA_STORE_KEY]: destinationMetricSchemaStore,
    [TOAST_STORE_KEY]: toastStore,
    [TUNNEL_STORE_KEY]: tunnelStore,
    [PUBLICIP_STORE_KEY]: publicIPStore,
    [CONNECTOR_STORE_KEY]: connectorStore,
    [BILLING_STORE_KEY]: billingStore,
    [ALERTS_STORE_KEY]: alertsStore,
    [METRICS_STORE_KEY]: metricsStore,
    [ALERTTRIGGER_STORE_KEY]: alertTriggerStore,
  },
  plugins: compact([
    process.env.NODE_ENV === 'development' && createLogger(),
    CONFIG.PUSHER_APP_KEY !== 'fake' &&
      pusherVuexPlugin({
        pusher: createPusher(),
        // eslint-disable-next-line no-undef
        subscriptionMutationType: setPusherSubscriptionChannels,
        eventHandlers: {
          [REFRESH_APP]: {
            type: 'mutation',
            // eslint-disable-next-line no-undef
            name: setRefreshApp,
          },
          ...sourcePusherEventHandlers,
          ...pipelinePusherEventHandlers,
          ...destinationPusherEventHandlers,
          ...tunnelPusherEventHandlers,
        },
      }),
  ]),
};

export default storeOptions;
