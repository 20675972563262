export const AUTH_STORE_KEY = 'auth';
export const LOGIN_URL = '/api/v1/login/';
export const LOGOUT_URL = '/api/v1/user/logout/';
export const FORGOT_PASSWORD_URL = '/api/v1/recover-password/';
export const RESET_PASSWORD_URL = '/api/v1/reset-password/';
export const RESET_PASSWORD_TOKEN_VERIFY_URL = '/api/v1/verify-password-token/';
export const SIGNUP_URL = '/api/v1/signup/';
export const USER_EMAIL_VALIDATE_URL = '/api/v1/validate-user-email/';
export const SET_SIGNUP_PASSWORD = '/api/v1/set-signup-password/';
export const USER_INVITE_TOKEN_VERIFY_URL = '/api/v1/verify-invite/';
export const USER_INVITE_TOKEN_PROCESS_URL = '/api/v1/process-user-invite/';
