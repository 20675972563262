<template>
  <div class="pipeline-ssh-connect-direct fly-box">
    <div class="fly-text fly-text--ui-small">
      Please whitelist the following IPs to allow connections to your source.
    </div>
    <div class="fly-box">
      <FlyTooltip v-for="(ip, idx) in ips" :key="idx" tooltip="Click to copy">
        <div
          v-clipboard:copy="ip"
          v-clipboard:success="onCopy"
          class="fly-text--label-blue fly-text"
        >
          {{ ip }}
        </div>
      </FlyTooltip>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { mapActions, mapGetters } from 'vuex';
import { find, get, uniq } from 'lodash';
import { showToastAction } from 'shared/stores/toast/toastActionTypes';
import TOAST_TYPE_ENUM from 'shared/components/Toast/TOAST_TYPE_ENUM';
import FlyTooltip from 'shared/components/Tooltip/FlyTooltip.vue';
import publicIPServiceGetters from 'web/publicIP/publicIPServiceGetters';
import CLOUD_PROVIDER_REGION_ENUM from 'web/common/CLOUD_PROVIDER_REGION_ENUM';

export default {
  name: 'PipelineSSHConnectDirect',
  components: {
    FlyTooltip,
  },
  props: {
    provider: VueTypes.number.isRequired,
    region: VueTypes.number.isRequired,
  },
  computed: {
    ...mapGetters({
      getPublicIP: publicIPServiceGetters.get,
    }),
    regionCode() {
      return get(
        find(CLOUD_PROVIDER_REGION_ENUM[this.provider], { value: this.region }),
        'regionCode',
      );
    },
    publicIP() {
      return this.getPublicIP(this.regionCode);
    },
    ips() {
      return uniq(['54.211.153.152', ...get(this.publicIP, 'ip', [])]);
    },
  },
  methods: {
    ...mapActions({
      showToastAction,
    }),
    onCopy(params) {
      this.showToastAction({
        key: `IP_COPY_${params.text}`,
        title: 'IP address copied!',
        description: `${params.text} has been copied to your clipboard`,
        type: TOAST_TYPE_ENUM.SUCCESS,
      });
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.pipeline-ssh-connect-direct {
  flex-direction: column;
  padding: 16px 0;

  .fly-text--ui-small {
    margin-bottom: 12px;
  }

  .fly-text--label-blue {
    margin-right: 8px;
    cursor: pointer;
  }
}
</style>
