<template>
  <div :class="'fly-box fly-toast ' + type">
    <div class="fly-box fly-toast--highlight-box" />
    <FlyIcon class="fly-toast--icon" :name="iconName" />
    <div class="fly-box fly-toast--text-box">
      <div class="fly-text fly-text--h4 fly-toast--title-text">{{ title }}</div>
      <div class="fly-text fly-text--ui-small fly-toast--description-text">
        {{ description }}
      </div>
    </div>
    <FlyButtonRaw
      v-if="dismissable"
      class="fly-button fly-button--icon fly-toast--close-icon"
      icon-placement="center"
      icon="Close"
      @click="onCloseClick"
    >
    </FlyButtonRaw>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import FlyButtonRaw from 'shared/components/Button/FlyButton.vue';
import TOAST_TYPE_ENUM from 'shared/components/Toast/TOAST_TYPE_ENUM';
export default {
  name: 'FlyToast',
  components: { FlyButtonRaw, FlyIcon },
  props: {
    title: VueTypes.string.def(''),
    description: VueTypes.string.def(''),
    type: VueTypes.string.def(''),
    dismissable: VueTypes.bool.def(true),
  },
  emits: ['close'],
  computed: {
    iconName() {
      console.log(this.type);
      switch (this.type) {
        case TOAST_TYPE_ENUM.SUCCESS:
          return 'TickCircle';
        case TOAST_TYPE_ENUM.ERROR:
        case TOAST_TYPE_ENUM.WARNING:
          return 'WarningCircle';
        case TOAST_TYPE_ENUM.PROGRESS:
          return 'Spinner';
        default:
          return '';
      }
    },
  },
  methods: {
    onCloseClick() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.fly-toast {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid $fly-color-grey-6;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 16px 32px -4px transparentize($fly-color-grey-3, 0.95);
  position: relative;
  align-items: center;

  &.success {
    .fly-toast--highlight-box {
      background-color: $fly-color-green-1;
    }
  }

  &.error {
    .fly-toast--highlight-box {
      background-color: $fly-color-red-3;

      .fly-toast--icon {
        path {
          fill: $fly-color-red-3;
        }
      }
    }
  }

  &.warning {
    .fly-toast--highlight-box {
      background-color: $fly-color-yellow-3;

      .fly-toast--icon {
        path {
          fill: $fly-color-yellow-3;
        }
      }
    }
  }

  &.progress {
    .fly-toast--highlight-box {
      background-color: $fly-color-blue-1;
    }
  }

  .fly-toast--highlight-box {
    width: 4px;
    border-radius: 2px;
    height: 100%;
    flex-shrink: 0;
  }

  .fly-toast--title-text {
    color: $fly-color-grey-1;
    margin-bottom: 4px;
    margin-top: 8px;
  }

  .fly-toast--description-text {
    color: $fly-color-grey-4;
    margin-bottom: 8px;
  }

  .fly-toast--icon {
    margin-left: 12px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .fly-toast--text-box {
    flex-direction: column;
    width: 100%;
  }

  .fly-toast--close-icon {
    flex-shrink: 0;
    margin-right: 4px;

    .fly-icon {
      path {
        fill: $fly-color-grey-4;
      }
    }
  }
}
</style>
