<template>
  <div class="fly-code-block-container">
    <div class="fly-text fly-text--code fly-code-block">
      <pre
        class="fly-code-block-pre hljs"
        :lang="lang"
        v-html="highlightedText"
      ></pre>
      <div class="fly-box copy-button-container">
        <FlyButtonRaw
          v-clipboard:copy="text"
          v-clipboard:success="onCopy"
          class="fly-button fly-button--copy"
          type="button"
          >{{ copyText }}</FlyButtonRaw
        >
      </div>
    </div>
  </div>
</template>

<script>
import highlight from 'highlight.js/lib/core';
import sqlHighlight from 'highlight.js/lib/languages/sql';
import iniHighlight from 'highlight.js/lib/languages/ini';
import jsonHighlight from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/dark.css';
import VueTypes from 'vue-types';
import FlyButtonRaw from 'shared/components/Button/FlyButton.vue';

highlight.registerLanguage('sql', sqlHighlight);
highlight.registerLanguage('ini', iniHighlight);
highlight.registerLanguage('json', jsonHighlight);
highlight.registerAliases(['mysql'], { languageName: 'sql' });

export default {
  name: 'FlyCodeBlock',
  components: { FlyButtonRaw },
  props: {
    lang: VueTypes.string.isRequired,
    text: VueTypes.string.isRequired,
  },
  data() {
    return {
      copyText: 'Copy',
    };
  },
  computed: {
    highlightedText() {
      return highlight.highlight(this.lang, this.text, true).value;
    },
  },
  methods: {
    onCopy() {
      this.copyText = 'Copied!';
      setTimeout(() => {
        this.copyText = 'Copy';
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-code-block-container {
  width: 100%;
  position: relative;
  margin-top: 16px;
}

.fly-code-block {
  padding: 12px;
  background-color: $fly-color-grey-2;
  border-radius: 8px;
  max-width: 100%;
  overflow: auto;

  .copy-button-container {
    flex-shrink: 0;
    align-items: flex-start;
    position: absolute;
    right: 12px;
    top: 12px;

    .fly-button--copy {
      background-color: $fly-color-grey-3;
      border-radius: 16px;
      color: #ffffff;
      border-style: none;
      font-size: 13px;
      line-height: 18px;
      padding: 6px 16px;

      &:hover {
        background-color: $fly-color-grey-4;
      }
    }
  }

  pre.hljs.fly-code-block-pre {
    width: 100%;
    background: none;
    margin: 0;
    padding: 0;
    line-height: 29px;
    font-family: 'Source Code Pro', monospace;

    & * {
      font-family: 'Source Code Pro', monospace;
      line-height: 29px;
    }

    & .hljs-comment {
      color: #99a0b7;
    }
  }
}
</style>
