<template>
  <div class="pipeline-create-use-ssl-switch">
    <FlySwitch
      label="Use SSL"
      name="ssl"
      :checked="usesSSL"
      @change="onUseSSLChange"
    />
    <FlySwitch
      v-if="usesSSL"
      label="Use custom SSL certificate"
      name="custom-ssl"
      :checked="customSSLChecked"
      @change="setFormFieldAtPath('useCustomSSL', !customSSLChecked)"
    />
    <FlyFileInput
      v-if="usesSSL && customSSLChecked"
      button-text="Select file"
      label="Upload SSL certificate file (.crt, .cert, .pem)"
      accept=".crt,.cert,.pem,text/plain"
      @change="onFileSelect"
    />
  </div>
</template>

<script>
import { get, isEmpty, isNil } from 'lodash';
import FlySwitch from 'shared/components/Form/FlySwitch.vue';
import FlyFileInput from 'shared/components/Form/FlyFileInput.vue';
import FormStoreMixin from 'shared/stores/form/FormStoreMixin';

export default {
  name: 'PipelineCreateUseSSLSwitch',
  components: {
    FlySwitch,
    FlyFileInput,
  },
  mixins: [FormStoreMixin()],
  data() {
    return {
      fileReader: null,
    };
  },
  computed: {
    ssl() {
      return this.formFieldValue('config.connection_config.ssl');
    },
    usesSSL() {
      return !!this.ssl && !isEmpty(this.ssl);
    },
    customSSLChecked() {
      return !!this.formFieldValue('useCustomSSL');
    },
  },
  watch: {
    customSSLChecked(val) {
      if (!val && this.usesSSL) {
        this.turnOnSSL();
      }
    },
  },
  mounted() {
    if (isNil(this.ssl)) {
      this.turnOnSSL();
    }
  },
  methods: {
    turnOnSSL() {
      this.setFormFieldAtPath('config.connection_config.ssl', {
        reject_unauthorized: false,
      });
    },
    onUseSSLChange(e) {
      if (e.target.checked) {
        this.turnOnSSL();
      } else {
        this.setFormFieldAtPath('config.connection_config.ssl', false);
        this.setFormFieldAtPath('useCustomSSL', false);
      }
    },
    onFileSelect(e) {
      const file = get(e, 'target.files[0]', '');

      if (this.fileReader) {
        this.fileReader.abort();
        this.fileReader.removeEventListener('loadend', this.onLoadEnd);
        this.fileReader = null;
      }

      if (file) {
        this.fileReader = new FileReader();
        this.fileReader.addEventListener('loadend', this.onLoadEnd);
        this.fileReader.readAsText(file);
      }
    },
    onLoadEnd() {
      this.setFormFieldAtPath('config.connection_config.ssl', {
        ca: this.fileReader.result,
      });
    },
  },
};
</script>

<style lang="scss">
.pipeline-create-use-ssl-switch {
  .fly-switch {
    padding: 16px 0;

    .fly-switch--label-text {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      font-family:
        Centra No2,
        sans-serif;
    }
  }

  .fly-file-input {
    margin-top: 0;
    .fly-label {
      font-weight: 500;
      margin-bottom: 16px;
    }
  }
}
</style>
