
import createServiceStore from 'shared/utils/createServiceStore.js';
import { DOCS_SERVICE_KEY } from './docsEnum.js';
import docsService from './docsService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';

export default createServiceStore({
  serviceName: DOCS_SERVICE_KEY,
  service: docsService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError
});
