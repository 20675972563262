import { setFeatureToggle } from './featureToggleMutationTypes.js';
import {
  setAllFeatureTogglesForLoggedInUserAction,
  setAllFeatureTogglesForUserAction,
} from './featureToggleActionTypes.js';
import FEATURE_TOGGLE_ENUM from 'shared/featureToggle/FEATURE_TOGGLE_ENUM.js';
import { each } from 'lodash';
import isFeatureToggleEnabled from 'shared/featureToggle/isFeatureToggleEnabled.js';
import CONFIG from 'shared/config';
import { getLoggedInUser } from '../user/userDataGetterTypes.js';
import { getFeatureToggle } from './featureToggleGetterTypes.js';

export default {
  state: {},
  getters: {
    [getFeatureToggle]: (state) => (key) => state[key],
  },
  mutations: {
    [setFeatureToggle](state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {
    [setAllFeatureTogglesForUserAction]({ commit }, user) {
      each(FEATURE_TOGGLE_ENUM, (featureToggle) => {
        const isEnabled = isFeatureToggleEnabled(featureToggle, {
          user,
          env: CONFIG.NODE_ENV,
        });
        commit(setFeatureToggle, { key: featureToggle, value: isEnabled });
      });
    },
    [setAllFeatureTogglesForLoggedInUserAction]({ dispatch, rootGetters }) {
      dispatch(setAllFeatureTogglesForUserAction, rootGetters[getLoggedInUser]);
    },
  },
};
