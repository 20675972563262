<template>
  <FlyToastPortal v-bind="$attrs" :toasts="toasts" @close="closeToast" />
</template>

<script>
import { filter, isEmpty } from 'lodash';
import FlyToastPortal from 'shared/components/Toast/FlyToastPortal.vue';
import { mapGetters, mapMutations } from 'vuex';
import { getAllToasts } from 'shared/stores/toast/toastGetterTypes.js';
import { hideToast } from 'shared/stores/toast/toastMutationTypes.js';

export default {
  name: 'ToastManager',
  components: { FlyToastPortal },
  inheritAttrs: false,
  computed: {
    ...mapGetters({
      getAllToasts,
    }),
    toasts() {
      return filter(this.getAllToasts, (toast) => !isEmpty(toast));
    },
  },
  methods: {
    ...mapMutations({
      hideToast,
    }),
    closeToast(toast) {
      this.hideToast(toast.key);
    },
  },
};
</script>

<style scoped></style>
