<template>
  <div class="fly-box switch-config">
    <FlySwitch
      :label="label"
      :name="name"
      :checked="!!value"
      :help-text="helpText"
      @change="onFieldChange"
    />
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import FlySwitch from 'shared/components/Form/FlySwitch.vue';

export default {
  name: 'FlyConfigSwitch',
  components: {
    FlySwitch,
  },
  props: {
    value: VueTypes.bool.def(true),
    name: VueTypes.string.def(''),
    label: VueTypes.string.def(''),
    helpText: VueTypes.string.def(''),
  },
  emits: ['switchChange'],
  methods: {
    onFieldChange(e) {
      this.$emit('switchChange', e.target.checked);
    },
  },
};
</script>

<style lang="scss">
.switch-config {
  border-style: none !important;
  flex-direction: column;
}
</style>
