import { HOOK_API_URL } from './hookEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(HOOK_API_URL, {
  extraMethods: {
    async getAttachment({ hookId, hookSourceType, hookSourceId, data }) {
      return this.request({
        url: `${HOOK_API_URL}${hookId}/${hookSourceType}/${hookSourceId}/`,
        method: 'get',
        data,
      });
    },
    async editAttachment({ hookId, hookSourceType, hookSourceId, data }) {
      return this.request({
        url: `${HOOK_API_URL}${hookId}/${hookSourceType}/${hookSourceId}/`,
        method: 'patch',
        data,
      });
    },
    async attach({ hookId, hookSourceType, hookSourceId, data }) {
      return this.request({
        url: `${HOOK_API_URL}${hookId}/${hookSourceType}/${hookSourceId}/`,
        method: 'post',
        data,
      });
    },
    async detach({ hookId, hookSourceType, hookSourceId }) {
      return this.request({
        url: `${HOOK_API_URL}${hookId}/${hookSourceType}/${hookSourceId}/`,
        method: 'delete',
      });
    },
    async archive(hookId) {
      return this.request({
        url: `${HOOK_API_URL}${hookId}/archive/`,
        method: 'post',
      });
    },
  },
});
