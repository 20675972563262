import { ALERTTRIGGER_API_URL } from './alertTriggerEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(ALERTTRIGGER_API_URL, {
  extraMethods: {
    acknowledgeAllAlertTriggers({ alert_id }) {
      return this.request({
        url: `${this.route}acknowledge/?alert_id=${alert_id}`,
        method: 'post',
      });
    },
    resolveAllAlertTriggers({ alert_id }) {
      return this.request({
        url: `${this.route}resolve/?alert_id=${alert_id}`,
        method: 'post',
      });
    },
  },
});
