<template>
  <div class="pipeline-create-square-sandbox-environment">
    <FlySwitch
      label="Use sandbox environment"
      name="use_sandbox_environment"
      padding-vertical="4"
      :checked="usesSandbox"
      @change="onUseSandboxChange"
    />
  </div>
</template>

<script>
import FlySwitch from 'shared/components/Form/FlySwitch.vue';
import FormStoreMixin from 'shared/stores/form/FormStoreMixin';

export default {
  name: 'PipelineCreateSquareSandboxEnvironmentSwitch',
  components: {
    FlySwitch,
  },
  mixins: [FormStoreMixin()],
  computed: {
    isSandbox() {
      return this.formFieldValue('config.tap_config.sandbox') === 'true';
    },
    usesSandbox() {
      return this.isSandbox;
    },
  },
  methods: {
    onUseSandboxChange(e) {
      this.setFormFieldAtPath(
        'config.tap_config.sandbox',
        e.target.checked.toString(),
      );
    },
  },
};
</script>

<style lang="scss">
.pipeline-create-square-sandbox-environment {
  .fly-switch {
    padding: 16px 0;

    .fly-switch--label-text {
      font-size: 20px;
      line-height: 28px;
      font-family: 'Source Sans Pro', sans-serif;
    }
  }
}
</style>
