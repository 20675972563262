import createServiceStore from 'shared/utils/createServiceStore.js';
import { ALERTS_SERVICE_KEY } from './alertsEnum.js';
import alertsService from './alertsService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';

export default createServiceStore({
  serviceName: ALERTS_SERVICE_KEY,
  service: alertsService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
});
