<template>
  <div class="fly-box secure-tunnel-status">
    <template v-if="tunnel.ssh_public_key">
      <div class="fly-text fly-text--ui-medium">SSH Public Key</div>
      <FlyCodeBlock lang="sql" :text="tunnel.ssh_public_key" />
    </template>
    <div class="fly-box secure-tunnel-status-container">
      <FlyIcon :name="setup_icon" class="fly-icon" />
      <div class="fly-text fly-text--ui-small">{{ setup_text }}</div>
      <FlyIcon :name="is_active_icon" class="fly-icon active-icon" />
      <div class="fly-text fly-text--ui-small">{{ is_active_text }}</div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { get } from 'lodash';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import FlyCodeBlock from 'shared/components/Text/FlyCodeBlock.vue';

export default {
  name: 'SecureTunnelStatus',
  components: {
    FlyCodeBlock,
    FlyIcon,
  },
  props: {
    tunnel: VueTypes.object.isRequired,
  },
  computed: {
    isSetupComplete() {
      return get(this.tunnel, 'setup_completed');
    },
    setup_icon() {
      return this.isSetupComplete ? 'TickCircle' : 'Spinner';
    },
    setup_text() {
      return this.isSetupComplete ? 'Setup Complete' : 'Waiting for setup...';
    },
    isActive() {
      return get(this.tunnel, 'is_active');
    },
    is_active_icon() {
      return this.isActive ? 'TickCircle' : 'WarningCircle';
    },
    is_active_text() {
      return this.isActive ? 'Active' : 'Inactive';
    },
  },
};
</script>

<style lang="scss">
.secure-tunnel-status {
  flex-direction: column;
  align-items: start;
  padding-right: 40px;

  .fly-text--ui-medium {
    font-weight: 500;
  }

  .fly-code-block {
    margin-bottom: 16px;
  }

  .secure-tunnel-status-container {
    align-items: center;

    .fly-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      &.active-icon {
        margin-left: 16px;
      }
    }
  }
}
</style>
