export default {
  dataTypeOptions: [
    {
      value: 'string',
      label: 'String',
    },
    {
      value: 'boolean',
      label: 'Boolean',
    },
    {
      value: 'number',
      label: 'Number',
    },
    {
      value: 'integer',
      label: 'Integer',
    },
    {
      value: 'array',
      label: 'Array',
    },
    {
      value: 'object',
      label: 'Object',
    },
    {
      value: 'bigint',
      label: 'Bigint',
    },
    {
      value: 'date',
      label: 'Date',
    },
    {
      value: 'datetime',
      label: 'Datetime',
    },
  ],
};
