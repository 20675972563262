import { ALERTS_API_URL } from './alertsEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(ALERTS_API_URL, {
  extraMethods: {
    async fetchAlertUsage({ id, ...params }) {
      return this.request({
        url: `${ALERTS_API_URL}${id}/usage/`,
        method: 'get',
        params,
      });
    },
  },
});
