import { filter, orderBy, get, every } from 'lodash';
import moment from 'moment-timezone';
import createServiceStore from 'shared/utils/createServiceStore.js';

import { setLoading } from '../loading/loadingMutationTypes.js';
import {
  deactivateUserAction,
  activateUserAction,
  deleteUserInviteAction,
  getLoggedInUserAction,
  inviteUserAction,
  resendUserInviteAction,
  updateUserPasswordAction,
  makeadminUserAction,
  updateClientNotificationSettingsAction,
} from './userActionTypes.js';
import { USER_SERVICE_KEY } from './userEnum.js';
import { setError } from '../error/errorMutationTypes.js';
import {
  getBillingSubscriptions,
  getLoggedInUser,
  getTrialExpiryDate,
  hasActiveSubscription,
  isBillingMailerOptedIn,
  isMonthlyMailerOptedIn,
  isInActiveTrial,
  isClientBypassBilling,
  isClientStripeManaged,
  getClient,
  canCreateAlert,
  getFreeObservabilityQuota,
  getFreeObservabilityUsage,
  getPaidObservabilityQuota,
  getPaidObservabilityUsage,
  showObservabilityUpsell,
  showObservabilityUpgrade,
} from './userDataGetterTypes.js';
import {
  isUserTrialExpired,
  isUserAnAdmin,
} from 'web/user/userDataGetterTypes';

import userService from './userService.js';
import userServiceGetters from './userServiceGetters.js';
import userServiceActions from './userServiceActions.js';
import userServiceMutations from './userServiceMutations.js';
import USER_ROLE_ENUM from 'web/user/USER_ROLE_ENUM';

export default createServiceStore({
  serviceName: USER_SERVICE_KEY,
  service: userService,
  initialState: { me: null },
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  getters: {
    [getLoggedInUser]: (state) => {
      const me = state.me;
      return state[get(me, 'id')] || {};
    },
    [getTrialExpiryDate]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      return get(loggedInUser, 'client.trial_expiry_date');
    },
    [getClient]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      return get(loggedInUser, 'client') || {};
    },
    [getFreeObservabilityQuota]: (state, getters) => {
      const client = getters[getClient];
      return get(client, 'free_observability_quota') || 0;
    },
    [getFreeObservabilityUsage]: (state, getters) => {
      const client = getters[getClient];
      return get(client, 'free_observability_usage') || 0;
    },
    [getPaidObservabilityQuota]: (state, getters) => {
      const client = getters[getClient];
      return get(client, 'paid_observability_quota') || 0;
    },
    [getPaidObservabilityUsage]: (state, getters) => {
      const client = getters[getClient];
      return get(client, 'paid_observability_usage') || 0;
    },
    [canCreateAlert]: (state, getters) => {
      const freeQuota = getters[getFreeObservabilityQuota];
      const freeUsage = getters[getFreeObservabilityUsage];
      const paidQuota = getters[getPaidObservabilityQuota];
      const paidUsage = getters[getPaidObservabilityUsage];
      const totalQuota = freeQuota + paidQuota;
      const totalUsage = freeUsage + paidUsage;
      return totalUsage < totalQuota;
    },
    [showObservabilityUpsell]: (state, getters) => {
      const freeQuota = getters[getFreeObservabilityQuota];
      const freeUsage = getters[getFreeObservabilityUsage];
      const paidQuota = getters[getPaidObservabilityQuota];
      return freeUsage >= freeQuota && paidQuota === 0;
    },
    [showObservabilityUpgrade]: (state, getters) => {
      const paidQuota = getters[getPaidObservabilityQuota];
      const paidUsage = getters[getPaidObservabilityUsage];
      return paidQuota && paidUsage >= paidQuota;
    },
    [isUserTrialExpired]: (state, getters) => {
      const trialExpiryDate = getters[getTrialExpiryDate];
      const isBypassBilling = getters[isClientBypassBilling];
      if (isBypassBilling) {
        return false;
      }
      return trialExpiryDate && moment(trialExpiryDate).isBefore(moment());
    },
    [getBillingSubscriptions]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      return get(loggedInUser, 'client.subscription_details');
    },
    [isInActiveTrial]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      const trialExpiryDate = get(loggedInUser, 'client.trial_expiry_date');
      const isBypassBilling = getters[isClientBypassBilling];
      if (isBypassBilling) {
        return false;
      }
      return trialExpiryDate && moment(trialExpiryDate).isAfter(moment());
    },
    [isUserAnAdmin]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      const user_role = get(loggedInUser, 'role');
      return user_role == USER_ROLE_ENUM.ADMIN;
    },
    [isClientBypassBilling]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      return get(loggedInUser, 'client.is_bypass_billing');
    },
    [isClientStripeManaged]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      return get(loggedInUser, 'client.is_stripe_managed');
    },
    [hasActiveSubscription]: (state, getters) => {
      const subscriptions = getters[getBillingSubscriptions];
      const isBypassBilling = getters[isClientBypassBilling];
      if (isBypassBilling) {
        return true;
      }
      return (
        subscriptions.length > 0 &&
        every(subscriptions, (subscription) => {
          const subscription_status = subscription.subscription_status;
          if (subscription_status === 'active') {
            return true;
          }
          if (subscription_status === 'canceled') {
            const cancel_at = subscription.subscription_cancel_at;
            return cancel_at && moment(cancel_at).isAfter(moment());
          }
          return false;
        })
      );
    },
    [isBillingMailerOptedIn]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      return get(loggedInUser, 'client.receive_billing_notifications', false);
    },
    [isMonthlyMailerOptedIn]: (state, getters) => {
      const loggedInUser = getters[getLoggedInUser];
      return get(loggedInUser, 'client.receive_monthly_notifications', false);
    },
    [userServiceGetters.getAll]: (state) => (
      sortBy = ['id'],
      sortOrder = ['asc']
    ) =>
      orderBy(
        filter(state, (user, key) => key !== 'me'),
        sortBy,
        sortOrder
      ),
  },
  actions: {
    async [getLoggedInUserAction]({ commit, dispatch }, actionParams = {}) {
      const user = await dispatch(userServiceActions.get, {
        id: 'me',
        ...actionParams,
      });

      commit(userServiceMutations.update, {
        id: 'me',
        payload: user,
      });

      if (window.analytics && typeof window.analytics.identify === 'function') {
        window.analytics.identify(user.email, {
          ...user,
          product_name: 'ELT & CDC',
        });
      }

      if (window.woopra && typeof window.woopra.identify === 'function') {
        window.woopra.identify({
          email: user.email,
          name: user.full_name,
        });

        window.woopra.track();
      }

      return user;
    },
    async [updateUserPasswordAction]({ commit }, data) {
      commit(setError, { key: updateUserPasswordAction, value: null });
      commit(setLoading, { key: updateUserPasswordAction, value: true });
      try {
        await userService.updatePassword(data);
      } catch (error) {
        commit(setError, { key: updateUserPasswordAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: updateUserPasswordAction, value: false });
      }
    },
    async [inviteUserAction]({ commit }, data) {
      commit(setError, { key: inviteUserAction, value: null });
      commit(setLoading, { key: inviteUserAction, value: true });
      try {
        const user = await userService.inviteUser(data);
        user.role = data.role;
        commit(userServiceMutations.set, user);
        return user;
      } catch (error) {
        commit(setError, { key: inviteUserAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: inviteUserAction, value: false });
      }
    },
    async [resendUserInviteAction]({ commit }, id) {
      commit(setError, { key: resendUserInviteAction, value: null });
      commit(setLoading, { key: resendUserInviteAction, value: true });
      try {
        await userService.resendUserInvite(id);
      } catch (error) {
        commit(setError, { key: resendUserInviteAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: resendUserInviteAction, value: false });
      }
    },
    async [deleteUserInviteAction]({ commit }, id) {
      commit(setError, { key: deleteUserInviteAction, value: null });
      commit(setLoading, { key: deleteUserInviteAction, value: true });
      try {
        await userService.deleteUserInvite(id);
        commit(userServiceMutations.remove, id);
      } catch (error) {
        commit(setError, { key: deleteUserInviteAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: deleteUserInviteAction, value: false });
      }
    },
    async [deactivateUserAction]({ commit }, id) {
      commit(setError, { key: deactivateUserAction, value: null });
      commit(setLoading, { key: deactivateUserAction, value: true });
      try {
        await userService.remove(id);
        commit(userServiceMutations.update, {
          id,
          payload: { is_active: false },
        });
      } catch (error) {
        commit(setError, { key: deactivateUserAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: deactivateUserAction, value: false });
      }
    },
    async [activateUserAction]({ commit }, id) {
      commit(setError, { key: activateUserAction, value: null });
      commit(setLoading, { key: activateUserAction, value: true });
      try {
        await userService.activateUser(id);
        commit(userServiceMutations.update, {
          id,
          payload: { is_active: true },
        });
      } catch (error) {
        commit(setError, { key: activateUserAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: activateUserAction, value: false });
      }
    },
    async [makeadminUserAction]({ commit }, id) {
      commit(setError, { key: makeadminUserAction, value: null });
      commit(setLoading, { key: makeadminUserAction, value: true });
      try {
        await userService.makeAdmin(id);
        commit(userServiceMutations.update, {
          id,
          payload: { role: 'admin' },
        });
      } catch (error) {
        commit(setError, { key: makeadminUserAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: makeadminUserAction, value: false });
      }
    },
    async [updateClientNotificationSettingsAction]({ commit }, data) {
      commit(setError, {
        key: updateClientNotificationSettingsAction,
        value: null,
      });
      commit(setLoading, {
        key: updateClientNotificationSettingsAction,
        value: true,
      });
      try {
        await userService.updateClientNotificationSettings(data);
      } catch (error) {
        commit(setError, {
          key: updateClientNotificationSettingsAction,
          value: error,
        });
        throw error;
      } finally {
        commit(setLoading, {
          key: updateClientNotificationSettingsAction,
          value: false,
        });
      }
    },
  },
});
