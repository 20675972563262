export default Object.freeze([
  {
    label: 'v58.0',
    value: '58.0',
  },
  {
    label: 'v57.0',
    value: '57.0',
  },
  {
    label: 'v56.0',
    value: '56.0',
  },
  {
    label: 'v55.0',
    value: '55.0',
  },
  {
    label: 'v54.0',
    value: '54.0',
  },
]);
