<template>
  <div class="fly-box fly-app-navbar-user">
    <FlyAvatar
      no-shrink
      :email="loggedInUser.email"
      :name="fullName"
      :color-key="1"
    />
    <div class="fly-box fly-app-navbar-user-logout">
      <FlyTooltip tooltip="Logout">
        <FlyLink
          class="fly-box fly-app-navbar-user-logout-button"
          @click="onLogoutClick"
        >
          <FlyIcon name="Logout" />
        </FlyLink>
      </FlyTooltip>
    </div>
  </div>
</template>

<script>
import FlyAvatar from 'shared/components/Avatar/FlyAvatar';
import FlyLink from 'shared/components/Link/FlyLink.vue';
import { getLoggedInUser } from 'web/user/userDataGetterTypes';
import { mapActions, mapGetters } from 'vuex';
import { logoutAction } from 'web/auth/authActionTypes';
import CONFIG from 'shared/config';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import FlyTooltip from 'shared/components/Tooltip/FlyTooltip.vue';
import { trim, get } from 'lodash';

export default {
  name: 'AppNavbarUser',
  components: {
    FlyAvatar,
    FlyLink,
    FlyTooltip,
    FlyIcon,
  },
  computed: {
    ...mapGetters({
      getLoggedInUser,
    }),
    loggedInUser() {
      return this.getLoggedInUser || {};
    },
    fullName() {
      const firstName = trim(get(this.getLoggedInUser, 'first_name', ''));
      const lastName = trim(get(this.getLoggedInUser, 'last_name', ''));
      return trim(`${firstName} ${lastName}`);
    },
  },
  methods: {
    ...mapActions({
      logoutAction,
    }),
    async onLogoutClick() {
      window.localStorage.removeItem('promptViewed');
      await this.logoutAction();
      if (this.getLoggedInUser.integrateio_id) {
        window.location = `${CONFIG.INTEGRATEIO_URL}/auth/login`;
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.fly-app-navbar-user {
  padding: 4px;
  align-items: center;
  flex-direction: column;

  .fly-app-navbar-user-logout {
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;

    .fly-app-navbar-user-logout-button {
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin: 12px 0;
      text-decoration: none;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      .fly-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
