import { setLoading } from 'web/loading/loadingMutationTypes';
import { setError } from 'web/error/errorMutationTypes';
import pipelinesService from 'web/pipelines/pipelinesService';
import { fetchPipelineHooksAction } from 'web/pipelines/details/hooks/pipelineHooksActionTypes';

export default {
  actions: {
    async [fetchPipelineHooksAction]({ commit }, { pipelineId }) {
      var pipelineHooksResponse = {};
      commit(setLoading, { key: fetchPipelineHooksAction, value: true });
      commit(setError, { key: fetchPipelineHooksAction, value: null });
      try {
        pipelineHooksResponse = await pipelinesService.fetchPipelineHooks({
          pipelineId,
        });
        return pipelineHooksResponse;
      } catch (err) {
        commit(setError, { key: fetchPipelineHooksAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: fetchPipelineHooksAction, value: false });
      }
    },
  },
};
