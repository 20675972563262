import createServiceStore from 'shared/utils/createServiceStore.js';
import { DESTINATIONS_SERVICE_KEY } from './destinationsEnum.js';
import destinationsService from './destinationsService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import {
  changeDestinationStatusAction,
  testDestinationConnectionAction,
  testExistingDestinationConnectionAction,
} from 'web/destinations/destinationsActionTypes';
import { setDestinationTestConnectionResult } from 'web/destinations/destinationsMutationTypes';
import destinationsServiceActions from 'web/destinations/destinationsServiceActions';

export default createServiceStore({
  serviceName: DESTINATIONS_SERVICE_KEY,
  service: destinationsService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  actions: {
    async [testDestinationConnectionAction]({ commit }, { data, config }) {
      commit(setLoading, { key: testDestinationConnectionAction, value: true });
      commit(setError, { key: testDestinationConnectionAction, value: null });
      commit(setError, { key: destinationsServiceActions.create, value: null });
      commit(setDestinationTestConnectionResult, {});

      try {
        const result = await destinationsService.testConnection({
          data: { ...data, db: data.data_store },
          ...config,
        });
        commit(setDestinationTestConnectionResult, result);
      } catch (err) {
        commit(setError, { key: testDestinationConnectionAction, value: err });
        throw err;
      } finally {
        commit(setLoading, {
          key: testDestinationConnectionAction,
          value: false,
        });
      }
    },
    async [testExistingDestinationConnectionAction](
      { commit },
      { id, config = {} } = {}
    ) {
      commit(setLoading, {
        key: testExistingDestinationConnectionAction,
        value: true,
      });
      commit(setError, {
        key: testExistingDestinationConnectionAction,
        value: null,
      });
      commit(setError, { key: destinationsServiceActions.create, value: null });
      commit(setDestinationTestConnectionResult, {});

      try {
        const result = await destinationsService.testExistingDestinationConnection(
          id,
          config
        );
        commit(setDestinationTestConnectionResult, result);
      } catch (err) {
        commit(setError, {
          key: testExistingDestinationConnectionAction,
          value: err,
        });
        throw err;
      } finally {
        commit(setLoading, {
          key: testExistingDestinationConnectionAction,
          value: false,
        });
      }
    },
    async [changeDestinationStatusAction](
      { commit },
      { destinationId, status, silent = false }
    ) {
      !silent &&
        commit(setLoading, {
          key: changeDestinationStatusAction,
          value: status,
        });
      commit(setError, { key: changeDestinationStatusAction, value: null });

      try {
        await destinationsService.changeStatus(destinationId, status);
      } catch (err) {
        commit(setError, { key: changeDestinationStatusAction, value: err });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, {
            key: changeDestinationStatusAction,
            value: false,
          });
      }
    },
  },
});
