import createServiceStore from 'shared/utils/createServiceStore.js';
import { BILLING_SERVICE_KEY } from './billingEnum.js';
import billingService from './billingService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import { getManageSubscriptionLinkAction } from 'web/billing/billingActionTypes';

export default createServiceStore({
  serviceName: BILLING_SERVICE_KEY,
  service: billingService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  actions: {
    async [getManageSubscriptionLinkAction]({ commit }) {
      commit(setError, { key: getManageSubscriptionLinkAction, value: null });
      commit(setLoading, { key: getManageSubscriptionLinkAction, value: true });
      try {
        const res = await billingService.getManageSubscriptionLink();
        if (res.success) {
          window.open(res.portal_session_url);
        } else {
          throw new Error(
            'Could not generate a subscription link. Please try again.'
          );
        }
      } catch (error) {
        commit(setError, {
          key: getManageSubscriptionLinkAction,
          value: error,
        });
        throw error;
      } finally {
        commit(setLoading, {
          key: getManageSubscriptionLinkAction,
          value: false,
        });
      }
    },
  },
});
