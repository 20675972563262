import { SOURCES_API_URL, EXTERNAL_OAUTH_URL } from './sourcesEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(SOURCES_API_URL, {
  extraMethods: {
    async testConnection({ data, ...config }) {
      return this.request({
        url: `${this.route}test-connection/`,
        method: 'post',
        data,
        ...config,
      });
    },
    async testExistingSourceConnection(id, data = {}) {
      return this.request({
        url: `${this.route}${id}/test/`,
        method: 'post',
        ...data,
      });
    },
    async changeStatus(sourceId, status) {
      return this.request({
        url: `${SOURCES_API_URL}${sourceId}/${status}/`,
        method: 'post',
      });
    },
    async getGA4Properties({ data }) {
      return this.request({
        url: `${EXTERNAL_OAUTH_URL}ga4/properties/`,
        method: 'post',
        data,
      });
    },
    async getFacebookPages({ data }) {
      return this.request({
        url: `${EXTERNAL_OAUTH_URL}facebook_pages/pages/`,
        method: 'post',
        data,
      });
    },
    async getSchema({ data }) {
      return this.request({
        url: `${this.route}sample-files/`,
        method: 'post',
        data,
      });
    },
  },
});
