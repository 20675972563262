import { setError } from '../error/errorMutationTypes.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import {
  forgotPasswordAction,
  loginAction,
  logoutAction,
  resetPasswordAction,
  verifyInviteTokenAction,
  verifyPasswordTokenAction,
  signupAction,
  userInviteAction,
  userEmailConfirmationAction,
  setSignupPasswordAction,
} from './authActionTypes.js';
import { getVerifiedUser } from './authGetterTypes.js';
import { setVerifiedUser } from './authMutationTypes.js';
import authService from './authService.js';
import { load } from 'recaptcha-v3';
import CONFIG from 'shared/config';
import { shutdownIntercomAction } from 'web/app/appActionTypes';

export default {
  state: {
    user: {},
  },
  getters: {
    [getVerifiedUser]: (state) => state.user,
  },
  mutations: {
    [setVerifiedUser](state, user) {
      state.user = user;
    },
  },
  actions: {
    async [verifyPasswordTokenAction]({ commit }, token) {
      commit(setError, { key: verifyPasswordTokenAction, value: null });
      commit(setLoading, { key: verifyPasswordTokenAction, value: true });
      try {
        await authService.verifyPasswordToken(token);
      } catch (error) {
        commit(setError, { key: verifyPasswordTokenAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: verifyPasswordTokenAction, value: false });
      }
    },
    async [verifyInviteTokenAction]({ commit }, token) {
      commit(setError, { key: verifyInviteTokenAction, value: null });
      commit(setLoading, { key: verifyInviteTokenAction, value: true });
      try {
        return await authService.verifyInviteToken(token);
      } catch (error) {
        commit(setError, { key: verifyInviteTokenAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: verifyInviteTokenAction, value: false });
      }
    },
    async [userInviteAction]({ commit }, data) {
      commit(setError, { key: userInviteAction, value: null });
      commit(setLoading, { key: userInviteAction, value: true });
      try {
        return await authService.processUserInvite(data);
      } catch (error) {
        commit(setError, { key: userInviteAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: userInviteAction, value: false });
      }
    },
    async [resetPasswordAction]({ commit }, data) {
      commit(setError, { key: resetPasswordAction, value: null });
      commit(setLoading, { key: resetPasswordAction, value: true });
      try {
        return await authService.resetPassword(data);
      } catch (error) {
        commit(setError, { key: resetPasswordAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: resetPasswordAction, value: false });
      }
    },
    async [loginAction]({ commit }, data) {
      commit(setError, { key: loginAction, value: null });
      commit(setLoading, { key: loginAction, value: true });
      try {
        return await authService.login(data);
      } catch (error) {
        commit(setError, { key: loginAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: loginAction, value: false });
      }
    },
    async [signupAction]({ commit }, data) {
      commit(setError, { key: signupAction, value: null });
      commit(setLoading, { key: signupAction, value: true });
      try {
        const recaptcha = await load(CONFIG.RECAPTCHA_SITE_KEY);
        const token = await recaptcha.execute('login');
        return await authService.signup({ ...data, recaptcha_token: token });
      } catch (error) {
        commit(setError, { key: signupAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: signupAction, value: false });
      }
    },
    async [logoutAction]({ dispatch }) {
      dispatch(shutdownIntercomAction);
      await authService.logout();
      window.location.assign(window.location.origin);
    },
    async [forgotPasswordAction]({ commit }, data) {
      commit(setError, { key: forgotPasswordAction, value: null });
      commit(setLoading, { key: forgotPasswordAction, value: true });
      try {
        return await authService.forgotPassword(data);
      } catch (error) {
        commit(setError, { key: forgotPasswordAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: forgotPasswordAction, value: false });
      }
    },
    async [userEmailConfirmationAction]({ commit }, token) {
      commit(setError, { key: userEmailConfirmationAction, value: null });
      commit(setLoading, { key: userEmailConfirmationAction, value: true });
      try {
        return await authService.validateUserEmail(token);
      } catch (error) {
        commit(setError, { key: userEmailConfirmationAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: userEmailConfirmationAction, value: false });
      }
    },
    async [setSignupPasswordAction]({ commit }, data) {
      commit(setError, { key: setSignupPasswordAction, value: null });
      commit(setLoading, { key: setSignupPasswordAction, value: true });
      try {
        return await authService.setSignupPassword(data);
      } catch (error) {
        commit(setError, { key: setSignupPasswordAction, value: error });
        throw error;
      } finally {
        commit(setLoading, { key: setSignupPasswordAction, value: false });
      }
    },
  },
};
