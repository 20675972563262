export const setFormData = 'setFormData';
export const setInitialFormData = 'setInitialFormData';
export const clearFormData = 'clearFormData';
export const resetFormData = 'resetFormData';
export const setFormField = 'setFormField';
export const setInitialFormField = 'setInitialFormField';
export const setFormFieldError = 'setFormFieldError';
export const setFormFieldAtPath = 'setFormFieldAtPath';
export const setInitialFormFieldAtPath = 'setInitialFormFieldAtPath';
export const clearFormErrors = 'clearFormErrors';
