import createServiceStore from 'shared/utils/createServiceStore.js';
import { SOURCES_SERVICE_KEY } from './sourcesEnum.js';
import sourcesService from './sourcesService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import {
  changeSourceStatusAction,
  testExistingSourceConnectionAction,
  testSourceConnectionAction,
  getGA4PropertiesAction,
  getFacebookPagesAction,
  getSchemaAction,
} from 'web/sources/sourcesActionTypes';
import { setSourceTestConnectionResult } from 'web/sources/sourcesMutationTypes';
import sourcesServiceActions from 'web/sources/sourcesServiceActions';

export default createServiceStore({
  serviceName: SOURCES_SERVICE_KEY,
  service: sourcesService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  actions: {
    async [testSourceConnectionAction]({ commit }, { data, config } = {}) {
      commit(setLoading, { key: testSourceConnectionAction, value: true });
      commit(setError, { key: testSourceConnectionAction, value: null });
      commit(setError, { key: sourcesServiceActions.create, value: null });
      commit(setSourceTestConnectionResult, {});

      try {
        const result = await sourcesService.testConnection({
          data: { ...data, data_store: data.data_store },
          ...config,
        });
        commit(setSourceTestConnectionResult, result);
      } catch (err) {
        commit(setError, { key: testSourceConnectionAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: testSourceConnectionAction, value: false });
      }
    },
    async [testExistingSourceConnectionAction](
      { commit },
      { id, data = {} } = {},
    ) {
      commit(setLoading, {
        key: testExistingSourceConnectionAction,
        value: true,
      });
      commit(setError, {
        key: testExistingSourceConnectionAction,
        value: null,
      });
      commit(setError, { key: sourcesServiceActions.create, value: null });
      commit(setSourceTestConnectionResult, {});

      try {
        const result = await sourcesService.testExistingSourceConnection(id, {
          data: {
            config: data.config,
            ...(data.tunnel_id ? { tunnel_id: data.tunnel_id } : {}),
          },
        });
        commit(setSourceTestConnectionResult, result);
      } catch (err) {
        commit(setError, {
          key: testExistingSourceConnectionAction,
          value: err,
        });
        throw err;
      } finally {
        commit(setLoading, {
          key: testExistingSourceConnectionAction,
          value: false,
        });
      }
    },
    async [changeSourceStatusAction](
      { commit },
      { sourceId, status, silent = false },
    ) {
      !silent &&
        commit(setLoading, { key: changeSourceStatusAction, value: status });
      commit(setError, { key: changeSourceStatusAction, value: null });

      try {
        await sourcesService.changeStatus(sourceId, status);
      } catch (err) {
        commit(setError, { key: changeSourceStatusAction, value: err });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, { key: changeSourceStatusAction, value: false });
      }
    },
    async [getGA4PropertiesAction]({ commit }, { accessToken, refreshToken }) {
      commit(setLoading, { key: getGA4PropertiesAction, value: true });
      commit(setError, { key: getGA4PropertiesAction, value: null });
      try {
        return await sourcesService.getGA4Properties({
          data: { access_token: accessToken, refresh_token: refreshToken },
        });
      } catch (err) {
        commit(setError, { key: getGA4PropertiesAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: getGA4PropertiesAction, value: false });
      }
    },
    async [getFacebookPagesAction]({ commit }, { accessToken }) {
      commit(setLoading, { key: getFacebookPagesAction, value: true });
      commit(setError, { key: getFacebookPagesAction, value: null });
      try {
        return await sourcesService.getFacebookPages({
          data: { access_token: accessToken },
        });
      } catch (err) {
        commit(setError, { key: getFacebookPagesAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: getFacebookPagesAction, value: false });
      }
    },
    async [getSchemaAction](
      { commit },
      { config, data_store, sample_options },
    ) {
      commit(setLoading, { key: getSchemaAction, value: true });
      commit(setError, { key: getSchemaAction, value: null });
      let result = null;
      try {
        result = await sourcesService.getSchema({
          data: {
            config: config,
            data_store: data_store,
            sample_options: sample_options,
          },
        });
        if (result.success) {
          return result;
        } else {
          throw new Error(result.error);
        }
      } catch (err) {
        commit(setError, { key: getSchemaAction, value: err });
        throw err;
      } finally {
        commit(setLoading, { key: getSchemaAction, value: false });
      }
    },
  },
});
