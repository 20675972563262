<template>
  <div :style="{ width: '100%' }">
    <FlyButtonRaw
      v-if="!showRoleArnInputField"
      class="fly-button fly-button--download"
      icon="ChevronRight"
      icon-placement="left"
      type="button"
      :style="{ marginTop: '12px', marginBottom: '12px', width: '42%' }"
      :is-loading="isFetchingCFTemplate"
      :disabled="disabled"
      @click="getTemplateAction"
      >{{ btnText }}</FlyButtonRaw
    >
    <FlyInput
      v-else
      class="fly-input fly-input--column"
      v-bind="inputProps"
      @input="$emit('change', $event)"
    ></FlyInput>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import FlyInput from 'shared/components/Form/FlyInput.vue';
import FlyButtonRaw from 'shared/components/Button/FlyButton.vue';
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import { getS3RoleAccessSetupCloudFormationTemplateUrl } from 'web/connector/connectorActionTypes';
import { isLoading } from 'web/loading/loadingGetterTypes';
import FormStoreMixin from 'shared/stores/form/FormStoreMixin';
export default {
  name: 'PipelineS3AccessSetup',
  components: {
    FlyInput,
    FlyButtonRaw,
  },
  mixins: [FormStoreMixin()],
  props: {
    inputProps: VueTypes.object.def({}),
  },
  emits: ['change'],
  data() {
    return {
      showRoleArnInputField: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading,
    }),
    isFetchingCFTemplate() {
      return this.isLoading(getS3RoleAccessSetupCloudFormationTemplateUrl);
    },
    btnText() {
      return this.isFetchingCFTemplate
        ? 'Waiting for setup config...'
        : 'Setup role access using CloudFormation';
    },
    bucketName() {
      return this.formFieldValue('config.connection_config.bucket_name');
    },
    bucketRegion() {
      return this.formFieldValue('config.provider.region');
    },
    disabled() {
      return !this.bucketName || !this.bucketRegion;
    },
  },
  methods: {
    ...mapActions({
      getCFSetupTemplateUrl: getS3RoleAccessSetupCloudFormationTemplateUrl,
    }),
    async getTemplateAction() {
      const params = {
        bucket_name: this.bucketName,
        bucket_region: this.bucketRegion,
      };
      const result = await this.getCFSetupTemplateUrl({ params });
      if (result && get(result, 'success', false)) {
        this.showRoleArnInputField = true;
        window.open(result.template_url);
      }
    },
  },
};
</script>

<style scoped></style>
