import { TUNNEL_API_URL } from './tunnelEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(TUNNEL_API_URL, {
  extraMethods: {
    downloadTunnelTemplate(tunnelId, config_type) {
      window.open(`${TUNNEL_API_URL}${tunnelId}/?config_type=${config_type}`);
    },

    async testConnection(tunnelId) {
      return this.request({
        url: `${this.route}test-connection/${tunnelId}/`,
        method: 'post',
      });
    },
  },
});
