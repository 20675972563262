<template>
  <div class="fly-box fly-avatar" :style="{ backgroundColor: colorCode }">
    <img v-if="imgSrc && !noImageFound" :src="imgSrc" @error="onImgError" />
    <span v-else class="fly-text fly-avatar-text">
      {{ initial }}
    </span>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import getGravatarUrl from 'shared/utils/getGravatarUrl';

const FLY_AVATAR_USER_INITIALS_COLOR_ENUMS = [
  '#00c09a',
  '#00a87b',
  '#00d166',
  '#f5d047',
  '#ff9800',
  '#ff7a3b',
  '#4bb0df',
  '#99c2d5',
  '#ff7f77',
  '#a651bb',
  '#fb7fc2',
  '#ff9280',
  '#b9914a',
  '#cd7872',
  '#57c7b8',
  '#c0c757',
];

export default {
  name: 'FlyAvatar',
  props: {
    src: VueTypes.string.def(''),
    name: VueTypes.string.def(''),
    email: VueTypes.string.def(''),
    colorKey: VueTypes.integer.isRequired,
  },
  data() {
    return {
      noImageFound: false,
    };
  },
  computed: {
    colorCode() {
      return this.noImageFound
        ? FLY_AVATAR_USER_INITIALS_COLOR_ENUMS[
            this.colorKey % FLY_AVATAR_USER_INITIALS_COLOR_ENUMS.length
          ]
        : '#ffffff';
    },
    imgSrc() {
      return this.src || (this.email ? getGravatarUrl(this.email) : '');
    },
    initial() {
      return this.name ? this.name[0] : '';
    },
  },
  methods: {
    onImgError() {
      this.noImageFound = true;
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-avatar {
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .fly-avatar-text {
    color: #ffffff;
    line-height: 1;
    font-weight: 500;
  }
}

.fly-avatar,
.fly-avatar img {
  height: 32px;
  width: 32px;
  min-width: 32px;
}
</style>
