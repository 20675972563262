export const CHANGE_USER_PASSWORD_MODAL = 'CHANGE_USER_PASSWORD_MODAL';
export const CONFIRM_SUBSCRIPTION_MODAL = 'CONFIRM_SUBSCRIPTION_MODAL';
export const CONFIRM_EDIT_SOURCE_MODAL = 'CONFIRM_EDIT_SOURCE_MODAL';
export const CONFIRM_EDIT_DESTINATION_MODAL = 'CONFIRM_EDIT_DESTINATION_MODAL';
export const COLLECTION_FIELD_EXCLUDE_MODAL = 'COLLECTION_FIELD_EXCLUDE_MODAL';
export const CREATE_PIPELINE_MODAL = 'CREATE_PIPELINE_MODAL';
export const INTEGRATEIO_MODAL = 'INTEGRATEIO_MODAL';
export const SOURCE_DESTINATION_CONNECTION_VALIDATION_MODAL =
  'SOURCE_DESTINATION_CONNECTION_VALIDATION_MODAL';
export const TUNNEL_CONNECTION_VALIDATION_MODAL =
  'TUNNEL_CONNECTION_VALIDATION_MODAL';
export const USER_INVITE_MODAL = 'USER_INVITE_MODAL';
export const HOOK_CREATE_MODAL = 'HOOK_CREATE_MODAL';
export const HOOK_ATTACH_MODAL = 'HOOK_ATTACH_MODAL';
export const SOURCE_DESTINATION_EDIT_CONFIRMATION_MODAL =
  'SOURCE_DESTINATION_EDIT_CONFIRMATION_MODAL';
export const COLLECTION_FIELD_CUSTOM_PRIMARY_KEY_MODAL =
  'COLLECTION_FIELD_CUSTOM_PRIMARY_KEY_MODAL';
export const CREATE_METRIC_MODAL = 'CREATE_METRIC_MODAL';
export const RESYNC_CONFIRMATION_MODAL = 'RESYNC_CONFIRMATION_MODAL';
export const CONFIRM_ALERT_DEACTIVATION_MODAL =
  'CONFIRM_ALERT_DEACTIVATION_MODAL';
export const CONFIRM_ARCHIVE_PIPELINE_MODAL = 'CONFIRM_ARCHIVE_PIPELINE_MODAL';
export const CONFIRM_ARCHIVE_SOURCE_MODAL = 'CONFIRM_ARCHIVE_SOURCE_MODAL';
export const CONFIRM_ARCHIVE_DESTINATION_MODAL =
  'CONFIRM_ARCHIVE_DESTINATION_MODAL';
export const CONFIRM_USER_DEACTIVATION_MODAL =
  'CONFIRM_USER_DEACTIVATION_MODAL';
export const CONFIRM_METRIC_ARCHIVE_MODAL = 'CONFIRM_METRIC_ARCHIVE_MODAL';
