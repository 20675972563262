import { openUserInviteModalAction } from 'web/user/userActionTypes';
import {
  clearFormData,
  setInitialFormData,
} from 'shared/stores/form/formMutationTypes';
import { USER_INVITE_FORM } from 'web/form/formKeys';
import { isEmpty } from 'lodash';
import { USER_INVITE_MODAL } from 'web/modal/modalKeys';
import { openModal } from 'shared/store/modal/modalMutationTypes';
import { showUserInviteModal } from 'web/user/userGetterTypes';
import { isModalOpen } from 'shared/store/modal/modalGetterTypes';

export default {
  state: {},
  getters: {
    [showUserInviteModal](state, getters, rootState, rootGetters) {
      return rootGetters[isModalOpen](USER_INVITE_MODAL);
    },
  },
  actions: {
    [openUserInviteModalAction]({ commit }, initialFormData = {}) {
      commit(clearFormData, USER_INVITE_FORM);
      if (!isEmpty(initialFormData)) {
        commit(setInitialFormData, {
          key: USER_INVITE_FORM,
          formData: initialFormData,
        });
      }
      commit(openModal, USER_INVITE_MODAL);
    },
  },
};
