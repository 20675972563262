import { getAlertUsage } from 'web/alerts/usage/alertUsageGetterTypes.js';
import { toChartData } from 'web/pipelines/usage/pipelineUsageStore.js';
import { setAlertUsage } from 'web/alerts/usage/alertUsageMutationTypes.js';
import { set } from 'lodash';
import { fetchAlertUsageAction } from 'web/alerts/usage/alertUsageActionTypes.js';
import alertsService from 'web/alerts/alertsService.js';
import { setLoading } from 'web/loading/loadingMutationTypes.js';
import { setError } from 'web/error/errorMutationTypes.js';

export default {
  state: {},
  getters: {
    [getAlertUsage]: (state) => (id) => {
      const usage = state[id];
      return [
        {
          name: 'Times alert was triggered',
          data: toChartData(usage),
        },
      ];
    },
  },
  mutations: {
    [setAlertUsage](state, { id, usage }) {
      set(state, id, usage);
    },
  },
  actions: {
    async [fetchAlertUsageAction](
      { commit },
      { id, start_timestamp, end_timestamp, group_by, silent = false }
    ) {
      !silent &&
        commit(setLoading, { key: fetchAlertUsageAction, value: true });
      commit(setError, { key: fetchAlertUsageAction, value: null });

      try {
        const usage = await alertsService.fetchAlertUsage({
          id,
          start_timestamp,
          end_timestamp,
          group_by,
        });
        commit(setAlertUsage, { id, usage });
      } catch (err) {
        commit(setError, { key: fetchAlertUsageAction, value: err });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, { key: fetchAlertUsageAction, value: false });
      }
    },
  },
};
