import { split, reduce, trim } from 'lodash';

export default name => {
  const cookies = reduce(
    split(document.cookie, ';'),
    (res, cookie) => {
      const [key, value] = split(trim(cookie), '=');
      res[key] = value;
      return res;
    },
    {}
  );
  return cookies[name];
};
