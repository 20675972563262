import createServiceStore from 'shared/utils/createServiceStore.js';
import { ALERTTRIGGER_SERVICE_KEY } from './alertTriggerEnum.js';
import alertTriggerService from './alertTriggerService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import {
  acknowledgeAllAlertTriggersAction,
  resolveAllAlertTriggersAction,
} from 'web/alertTrigger/alertTriggerActionTypes.js';

export default createServiceStore({
  serviceName: ALERTTRIGGER_SERVICE_KEY,
  service: alertTriggerService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  actions: {
    async [acknowledgeAllAlertTriggersAction]({ commit }, { alert_id }) {
      commit(setError, { key: acknowledgeAllAlertTriggersAction, value: null });
      commit(setLoading, {
        key: acknowledgeAllAlertTriggersAction,
        value: true,
      });
      try {
        await alertTriggerService.acknowledgeAllAlertTriggers({ alert_id });
      } catch (error) {
        commit(setError, {
          key: acknowledgeAllAlertTriggersAction,
          value: error,
        });
        throw error;
      } finally {
        commit(setLoading, {
          key: acknowledgeAllAlertTriggersAction,
          value: false,
        });
      }
    },
    async [resolveAllAlertTriggersAction]({ commit }, { alert_id }) {
      commit(setError, { key: resolveAllAlertTriggersAction, value: null });
      commit(setLoading, {
        key: resolveAllAlertTriggersAction,
        value: true,
      });
      try {
        await alertTriggerService.resolveAllAlertTriggers({ alert_id });
      } catch (error) {
        commit(setError, {
          key: resolveAllAlertTriggersAction,
          value: error,
        });
        throw error;
      } finally {
        commit(setLoading, {
          key: resolveAllAlertTriggersAction,
          value: false,
        });
      }
    },
  },
});
