import { setLoading } from 'web/loading/loadingMutationTypes';
import { setError } from 'web/error/errorMutationTypes';
import pipelinesService from 'web/pipelines/pipelinesService';
import {
  fetchPipelineAccountUsageAction,
  fetchPipelineAccountUsagePipelinesAction,
  fetchPipelineUsageCollectionsAction,
} from 'web/settings/usage/pipelineAccountUsageActionTypes';

export default {
  actions: {
    async [fetchPipelineAccountUsageAction]({ commit }, params) {
      var pipelineAccountUsageResponse = {};
      commit(setLoading, { key: fetchPipelineAccountUsageAction, value: true });
      commit(setError, { key: fetchPipelineAccountUsageAction, value: null });
      try {
        pipelineAccountUsageResponse =
          await pipelinesService.fetchAccountUsage(params);
        return pipelineAccountUsageResponse;
      } catch (err) {
        commit(setError, { key: fetchPipelineAccountUsageAction, value: err });
        throw err;
      } finally {
        commit(setLoading, {
          key: fetchPipelineAccountUsageAction,
          value: false,
        });
      }
    },
    async [fetchPipelineAccountUsagePipelinesAction]({ commit }, params) {
      var pipelineAccountUsageResponse = {};
      commit(setLoading, {
        key: fetchPipelineAccountUsagePipelinesAction,
        value: true,
      });
      commit(setError, {
        key: fetchPipelineAccountUsagePipelinesAction,
        value: null,
      });
      try {
        pipelineAccountUsageResponse =
          await pipelinesService.fetchAccountUsagePipelines(params);
        return pipelineAccountUsageResponse;
      } catch (err) {
        commit(setError, {
          key: fetchPipelineAccountUsagePipelinesAction,
          value: err,
        });
        throw err;
      } finally {
        commit(setLoading, {
          key: fetchPipelineAccountUsagePipelinesAction,
          value: false,
        });
      }
    },
    async [fetchPipelineUsageCollectionsAction]({ commit }, params) {
      var pipelineAccountUsageResponse = {};
      commit(setLoading, {
        key: fetchPipelineUsageCollectionsAction,
        value: true,
      });
      commit(setError, {
        key: fetchPipelineUsageCollectionsAction,
        value: null,
      });
      try {
        pipelineAccountUsageResponse =
          await pipelinesService.fetchPipelineUsageCollections(params);
        return pipelineAccountUsageResponse;
      } catch (err) {
        commit(setError, {
          key: fetchPipelineUsageCollectionsAction,
          value: err,
        });
        throw err;
      } finally {
        commit(setLoading, {
          key: fetchPipelineUsageCollectionsAction,
          value: false,
        });
      }
    },
  },
};
