import alertsUIStore from './alertsUIStore.js';
import alertsDataStore from './alertsDataStore.js';
import alertUsageStore from 'web/alerts/usage/alertUsageStore.js';

export default {
  modules: {
    ui: alertsUIStore,
    data: alertsDataStore,
    usage: alertUsageStore,
  },
};
