<template>
  <FlyDropdown
    trigger="hover"
    mount-on="body"
    :popper-options="{ placement: 'top' }"
    v-bind="$attrs"
  >
    <template #trigger>
      <slot></slot>
    </template>
    <template #options>
      <slot name="tooltip">
        <span v-if="tooltip" class="fly-text fly-text--tooltip">
          {{ tooltip }}
        </span>
      </slot>
    </template>
  </FlyDropdown>
</template>

<script>
import VueTypes from 'vue-types';
import FlyDropdown from 'shared/components/Dropdown/FlyDropdown.vue';

export default {
  name: 'FlyTooltip',
  components: { FlyDropdown },
  props: {
    tooltip: VueTypes.oneOfType([VueTypes.string, VueTypes.bool]).def(''),
  },
  inheritAttrs: false,
};
</script>

<style lang="scss"></style>
