import qs from 'qs';
import createService from 'shared/utils/createService.js';
import {
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  RESET_PASSWORD_URL,
  RESET_PASSWORD_TOKEN_VERIFY_URL,
  SIGNUP_URL,
  USER_EMAIL_VALIDATE_URL,
  USER_INVITE_TOKEN_VERIFY_URL,
  USER_INVITE_TOKEN_PROCESS_URL,
  SET_SIGNUP_PASSWORD,
} from './authEnum.js';

export default createService(LOGIN_URL, {
  extraMethods: {
    async verifyPasswordToken(token) {
      return this.request({
        url: `${RESET_PASSWORD_TOKEN_VERIFY_URL}${token}/`,
        method: 'get',
      });
    },
    async resetPassword(data) {
      return this.request({
        url: `${RESET_PASSWORD_URL}${data.token}/`,
        method: 'post',
        data,
      });
    },
    async verifyInviteToken(token) {
      return this.request({
        url: `${USER_INVITE_TOKEN_VERIFY_URL}${token}/`,
        method: 'get',
      });
    },
    async login(data) {
      return this.request({
        url: this.route,
        method: 'post',
        data: qs.stringify(data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    },
    async signup(data) {
      return this.request({
        url: SIGNUP_URL,
        method: 'post',
        data: qs.stringify(data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    },
    async logout() {
      return this.request({
        url: LOGOUT_URL,
        method: 'post',
      });
    },
    async forgotPassword(data) {
      return this.request({
        url: `${FORGOT_PASSWORD_URL}${data.email}/`,
        method: 'post',
      });
    },
    async processUserInvite(data) {
      return this.request({
        url: `${USER_INVITE_TOKEN_PROCESS_URL}${data.token}/`,
        method: 'post',
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          password: data.password,
        },
      });
    },
    async validateUserEmail(token) {
      return this.request({
        url: `${USER_EMAIL_VALIDATE_URL}${token}/`,
        method: 'post',
      });
    },
    async setSignupPassword(data) {
      return this.request({
        url: `${SET_SIGNUP_PASSWORD}${data.token}/`,
        method: 'post',
        data,
      });
    },
  },
});
