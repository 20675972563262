<template>
  <AppNavbarMenuItem
    v-for="(navItem, index) in navItems"
    :key="index"
    :to="navItem.to"
    :icon="navItem.icon"
    :is-active="isActive(navItem)"
    :text="navItem.text"
  >
  </AppNavbarMenuItem>
</template>

<script>
import AppNavbarMenuItem from 'web/common/nav/AppNavbarMenuItem';
import { compact } from 'lodash';
import { mapGetters } from 'vuex';
import { getFeatureToggle } from 'web/featureToggle/featureToggleGetterTypes';
import FEATURE_TOGGLE_ENUM from 'shared/featureToggle/FEATURE_TOGGLE_ENUM';

export default {
  name: 'AppNavbarMenu',
  components: {
    AppNavbarMenuItem,
  },
  computed: {
    ...mapGetters({
      getFeatureToggle,
    }),
    isDataObservabilityEnabled() {
      return this.getFeatureToggle(
        FEATURE_TOGGLE_ENUM.DATA_OBSERVABILITY_ENABLED
      );
    },
    navItems() {
      return compact([
        {
          to: '/pipelines',
          icon: 'Pipeline',
          text: 'Pipelines',
        },
        {
          to: '/sources',
          icon: 'Folder',
          text: 'Sources',
        },
        {
          to: '/destinations',
          icon: 'Server',
          text: 'Destinations',
        },
        ...(this.isDataObservabilityEnabled
          ? [
              {
                to: '/observability',
                icon: 'Bell',
                text: 'Observability',
              },
            ]
          : []),
        {
          to: '/settings',
          icon: 'Cog',
          text: 'Settings',
        },
      ]);
    },
  },
  methods: {
    isActive(navItem) {
      if (navItem.to === '/observability') {
        return (
          this.$route.path.startsWith(navItem.to) ||
          this.$route.path.startsWith('/alerts') ||
          this.$route.path.startsWith('/metrics')
        );
      }
      return this.$route.path.startsWith(navItem.to);
    },
  },
};
</script>

<style lang="scss"></style>
