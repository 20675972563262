<template>
  <div class="fly-box logo-container">
    <FlyDataLogoIcon />
  </div>
</template>
<script>
import FlyDataLogoIcon from 'shared/components/Icon/FlyDataLogoIcon';

export default {
  name: 'AppNavbarLogo',
  components: {
    FlyDataLogoIcon,
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  flex-flow: column;
  padding: 16px;
}
</style>
