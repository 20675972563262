import createServiceStore from 'shared/utils/createServiceStore.js';
import { CONNECTOR_SERVICE_KEY } from './connectorEnum.js';
import connectorService from './connectorService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import { getS3RoleAccessSetupCloudFormationTemplateUrl } from 'web/connector/connectorActionTypes';

export default createServiceStore({
  serviceName: CONNECTOR_SERVICE_KEY,
  service: connectorService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  actions: {
    async [getS3RoleAccessSetupCloudFormationTemplateUrl](
      { commit },
      { params, silent = false }
    ) {
      !silent &&
        commit(setLoading, {
          key: getS3RoleAccessSetupCloudFormationTemplateUrl,
          value: true,
        });
      commit(setError, {
        key: getS3RoleAccessSetupCloudFormationTemplateUrl,
        value: null,
      });

      try {
        return await connectorService.getS3RoleAccessSetupCloudFormationTemplateUrl(
          {
            params,
          }
        );
      } catch (err) {
        commit(setError, {
          key: getS3RoleAccessSetupCloudFormationTemplateUrl,
          value: err,
        });
        throw err;
      } finally {
        !silent &&
          commit(setLoading, {
            key: getS3RoleAccessSetupCloudFormationTemplateUrl,
            value: false,
          });
      }
    },
  },
});
