<template>
  <FlyInputLabel :error="error" :label="label" class="fly-file-input">
    <input
      ref="input"
      type="file"
      :style="{
        visibility: 'hidden',
        position: 'absolute',
        width: 0,
        height: 0,
      }"
      :accept="accept"
      @change="onFileInputChange"
    />
    <div>
      <FlyButtonRaw
        class="fly-button fly-button--secondary fly-button--icon"
        icon-placement="left"
        icon="Plus"
        type="button"
        @click="onButtonClick"
      >
        <slot>{{ _buttonText }}</slot>
      </FlyButtonRaw>
    </div>
  </FlyInputLabel>
</template>

<script>
import VueTypes from 'vue-types';
import { get } from 'lodash';
import FlyInputLabel from 'shared/components/Form/FlyInputLabel.vue';
import FlyButtonRaw from 'shared/components/Button/FlyButton.vue';

export default {
  name: 'FlyFileInput',
  components: {
    FlyButtonRaw,
    FlyInputLabel,
  },
  props: {
    buttonText: VueTypes.string.isRequired,
    error: VueTypes.string.def(''),
    label: VueTypes.string.def(''),
    accept: VueTypes.string.def(''),
  },
  emits: ['change'],
  data() {
    return {
      fileName: '',
    };
  },
  computed: {
    _buttonText() {
      return this.fileName || this.buttonText;
    },
  },
  methods: {
    onButtonClick() {
      this.$refs.input.click();
    },
    onFileInputChange(e) {
      this.fileName = get(e, 'target.files[0].name', '');
      this.$emit('change', e);
    },
  },
  inheritAttrs: false,
};
</script>

<style lang="scss">
.fly-file-input.fly-label {
  margin-top: 16px;
}
</style>
