<template>
  <div class="fly-box pipeline-create-ssh-options">
    <div v-if="!disabled" class="fly-box">
      <FlyRadioCard
        label="Connect directly"
        name="ssh"
        help-text="Choose if your database is available on public address."
        value="direct"
        :selected-value="formFieldValue('connectionType')"
        @change="onDirectSelect"
      />
      <FlyRadioCard
        label="Connect via secure tunnel"
        name="ssh"
        help-text="Choose if your source is NOT publicly accessible or in a private network."
        value="secure"
        :selected-value="formFieldValue('connectionType')"
        @change="onTunnelSelect"
      />
    </div>
    <div
      v-if="disabled && isDirect"
      class="fly-text fly-text--ui-medium connecting-text"
    >
      Connecting Directly
    </div>
    <PipelineSSHConnectDirect
      v-if="isDirect"
      :provider="provider"
      :region="region"
    />
    <div
      v-if="disabled && isSecureTunnel"
      class="fly-text fly-text--ui-medium connecting-text"
    >
      Connecting via Tunnel: &nbsp;
      <div class="fly-text fly-text--ui-medium connecting-sub-text">
        {{ tunnelName }}
      </div>
    </div>
    <PipelineSSHConnectSecureTunnel
      v-if="!disabled && isSecureTunnel"
      :tunnels="tunnels"
      :source-or-destination="nodeType"
      :use-existing-tunnel="useExistingTunnel"
      :selected-tunnel="selectedTunnel"
      :region="region"
      :provider="provider"
      @use-existing-tunnel-change="onUseExistingTunnelChange"
      @tunnel-selection-change="onTunnelSelectionChange"
      @refresh-tunnel-list="onRefreshTunnelList"
    />
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { get } from 'lodash';
import FlyRadioCard from 'shared/components/Form/FlyRadioCard';
import PipelineSSHConnectDirect from 'web/pipelines/create/PipelineSSHConnectDirect';
import PipelineSSHConnectSecureTunnel from 'web/pipelines/create/PipelineSSHConnectSecureTunnel';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import tunnelServiceGetters from 'web/tunnel/tunnelServiceGetters';
import { setUseExistingTunnel } from 'web/pipelines/pipelinesMutationTypes';
import { getUseExistingTunnel } from 'web/pipelines/pipelinesGetterTypes';
import { CREATE_SECURE_TUNNEL_FORM } from 'web/form/formKeys';
import { clearFormData } from 'shared/stores/form/formMutationTypes';
import FormStoreMixin from 'shared/stores/form/FormStoreMixin';
import tunnelServiceActions from 'web/tunnel/tunnelServiceActions';

export default {
  name: 'PipelineCreateSSHOptions',
  components: {
    PipelineSSHConnectSecureTunnel,
    PipelineSSHConnectDirect,
    FlyRadioCard,
  },
  mixins: [FormStoreMixin()],
  props: {
    nodeType: VueTypes.oneOf(['source', 'destination']).isRequired,
    disabled: VueTypes.bool.def(false),
    region: VueTypes.number.isRequired,
    provider: VueTypes.number.isRequired,
  },
  computed: {
    ...mapGetters({
      getAllTunnels: tunnelServiceGetters.getAll,
      getTunnel: tunnelServiceGetters.get,
      useExistingTunnel: getUseExistingTunnel,
    }),
    isDirect() {
      return this.formFieldValue('connectionType') === 'direct';
    },
    isSecureTunnel() {
      return this.formFieldValue('connectionType') === 'secure';
    },
    selectedTunnelId() {
      return this.formFieldValue('tunnel_id');
    },
    selectedTunnel() {
      return this.getTunnel(this.selectedTunnelId) || null;
    },
    tunnelName() {
      return get(this.selectedTunnel, 'name');
    },
    tunnels() {
      return this.getAllTunnels();
    },
    activeTunnels() {
      return this.tunnels.filter((tunnel) => {
        return get(tunnel, 'is_active');
      });
    },
  },
  mounted() {
    this.getTunnelList();
    if (!this.tunnels) {
      this.setUseExistingTunnel(false);
    } else {
      this.setUseExistingTunnel(true);
    }
  },
  methods: {
    ...mapMutations({ setUseExistingTunnel, clearFormData }),
    ...mapActions({
      getTunnelList: tunnelServiceActions.list,
    }),
    onTunnelSelectionChange(tunnel) {
      this.setFormField('tunnel_id', tunnel.id);
    },
    onDirectSelect() {
      this.setFormField('connectionType', 'direct');
      this.setFormField('tunnel_id', null);
    },
    onTunnelSelect() {
      this.setFormField('connectionType', 'secure');
      if (this.tunnels.length) {
        this.setUseExistingTunnel(true);
        this.setFormField('tunnel_id', this.activeTunnels[0]?.id);
      } else {
        this.setUseExistingTunnel(false);
        this.setFormField('tunnel_id', null);
      }
    },
    onUseExistingTunnelChange(value) {
      this.setUseExistingTunnel(value);
      if (value) {
        this.setFormField('tunnel_id', this.activeTunnels[0]?.id);
      } else {
        this.setFormField('tunnel_id', null);
        this.clearFormData(CREATE_SECURE_TUNNEL_FORM);
      }
    },
    async onRefreshTunnelList() {
      await this.getTunnelList();
    },
  },
};
</script>

<style lang="scss">
.pipeline-create-ssh-options {
  flex-direction: column;
  width: 100%;

  .fly-radio-card {
    padding: 24px 20px;
    width: 50%;

    &:first-child {
      margin-right: 32px;
    }
  }

  .connecting-text {
    margin-top: 20px;
    font-weight: 500;

    .connecting-sub-text {
      font-weight: 400;
    }
  }
}
</style>
