import { isModalOpen } from './modalGetterTypes.js';
import { each } from 'lodash';
import {
  closeAllModals,
  closeModal,
  openModal,
  setModalOpenState,
} from './modalMutationTypes';

export default {
  state: {},
  getters: {
    [isModalOpen]: (state) => (key) => state[key],
  },
  mutations: {
    [setModalOpenState](state, { key, open }) {
      state[key] = open;
    },
    [openModal](state, key) {
      state[key] = true;
    },
    [closeModal](state, key) {
      state[key] = false;
    },
    [closeAllModals](state) {
      each(state, (open, key) => (state[key] = false));
    },
  },
};
