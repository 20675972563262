<template>
  <FlyDropdown
    ref="dropdownComponent"
    v-click-outside="closeDropdown"
    class="fly-single-select"
    :is-active="open"
    :popper-options="{
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -1],
          },
        },
        {
          name: 'sameWidth',
          enabled: matchDropdownInputWidth,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`;
          },
          effect: ({ state }) => {
            state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
          },
        },
      ],
    }"
    :show="open"
    trigger-mode="manual"
    trigger-selector=".fly-single-select--input"
    v-bind="$attrs"
  >
    <template #trigger>
      <FlyInput
        ref="input"
        :class="{
          'fly-single-select--input': true,
          'fly-input--column': true,
          'fly-input--cursor-text': !!search,
          'fly-input--icon-right': true,
          ...inputBorderRadiusClass,
        }"
        :placeholder="placeholder"
        :disabled="disabled"
        icon-right="ChevronDown"
        :required="required"
        :value="inputValue"
        :readonly="!search && !required"
        :label="label"
        :help-text="helpText"
        :label-class="labelClass"
        @click="onInputClick"
        @blur="onInputBlur"
        @focus="onInputFocus"
        @input="onInputChange"
        @keydown.down.prevent="onInputDown"
        @keydown.enter="onInputEnter"
        @keydown.tab="onInputTab"
        @keydown.up.prevent="onInputUp"
        @keyup.esc="onInputEsc"
      >
        <template #default>
          <slot name="helpText"></slot>
        </template>
      </FlyInput>
    </template>
    <template #options>
      <div
        v-if="open"
        ref="dropdown"
        :class="'fly-box fly-single-select-dropdown ' + dropdownBorderClass"
      >
        <FlyTooltip v-if="isMultiSelect" tooltip="Select all options">
          <div class="fly-single-select-option fly-box">
            <div class="fly-box pipeline-single-select--prop">
              <FlyCheckbox
                :checked="false"
                :label="selectAllLabel"
                @change="onSelectAllCheckboxChange"
              ></FlyCheckbox>
            </div>
          </div>
        </FlyTooltip>

        <FlyTooltip
          v-for="(option, index) in filteredOptions"
          :key="index"
          :tooltip="option.isDisabled && disabledOptionTooltip"
        >
          <div
            :key="index"
            :item-key="index"
            :class="{
              'fly-single-select-option': true,
              'column-direction': subTextKey,
              'fly-box': true,
              'fly-active': index === activeIndex,
              'fly-single-select-option-disabled': option.isDisabled,
            }"
            @mousedown.prevent="() => onOptionClick(option)"
            @mouseover="() => setSelectedIndex(index)"
          >
            <slot
              :is-active="index === activeIndex"
              :is-hovered="index === selectedIndex"
              :option="option"
            >
              <span
                :class="{
                  'fly-text--ui-small': true,
                  'fly-single-select-option-text': true,
                  'fly-text': true,
                  'fly-active': index === activeIndex,
                }"
              >
                {{ option[labelKey] }}
              </span>
              <span
                v-if="subTextKey"
                :class="{
                  'fly-text--ui-xxsmall': true,
                  'fly-single-select-option-sub-text': true,
                  'fly-text': true,
                  'fly-active': index === activeIndex,
                }"
              >
                {{ option[subTextKey] }}
              </span>
            </slot>
          </div>
        </FlyTooltip>
      </div>
    </template>
  </FlyDropdown>
</template>

<script>
import VueTypes from 'vue-types';
import { get, debounce, take, isEmpty, orderBy, isNil, map } from 'lodash';
import { QuickScore } from 'quick-score';
import FlyDropdown from 'shared/components/Dropdown/FlyDropdown.vue';
import MenuKeyboardNavigationMixin from 'shared/components/Form/MenuKeyboardNavigationMixin';
import isElVisibleInParentEl from 'shared/utils/isElVisibleInParentEl';
import FlyTooltip from 'shared/components/Tooltip/FlyTooltip.vue';
import FlyInput from 'shared/components/Form/FlyInput.vue';
import FlyCheckbox from 'shared/components/Form/FlyCheckbox.vue';

export default {
  name: 'FlySingleSelect',
  components: { FlyCheckbox, FlyInput, FlyTooltip, FlyDropdown },
  mixins: [MenuKeyboardNavigationMixin('selectedIndex', 'filteredOptions')],
  props: {
    placeholder: VueTypes.string.def(''),
    options: VueTypes.array.isRequired,
    label: VueTypes.string.def(''),
    labelClass: VueTypes.string,
    labelKey: VueTypes.string.def('label'),
    valueKey: VueTypes.string.def(''),
    helpText: VueTypes.string.def(''),
    subTextKey: VueTypes.string.def(''),
    filterProperties: VueTypes.array.def(['label']),
    initialQuery: VueTypes.string.def(''),
    value: VueTypes.object.def(null),
    maxResultsToShow: VueTypes.number.def(1000),
    selectFirstOption: VueTypes.bool.def(false),
    matchDropdownInputWidth: VueTypes.bool.def(true),
    closeAfterOptionSelect: VueTypes.bool.def(true),
    search: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    required: VueTypes.bool.def(false),
    disabledOptionTooltip: VueTypes.string.def(''),
    singularName: VueTypes.string.def(''),
    pluralName: VueTypes.string.def(''),
    isMultiSelect: VueTypes.bool.def(false),
    allSelected: VueTypes.bool.def(false),
    preventDefaultSearch: VueTypes.bool.def(false),
  },
  emits: ['selection-change', 'open', 'close', 'change', 'query', 'selectAll'],
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _valueKey: this.valueKey || this.labelKey,
      placement: '',
      open: false,
      selectedIndex: 0,
      query: '',
      filteredOptions: this.search ? [] : this.options,
      searcher: this.search
        ? new QuickScore(this.options, this.filterProperties)
        : null,
    };
  },
  computed: {
    inputBorderRadiusClass() {
      if (!this.open) {
        return {};
      }
      if (this.placement.includes('top')) {
        return { 'fly-single-select--input--top': true };
      }
      if (this.placement.includes('bottom')) {
        return { 'fly-single-select--input--bottom': true };
      }

      return {};
    },
    dropdownBorderClass() {
      if (this.placement.includes('bottom')) {
        return 'fly-single-select-dropdown--bottom';
      }
      if (this.placement.includes('top')) {
        return 'fly-single-select-dropdown--top';
      }

      return '';
    },
    currentValueLabel() {
      return get(this.value, this.labelKey, '');
    },
    activeIndex() {
      return this.filteredOptions.findIndex(
        (option) => option[this._valueKey] === get(this.value, this._valueKey),
      );
    },
    inputValue() {
      if (this.open) {
        return this.query;
      }
      if (this.isMultiSelect) {
        if (this.singularName && this.pluralName) {
          return this.value.length
            ? this.value.length === 1
              ? `${this.value.length} ${this.singularName} selected`
              : `${this.value.length} ${this.pluralName} selected`
            : '';
        }
        return (this.value || []).map((item) => item.name).join(', ');
      }
      return get(this.value, this.labelKey, '');
    },
    selectAllLabel() {
      if (this.query) {
        return this.allSelected
          ? 'Deselect All Filtered'
          : 'Select All Filtered';
      }
      return this.allSelected ? 'Deselect All' : 'Select All';
    },
  },
  watch: {
    options(newVal) {
      if (this.search) {
        this.$nextTick(() => {
          this.searcher = new QuickScore(newVal, this.filterProperties);
          this.searchQuery();
        });
      } else {
        this.filteredOptions = newVal;
      }
    },
    selectedIndex(newVal) {
      this.$emit('selection-change', this.filteredOptions[newVal]);
      this.$nextTick(() => {
        if (this.$refs.dropdown) {
          const listItemEl = this.$refs.dropdown.querySelector(
            `[item-key="${newVal}"]`,
          );

          if (!listItemEl) {
            return;
          }

          if (!isElVisibleInParentEl(listItemEl, this.$refs.dropdown)) {
            listItemEl.scrollIntoView(false, {
              behaviour: 'smooth',
              block: 'center',
            });
          }
        }
      });
    },
    open(newVal) {
      this.$emit(newVal ? 'open' : 'close');
      this.$nextTick(() => {
        this.placement =
          get(this.$refs.dropdownComponent, 'popperInstance.state.placement') ||
          '';
      });
    },
    value(newVal) {
      this.$nextTick(() => {
        if (this.isMultiSelect) {
          return;
        }
        this.query = get(newVal, this.labelKey, '');
      });
    },
  },
  mounted() {
    this.searchQuery();
    this.query = this.currentValueLabel || this.initialQuery;
  },
  methods: {
    openDropdown() {
      this.open = true;
    },
    closeDropdown() {
      this.open = false;
    },
    resetQueryValue() {
      this.query = this.query ? this.currentValueLabel : '';
      if (!this.query) {
        this.emitChange({});
      }
    },
    openDropdownAndSearch() {
      this.openDropdown();
      this.searchQuery();
    },
    onInputClick() {
      this.openDropdown();
    },
    onInputFocus() {
      if (this.search) {
        this.$refs.input.selectAllText();
        this.openDropdownAndSearch();
      }
    },
    onInputBlur() {
      if (this.isMultiSelect) {
        return;
      }
      this.resetQueryValue();
      this.closeDropdown();
    },
    onInputChange(e) {
      if (!this.open) {
        this.openDropdown();
      }
      this.query = e.target.value;

      if (!this.preventDefaultSearch) {
        this.searchQueryDebounced();
      }
      this.$emit('query', this.query);
    },
    onInputEsc() {
      if (this.open) {
        this.resetQueryValue();
        this.closeDropdown();
      }
    },
    onInputDown() {
      if (!this.open) {
        this.openDropdownAndSearch();
      } else {
        this.downHandler();
      }
    },
    onInputUp() {
      if (!this.open) {
        this.openDropdownAndSearch();
      } else {
        this.upHandler();
      }
    },
    onInputEnter(e) {
      if (this.open) {
        e.preventDefault();
        this.emitChange(this.filteredOptions[this.selectedIndex]);
        this.closeAfterOptionSelect && this.closeDropdown();
      }
    },
    onInputTab() {
      if (this.open && this.selectFirstOption && this.query) {
        this.searchQuery();
        !isEmpty(this.filteredOptions) &&
          this.emitChange(this.filteredOptions[this.selectedIndex]);
      }
    },
    onOptionClick(option) {
      if (option.isDisabled) {
        return;
      }

      this.emitChange(option);
      this.closeAfterOptionSelect && this.closeDropdown();
    },
    setSelectedIndex(index) {
      if (this.selectedIndex !== index) {
        this.selectedIndex = index;
      }
    },
    onSelectAllCheckboxChange(event) {
      this.$emit('selectAll', event.target.checked);
    },
    emitChange(option) {
      if (isEmpty(option)) {
        return this.$emit('change', {});
      }

      if (this.isMultiSelect) {
        this.$emit('change', option);
        return;
      }

      const item = option;
      if (item[this._valueKey] === get(this.value, this._valueKey)) {
        this.query = item[this.labelKey];
      } else {
        this.$emit('change', item);
      }
    },
    searchQuery() {
      if (this.preventDefaultSearch) {
        this.filteredOptions = this.options;
      }
      if (this.search && !this.preventDefaultSearch) {
        let searchResults = this.searcher.search(this.query || '');
        if (!isNil(get(searchResults[0], 'item.priority'))) {
          searchResults = orderBy(
            searchResults,
            ['score', 'item.priority'],
            ['desc', 'asc'],
          );
        }
        this.filteredOptions = map(
          this.query
            ? searchResults
            : take(searchResults, this.maxResultsToShow),
          'item',
        );
        this.selectedIndex = 0;
      }
    },
    searchQueryDebounced: debounce(function () {
      this.searchQuery();
    }, 250),
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-single-select-dropdown {
  background-color: #ffffff;
  border: 1px solid $fly-color-grey-6;
  border-radius: 8px;
  box-shadow: 0 8px 16px -4px transparentize($fly-color-grey-3, 0.9);
  flex-direction: column;
  overflow-y: auto;
  padding: 4px 0;
  max-height: 320px;
  z-index: 9999999999;
  width: 100%;

  &.fly-single-select-dropdown--bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &.fly-single-select-dropdown--top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.fly-single-select-option {
  background-color: #ffffff;
  padding: 7px 16px;
  cursor: pointer;
  pointer-events: auto;
  width: 100%;
  flex-direction: row;

  &.column-direction {
    flex-direction: column;
  }

  &:hover {
    background-color: $fly-color-grey-8;
  }

  &.fly-single-select-option-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.fly-single-select-option-text {
  color: $fly-color-grey-2;
  &.fly-active {
    color: $fly-color-blue-1;
  }
  &[disabled] {
    color: $fly-color-blue-5;
  }
}

.fly-single-select-option-sub-text {
  color: $fly-color-grey-4;
  &.fly-active {
    color: $fly-color-blue-1;
  }
  &[disabled] {
    color: $fly-color-blue-5;
  }
}

.fly-single-select {
  .fly-single-select--input {
    margin-bottom: 0;
    box-shadow: 0 2px 8px 0px transparentize($fly-color-grey-3, 0.95);
    border-color: $fly-color-grey-6;
    cursor: pointer;

    &.fly-input--cursor-text {
      cursor: text;
    }

    &[disabled],
    &.fly-input--loading {
      cursor: not-allowed;
    }

    .fly-single-select--input--top {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .fly-single-select--input--bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .fly-icon {
    position: absolute;
    right: 12px;
    top: 13px;
  }

  .fly-dropdown-content {
    width: 100%;
  }
}
</style>
