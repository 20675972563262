import { castArray, some } from 'lodash';
import { setLoading } from './loadingMutationTypes.js';
import { isLoading } from './loadingGetterTypes.js';
import { fetchingSetupPipelineFormData, initializing } from './loadingKeys.js';

export default {
  state: {
    [initializing]: true,
    [fetchingSetupPipelineFormData]: true,
  },
  getters: {
    [isLoading]: (state) => (keys) => {
      const _keys = castArray(keys);
      return some(_keys, (key) => state[key]);
    },
  },

  mutations: {
    [setLoading](state, { key, value }) {
      state[key] = value;
    },
  },
};
