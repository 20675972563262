<template>
  <div class="fly-box fly-app-topbar">
    <div class="fly-box fly-app-topbar-left">
      <h4 class="fly-text fly-text--h4">{{ welcomeMessage }}</h4>
    </div>
    <AppNavbarAppsMenu class="fly-app-topbar-right">
      <div class="trigger-container">
        <FlyIcon class="icon" name="ELTCDC"></FlyIcon>
        ELT & CDC
        <svg
          class="caret-icon"
          height="20"
          width="10"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 122 66"
        >
          <g>
            <path
              d="M11.68,1.95C8.95-0.7,4.6-0.64,1.95,2.08c-2.65,2.72-2.59,7.08,0.13,9.73l54.79,53.13l4.8-4.93l-4.8,4.95 c2.74,2.65,7.1,2.58,9.75-0.15c0.08-0.08,0.15-0.16,0.22-0.24l53.95-52.76c2.73-2.65,2.79-7.01,0.14-9.73 c-2.65-2.72-7.01-2.79-9.73-0.13L61.65,50.41L11.68,1.95L11.68,1.95z"
            />
          </g>
        </svg>
      </div>
    </AppNavbarAppsMenu>
  </div>
</template>

<script>
import AppNavbarAppsMenu from 'web/common/nav/AppNavbarAppsMenu';
import FlyIcon from 'shared/components/Icon/FlyIcon.vue';
import { getLoggedInUser } from 'web/user/userDataGetterTypes';
import { mapGetters } from 'vuex';

export default {
  name: 'AppTopbar',
  components: {
    AppNavbarAppsMenu,
    FlyIcon,
  },
  computed: {
    ...mapGetters({
      getLoggedInUser,
    }),
    welcomeMessage() {
      return `Welcome, ${this.getLoggedInUser.first_name}!`;
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';

.fly-app-topbar {
  width: calc(100vw - 50px);
  height: 51px;
  background: #ffffff;
  position: absolute;
  left: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $fly-color-grey-7;
  z-index: 10;

  .fly-app-topbar-left {
    padding: 8px 8px 8px 32px;
  }

  .fly-app-topbar-right {
    width: auto;
  }
}
.trigger-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0 0 0 10px;
  border-left: 1px solid #ebefff;
  font-size: 14px;
  color: #3f3f3f;
  cursor: pointer;
  pointer-events: all !important;
  height: 50px;
  background: #ffffff;
  width: 200px;
  justify-content: flex-start;
  position: relative;
}

.caret-icon {
  position: absolute;
  right: 10px;
}

.icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
</style>
