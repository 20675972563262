import { SOURCE_SCHEMA_API_URL } from './sourceSchemaEnum.js';
import createService from 'shared/utils/createService.js';

export default createService(SOURCE_SCHEMA_API_URL, {
  extraMethods: {
    async get(id, config = {}) {
      let error;
      const response = await this.axiosInstance
      .request({
        url: `${this.route}${id}/schema-collection/`,
        ...config,
      })
      .catch((err) => error = err.response.data.detail);
      if (response.data) {
        return {
          id,
          collections: response.data,
        };
      }

      return {
        id,
        collections: {},
        error,
      };
    },
    async getColumns(id, name, config = {}) {
      const response = await this.axiosInstance.request({
        url: `${this.route}${id}/schema-collection-columns/${name}`,
        ...config,
      });
      return response;
    },
  },
});
