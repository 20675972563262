import SOURCE_ENUM from 'web/sources/SOURCE_ENUM';
import FlyConfigSwitch from 'web/pipelines/create/source/FlyConfigSwitch';
import GA4PropertiesSelectTable from 'web/pipelines/create/GA4PropertiesSelectTable';
import FacebookPagesTable from 'web/pipelines/create/FacebookPagesTable';
import FileStorageSchemaImporter from 'web/pipelines/create/schema/file_storage/FileStorageSchemaImporter';
import { markRaw } from 'vue';
import BASE_RATES_ENUM from 'web/pipelines/create/docs/exchangerates_data_api/BASE_RATES_ENUM';
import SALESFORCE_VERSIONS from 'web/pipelines/create/docs/salesforcev2/SALESFORCE_VERSIONS';
import CLOUD_PROVIDER_REGION_ENUM from 'web/common/CLOUD_PROVIDER_REGION_ENUM';
import CLOUD_PROVIDER_ENUM from 'web/common/CLOUD_PROVIDER_ENUM';
import { find, trim } from 'lodash';
import PipelineCreateSSHOptions from 'web/pipelines/create/PipelineCreateSSHOptions';
import PipelineCreateUseSSLSwitch from 'web/pipelines/create/PipelineCreateUseSSLSwitch';
import PipelineCreateSandboxEnvironment from 'web/pipelines/create/PipelineCreateSandboxEnvironment';
import PipelineCreateSquareSandboxEnvironmentSwitch from 'web/pipelines/create/PipelineCreateSquareSandboxEnvironmentSwitch';
import FlyInput from 'shared/components/Form/FlyInput.vue';
import FlySingleSelect from 'shared/components/Form/FlySingleSelect.vue';

const commonInputProps = {
  labelClass: 'fly-input--label--common-form',
  class: 'fly-input fly-input--column',
};

const nameFieldMaxLength = {
  inputtype: 'node_name',
};

const nodeConfigMaxLength = {
  inputtype: 'node_config',
};

const startDateField = (formKey = 'config.static.start_date') => {
  return {
    component: markRaw(FlyInput),
    formKey,
    changeEventName: 'input',
    props: {
      ...commonInputProps,
      label: 'Start Date',
      type: 'date',
      maxDate: new Date(),
      minDate: new Date('1970-01-01'),
    },
    allowEditAfterCreate: true,
    transformEventValue: (e) => trim(e.target.value),
    defaultValue: `${new Date().getFullYear() - 2}-01-01`,
    transformValue: (value) => {
      const d = new Date(value);

      return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${(
        '0' + d.getDate()
      ).slice(-2)}`;
    },
  };
};

const mysqlForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., production-db, test-db, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.host',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Host *',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.port',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              type: 'number',
              label: 'Port *',
            },
            defaultValue: 3306,
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.user',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'User *',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.password',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nodeConfigMaxLength,
              type: 'password',
              label: 'Password *',
            },
            ephemeral: true,
          },
        ],
        {
          component: markRaw(FlyInput),
          formKey: 'config.connection_config.database',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Database *',
          },
          allowEditAfterCreate: false,
          transformEventValue: (e) => trim(e.target.value),
        },
        {
          formKey: 'tunnel_id',
          defaultValue: null,
        },
      ],
    },
    {
      title: 'Connection options',
      fields: [
        {
          component: markRaw(PipelineCreateSSHOptions),
        },
      ],
    },
    {
      title: 'Custom SSL options',
      fields: [
        {
          component: markRaw(PipelineCreateUseSSLSwitch),
          formKey: 'config.connection_config.ssl',
          defaultValue: {
            rejectUnauthorized: false,
          },
        },
      ],
    },
  ],
};

const postgresForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., production-db, test-db, etc.',
          },
        },
      ],
    },
    {
      title: 'Source Credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.host',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Host *',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.port',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              type: 'number',
              label: 'Port *',
            },
            defaultValue: 5432,
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.user',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Database User *',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.password',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nodeConfigMaxLength,
              type: 'password',
              label: 'Database Password *',
            },
            ephemeral: true,
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.database',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Database *',
            },
            allowEditAfterCreate: false,
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.pgschema',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Schema *',
            },
            defaultValue: 'public',
            allowEditAfterCreate: false,
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'tunnel_id',
          defaultValue: null,
        },
      ],
    },
    {
      title: 'Connection options',
      fields: [
        {
          component: markRaw(PipelineCreateSSHOptions),
        },
      ],
    },
    {
      title: 'Custom SSL options',
      fields: [
        {
          component: markRaw(PipelineCreateUseSSLSwitch),
          formKey: 'config.connection_config.ssl',
          defaultValue: {
            rejectUnauthorized: false,
          },
        },
      ],
    },
  ],
};

const sqlsrvForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., production-db, test-db, etc.',
          },
        },
      ],
    },
    {
      title: 'Source Credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.host',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Host *',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.port',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              type: 'number',
              label: 'Port *',
            },
            defaultValue: 1433,
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.user',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Database User *',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.password',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nodeConfigMaxLength,
              type: 'password',
              label: 'Database Password *',
            },
            ephemeral: true,
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.database',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Database *',
            },
            allowEditAfterCreate: false,
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.sqlsrvschema',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Schema *',
            },
            defaultValue: 'dbo',
            allowEditAfterCreate: false,
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'tunnel_id',
          defaultValue: null,
        },
      ],
    },
    {
      title: 'Connection options',
      fields: [
        {
          component: markRaw(PipelineCreateSSHOptions),
        },
      ],
    },
    {
      title: 'Custom SSL options',
      fields: [
        {
          component: markRaw(PipelineCreateUseSSLSwitch),
          formKey: 'config.connection_config.ssl',
          defaultValue: {
            rejectUnauthorized: false,
          },
        },
      ],
    },
  ],
};

const salesforceForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., production-db, test-db, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.instanceName',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Instance Name *',
            },
            allowEditAfterCreate: false,
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.accessToken',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nodeConfigMaxLength,
              type: 'password',
              label: 'Access Token *',
            },
            ephemeral: true,
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.refreshToken',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nodeConfigMaxLength,
              type: 'password',
              label: 'Refresh Token *',
            },
            ephemeral: true,
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.username',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Username',
              ...nodeConfigMaxLength,
              disabled: true,
              placeholder: 'No user found',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.connection_config.apiType',
          defaultValue: 'legacy',
        },
      ],
    },
  ],
};

const salesforceV2Form = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., production-db, test-db, etc.',
          },
        },
        {
          component: markRaw(FlyInput),
          formKey: 'config.connection_config.apiType',
          defaultValue: 'bulk',
          transformValue: (value) => (value === 'bulk' ? 'Bulk' : 'Rest'),
          props: {
            ...commonInputProps,
            label: 'API Type',
            ...nodeConfigMaxLength,
            disabled: true,
          },
        },
        {
          component: markRaw(FlySingleSelect),
          formKey: 'config.connection_config.apiVersion',
          changeEventName: 'change',
          props: {
            label: 'API Version',
            class: 'fly-single-select--common-form',
            search: false,
            options: SALESFORCE_VERSIONS,
            required: true,
          },
          defaultValue: '58.0',
          allowEditAfterCreate: false,
          transformEventValue: (value) => value.value,
          transformValue: (value) =>
            find(SALESFORCE_VERSIONS, { value: value }),
        },
        {
          component: markRaw(FlyInput),
          formKey: 'config.connection_config.maxApiQuotaPercentage',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            type: 'number',
            max: 100,
            min: 0,
            label: 'Maximum allowed API Quota Percentage',
            helpText:
              'The maximum percentage of the Salesforce API quota that can be used by this source. If the source exceeds this limit, the pipeline will pause and retry in one hour.',
          },
          defaultValue: 80,
          transformEventValue: (e) => parseInt(e.target.value),
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.connection_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.instanceName',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Instance Name *',
            },
            allowEditAfterCreate: false,
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.username',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Username',
              ...nodeConfigMaxLength,
              disabled: true,
              placeholder: 'No user found',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.connection_config.includeFormulaFields',
          defaultValue: false,
        },
        {
          formKey: 'config.connection_config.pollingOptions',
          defaultValue: {
            pollInterval: 5000,
            pollTimeout: 600000,
          },
        },
        startDateField('config.start_date'),
      ],
    },
  ],
};

const bigCommerceForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.store_hash',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Store Hash *',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client ID *',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.access_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Access Token *',
            },
            ephemeral: true,
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'bigcommerce',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const klaviyoForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Private API Key',
              autoFocus: true,
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-klaviyo',
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_config.user_agent',
          defaultValue: 'email_address',
        },
        startDateField('config.tap_config.start_date'),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const klaviyoGenericForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiKey',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nameFieldMaxLength,
              label: 'Private API Key',
              autoFocus: true,
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'klaviyo_generic',
        },
        {
          formKey: 'config.static.start_date',
          defaultValue: '2020-01-01T00:00:00.000Z',
        },
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const gorgiasForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.domain',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Domain',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.email',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'User e-mail',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.access_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'gorgias',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const delightedForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiKey',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Api Key',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'delighted',
        },
      ],
    },
  ],
};

const facebookForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.account_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Facebook Ads Account ID',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: true,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-facebook',
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_config.insights_buffer_days',
          defaultValue: 7,
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

let date = new Date();
date.setDate(date.getDate() - 60);
const shippoForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Live Token',
              autoFocus: true,
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-shippo',
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        startDateField('config.tap_config.start_date'),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const rechargeForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.access_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Access Token',
              ...nodeConfigMaxLength,
              type: 'password',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.user_agent',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API User Email Address',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-recharge',
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const onfleetForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Key',
              ...nodeConfigMaxLength,
              type: 'password',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-onfleet',
        },
        {
          formKey: 'config.tap_config.user_agent',
          defaultValue: 'email_address',
        },
        {
          formKey: 'config.tap_config.quota_limit',
          defaultValue: 50,
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const googleAnalyticsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.client_email',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client email',
              labelClass: 'fly-input--label--common-form-33',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.private_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Private key',
              labelClass: 'fly-input--label--common-form-33',
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.view_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'View ID',
              labelClass: 'fly-input--label--common-form-33',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-google-analytics',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const yotpoForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Key',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.api_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: true,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-yotpo',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const stripeForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.account_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Account ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.client_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Access Token',
              ...nodeConfigMaxLength,
              type: 'password',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-stripe',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const bingAdsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.account_ids',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Account ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.customer_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Customer ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.developer_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Developer Token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-bing-ads',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const shopifyForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.shop',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Shop',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_config.date_window_size',
          defaultValue: 15,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-shopify',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const ebayForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.refresh_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Refresh Token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(PipelineCreateSandboxEnvironment),
            formKey: 'config.tap_config.environment',
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-ebay',
        },
        startDateField('config.tap_config.start_date'),
        {
          formKey: 'config.tap_config.scope',
          defaultValue:
            'https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly',
        },
      ],
    },
  ],
};

const braintreeForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.merchant_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Merchant ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.public_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Public key',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.private_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Private key',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(PipelineCreateSandboxEnvironment),
            formKey: 'config.tap_config.environment',
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-braintree',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const googleSheetsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.spreadsheet_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Spreadsheet ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-google-sheets',
        },
        startDateField('config.tap_config.start_date'),
        {
          formKey: 'config.tap_config.user_agent',
          defaultValue: 'tap-google-sheets <email_address>',
        },
      ],
    },
  ],
};

const zendeskChatForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: true,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-zendesk-chat',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const adrollForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'adroll',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const linkedInAdsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.accounts',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Advertiser IDs',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_config.accounts',
          defaultValue: null,
        },
        {
          formKey: 'config.tap_config.user_agent',
          defaultValue: 'email_address',
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-linkedin-ads',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const exchangeRatesForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.access_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Access Key',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-exchangeratesapi',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const exchangeRatesDataAPIForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source Configuration',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Key',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'exchangerates_data_api',
        },
        startDateField(),
        {
          component: markRaw(FlySingleSelect),
          formKey: 'config.static.base',
          changeEventName: 'change',
          props: {
            label: 'Base Rate',
            class: 'fly-single-select--common-form',
            search: false,
            options: BASE_RATES_ENUM,
          },
          allowEditAfterCreate: false,
          transformEventValue: (value) => value.value,
          transformValue: (value) => BASE_RATES_ENUM[value],
        },
      ],
    },
  ],
};

const sendgridForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Key',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'sendgrid',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const salesforceMarketingForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.username',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Username',
              ...nodeConfigMaxLength,
              disabled: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: true,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-salesforce',
        },
        {
          formKey: 'config.tap_config.api_type',
          defaultValue: 'BULK',
        },
        {
          formKey: 'config.tap_config.lookback_window',
          defaultValue: 10,
        },
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const surveyMonkeyForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.survey_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Survey ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.page_size',
          defaultValue: 50,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-surveymonkey',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const squareForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(PipelineCreateSquareSandboxEnvironmentSwitch),
            formKey: 'config.tap_config.sandbox',
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-square',
        },
        {
          formKey: 'config.tap_config.sandbox',
          defaultValue: 'true',
        },
        {
          formKey: 'config.tap_config.user_agent',
          defaultValue: 'tap-square <email_address>',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const zendeskSupportForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.subdomain',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Zendesk Support Account Domain',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-zendesk',
        },
        startDateField('config.tap_config.start_date'),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const googleAdsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.customer_ids',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Customer ID',
              helpText:
                'Comma separated list of customer IDs, if you want to select all active accounts, enter: *',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.manager_customer_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Manager customer ID',
              helpText: 'Root manager customer ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.customer_ids_to_exclude',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Customer IDs to exclude',
              helpText:
                'If all accounts are selected, you can exclude some of them by entering their IDs here (either Submanagers or Accounts)',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-adwords',
        },
        {
          formKey: 'config.tap_config.user_agent',
          defaultValue: 'tap-adwords <email_address>',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const hubspotForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: true,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-hubspot',
        },
        {
          formKey: 'config.tap_config.api_type',
          defaultValue: 'BULK',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};
const pinterestForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'pinterest',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const omnisendForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'omnisend',
        },
        {
          formKey: 'config.data_store',
          defaultValue: 'omnisend',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};
const adobeAnalyticsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.globalOrganizationId',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              labelClass: 'fly-input--label--common-form-33',
              label: 'Global Organization Id',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.organizationId',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              labelClass: 'fly-input--label--common-form-33',
              label: 'Organization Id',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.technicalAccountId',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              labelClass: 'fly-input--label--common-form-33',
              label: 'Technical Account Id',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.clientId',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Id',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.clientSecret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.privateKey',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nodeConfigMaxLength,
              label: 'Private Key',
              type: 'password',
            },
            ephemeral: true,
          },
        ],
        {
          formKey: 'config.static.url',
          defaultValue: 'https://ims-na1.adobelogin.com/ims/exchange/jwt/',
        },
        {
          formKey: 'config.api_name',
          defaultValue: 'adobe_analytics',
        },
      ],
    },
  ],
};

const paypalForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'paypal',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const mailchimpForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.server',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Server Prefix',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'mailchimp',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const twitterForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.user_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'User ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'twitter',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const youtubeAnalyticsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'youtube_analytics',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const youtubeForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'youtube',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const tiktokAdsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.advertiser_ids',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Advertiser IDs',
            },
            transformEventValue: (e) =>
              trim(
                e.target.value
                  .replace(/[^\d,]/g, '')
                  .replace(/,\s*$/, '')
                  .split(',')
                  .map((advertiser) => `"${advertiser}"`)
                  .join(','),
              ),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'tiktok_ads',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const algoliaForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.application_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Application Id',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Admin API Key',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'algolia',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const shipstationForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Key',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'shipstation',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const salesforcePardotForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.username',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Username',
              labelClass: 'fly-input--label--common-form-33',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.password',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Password',
              ...nodeConfigMaxLength,
              type: 'password',
              labelClass: 'fly-input--label--common-form-33',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.security_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Security Token',
              ...nodeConfigMaxLength,
              type: 'password',
              labelClass: 'fly-input--label--common-form-33',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.business_uid',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Business Unit ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.api_name',
          defaultValue: 'salesforce_pardot',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const snapchatForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.organization_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Organization Id',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.ads_account_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Ads Account Id',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client id',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Secret',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.refresh_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Refresh Token',
              type: 'text',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'snapchat',
        },
        {
          formKey: 'config.static.url',
          defaultValue:
            'https://accounts.snapchat.com/login/oauth2/access_token',
        },
      ],
    },
  ],
};

const prestashopForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Key',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.shop_url',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Shop domain name',
              placeholder: 'eg. example.com/prestashop',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'prestashop',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const salesforceEinsteinForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.assertion',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Assertion',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_config.request_timeout',
          defaultValue: 300,
        },
        {
          formKey: 'config.api_name',
          defaultValue: 'salesforce_einstein',
        },
        {
          formKey: 'config.tap_config.api_type',
          defaultValue: 'BULK',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const mandrillForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiKey',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API key',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'mandrill',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const yotpoLoyaltyForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.guid',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'GUID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiKey',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API key',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'yotpo_loyalty',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const intuitQuickbooksForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.company_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Company Id',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.api_name',
          defaultValue: 'intuit_quickbooks',
        },
        startDateField('config.tap_config.start_date'),
        {
          formKey: 'config.tap_config.user_agent',
          defaultValue: 'tap-google-sheets <email_address>',
        },
      ],
    },
  ],
};

const facebookPagesForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'facebook_pages',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
        {
          formKey: 'config.static.rangeDays',
          defaultValue: '7',
        },
      ],
    },
    {
      title: 'Source properties',
      fields: [
        {
          component: markRaw(FacebookPagesTable),
        },
      ],
    },
  ],
};

const recurlyForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiKey',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API key',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'recurly',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const nacelleForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.space_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Space Id',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.space_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Public Storefront Token',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'nacelle',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const alchemerForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_token_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API token secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'alchemer',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const googleSearchConsoleForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'google_search_console',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const ga4Form = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.lookbackDays',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Lookback Days',
              defaultValue: 30,
              placeholder: 'e.g.   30,7',
              helpText:
                'Sync data for a single day from a specified number of days in the past (Separated by comma). For example, a lookback value of 30 will sync one day’s worth of data from 30 days ago.',
            },
            transformEventValue: (e) => JSON.parse('[' + e.target.value + ']'),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'ga4',
        },
        {
          formKey: 'config.static.bufferTime',
          defaultValue: '2',
        },
        {
          formKey: 'config.static.rangeDays',
          defaultValue: '7',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DD',
        },
        {
          formKey: 'config.extra_args.ga4Properties',
          defaultValue: [],
        },
      ],
    },
    {
      title: 'Source properties',
      fields: [
        {
          component: markRaw(GA4PropertiesSelectTable),
          formKey: 'config.dynamic.loopableObject',
          changeEventName: 'selectedPropertiesChange',
        },
      ],
    },
  ],
};

const ga4ExportForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
        {
          component: markRaw(FlyConfigSwitch),
          formKey: 'config.static.include_intraday_table',
          defaultValue: true,
          changeEventName: 'switchChange',
          props: {
            class: 'fly-switch--common-form',
            fieldKey: 'config.static.include_intraday_table',
            name: 'Intraday Tables',
            label: 'Include Intraday Tables',
            helpText:
              'Intraday tables are tables that are updated throughout the day. If you enable this option, the connector will sync data from these tables as well. This will increase the amount of data synced.',
          },
        },
        {
          component: markRaw(FlyConfigSwitch),
          formKey: 'config.static.lookback_days_active',
          defaultValue: true,
          changeEventName: 'switchChange',
          props: {
            class: 'fly-switch--common-form',
            fieldKey: 'config.static.lookback_days_active',
            name: 'Lookback',
            label: 'Enable Lookback Days',
            helpText:
              'Google Analytics will update daily tables for up to 72 hours. If you enable this option, the connector will once a day re-sync the newest table that has fixed data (Table from 4 days ago).',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_email',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Email',
              ...nodeConfigMaxLength,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.private_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Private Key',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.project_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Project ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.dataset_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Dataset ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'ga4_export',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DD',
        },
        {
          formKey: 'config.static.client_project_id',
          defaultValue: '',
        },
      ],
    },
  ],
};

const ga360Form = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
        {
          component: markRaw(FlyInput),
          formKey: 'config.api_name',
          defaultValue: 'ga360',
          changeEventName: 'input',
          transformValue: () => 'BigQuery Rest API',
          props: {
            ...commonInputProps,
            label: 'API Type',
            ...nodeConfigMaxLength,
            disabled: true,
          },
        },
        {
          component: markRaw(FlyConfigSwitch),
          formKey: 'config.static.include_intraday_table',
          defaultValue: true,
          changeEventName: 'switchChange',
          props: {
            class: 'fly-switch--common-form',
            fieldKey: 'config.static.include_intraday_table',
            name: 'Intraday Tables',
            label: 'Include Intraday Tables',
            helpText:
              'Intraday tables are tables that are updated throughout the day. If you enable this option, the connector will sync data from these tables as well. This will increase the amount of data synced.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.project_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Project ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.dataset_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Dataset ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'ga360',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DD',
        },
      ],
    },
  ],
};

const ga360BQSForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
        {
          component: markRaw(FlyInput),
          formKey: 'config.api_name',
          defaultValue: 'ga360_bq_storage',
          transformValue: () => 'BigQuery Storage API',
          props: {
            ...commonInputProps,
            label: 'API Type',
            ...nodeConfigMaxLength,
            disabled: true,
          },
        },
        {
          component: markRaw(FlyConfigSwitch),
          formKey: 'config.static.include_intraday_table',
          defaultValue: true,
          changeEventName: 'switchChange',
          props: {
            class: 'fly-switch--common-form',
            fieldKey: 'config.static.include_intraday_table',
            name: 'Intraday Tables',
            label: 'Include Intraday Tables',
            helpText:
              'Intraday tables are tables that are updated throughout the day. If you enable this option, the connector will sync data from these tables as well. This will increase the amount of data synced.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_email',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Email',
              ...nodeConfigMaxLength,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.private_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Private Key',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.project_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Project ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.dataset_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Dataset ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'ga360_bq_storage',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DD',
        },
        {
          formKey: 'config.static.client_project_id',
          defaultValue: '',
        },
      ],
    },
  ],
};

const judgemeForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.shop_domain',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'shop_domain',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'api_token',
              type: 'key',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'judgeme',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const zenventoryForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Key',
              type: 'username',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'zenventory',
        },
        startDateField(),
      ],
    },
  ],
};

const returnlyForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'returnly',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const netsuiteForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.accountId',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Account ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.consumerKey',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Consumer Key',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.consumerSecret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Consumer Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.tokenId',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Token ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.tokenSecret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Token Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'netsuite',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const criteoForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.advertiser_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Advertiser ID',
              ...nodeConfigMaxLength,
              type: 'password',
              disabled: true,
            },
            allowEditAfterCreate: false,
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'criteo',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const cybersourceForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.url',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              labelClass: 'fly-input--label--common-form-25',
              label: 'URL',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.merchant_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              labelClass: 'fly-input--label--common-form-25',
              label: 'Merchant ID',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.key_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Key Id',
              labelClass: 'fly-input--label--common-form-25',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.shared_secret_key',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Shared Secret Key',
              labelClass: 'fly-input--label--common-form-25',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'cybersource',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const rakutenAdvertisingForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.client_secret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Secret',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.account_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Account ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'rakuten_advertising',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const ringcentralForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.static.url_string',
          defaultValue: 'https://platform.devtest.ringcentral.com',
        },
        {
          formKey: 'config.api_name',
          defaultValue: 'ringcentral',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const githubForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.tap_config',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.repository',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Repositories',
              placeholder: 'eg. integrateio/integrateio',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: true,
        },
        {
          formKey: 'config.is_bookmark_nested',
          defaultValue: true,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-github',
        },
        startDateField('config.tap_config.start_date'),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const gitlabForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.private_token',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Private Token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.api_url',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API URL',
              placeholder: 'e.g. https://gitlab.com/api/v3',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.projects',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Projects',
              placeholder: 'e.g. integrateio/example integrateio/example2',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.tap_config.groups',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Groups',
              placeholder: 'e.g. mygroup integrateio',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.is_legacy_tap',
          defaultValue: false,
        },
        {
          formKey: 'config.tap_name',
          defaultValue: 'tap-gitlab',
        },
        startDateField('config.tap_config.start_date'),
      ],
    },
  ],
};

const pickzenForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            ...nameFieldMaxLength,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiToken',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'pickzen',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const dripForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'drip',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const slackForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'slack',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const enquirelabsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiToken',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'enquirelabs',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const airtableForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'airtable',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const vendForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source Credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiToken',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API Token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.server',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Shop Name',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'vend',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const smileForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.apiToken',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API token',
              ...nodeConfigMaxLength,
              type: 'password',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'smile',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: 'YYYY-MM-DDTHH:mm:ssZ',
        },
      ],
    },
  ],
};

const amazonAdsForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.api_region',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'API region',
              disabled: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'amazon_ads',
        },
        startDateField(),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const amazonMarketplaceForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.region_name',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Region',
              disabled: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.country',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Country',
              disabled: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'amazon_marketplace',
        },
        {
          formKey: 'config.static.start_date',
          defaultValue: '2022-01-01T00:00:00.000Z',
        },
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

const marketoForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.server',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Server Prefix',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.clientId',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client ID',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.static.clientSecret',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Client Secret',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        {
          formKey: 'config.api_name',
          defaultValue: 'marketo',
        },
        startDateField(),
      ],
    },
  ],
};

const s3Form = {
  selectedFileFilter: 'regex',
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
        {
          component: markRaw(FlyInput),
          formKey: 'config.table_name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Table Name *',
            autoFocus: true,
            placeholder: 'e.g., MainStoreTable, clothing_table, etc.',
            helpText:
              'The name of the table in the destination where the data will be loaded.',
          },
          allowEditAfterCreate: false,
        },
      ],
    },
    {
      title: 'Source credentials',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.bucket_name',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Bucket Name',
              autoFocus: true,
            },
            allowEditAfterCreate: false,
          },
          {
            component: markRaw(FlySingleSelect),
            formKey: 'config.connection_config.bucket_region',
            changeEventName: 'change',
            props: {
              label: 'Bucket Region',
              class: 'fly-single-select--common-form',
              search: false,
              options: CLOUD_PROVIDER_REGION_ENUM[CLOUD_PROVIDER_ENUM.AWS],
            },
            allowEditAfterCreate: false,
            transformEventValue: (value) => value.regionCode,
            transformValue: (value) =>
              find(CLOUD_PROVIDER_REGION_ENUM[CLOUD_PROVIDER_ENUM.AWS], {
                regionCode: value,
              }),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.assume_role',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Role ARN',
              autoFocus: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.assume_role_external_id',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Role External ID',
              autoFocus: true,
              disabled: true,
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
      ],
    },
    {
      title: 'S3 Files Settings',
      fields: [
        [
          {
            component: markRaw(FlyInput),
            formKey: 'config.file_prefix',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'File Prefix',
              autoFocus: true,
              placeholder: 'e.g., main-folder/, clothing-folder/, etc.',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.file_regex',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'File Regex',
              autoFocus: true,
              placeholder: 'e.g., \\.json$ , ^sync-data-,  etc.',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
        ],
        [
          {
            component: markRaw(FlyConfigSwitch),
            formKey: 'config.sample_options.has_headers',
            defaultValue: true,
            changeEventName: 'switchChange',
            props: {
              class: 'fly-switch--common-form',
              fieldKey: 'config.sample_options.has_headers',
              name: 'Has Headers',
              label: 'CSV Files have headers',
              helpText:
                'If the files include headers for column names, enable this option. If not, disable this option. If files are not CSV/TSV/TXT, this option will be ignored.',
            },
          },
        ],
        {
          formKey: 'config.sample_options.num_files_to_sample',
          defaultValue: 5,
        },
        {
          formKey: 'config.sample_options.sample_length',
          defaultValue: 50,
        },
        {
          formKey: 'config.sample_options.sample_rate',
          defaultValue: 5,
        },
        startDateField('config.start_date'),
      ],
    },
    {
      title: 'Source schema',
      fields: [
        {
          component: markRaw(FileStorageSchemaImporter),
          formKey: 'config.columns',
          changeEventName: 'columnsChange',
        },
      ],
    },
  ],
};

const zendeskSellForm = {
  fieldGroups: [
    {
      title: 'Source Details',
      fields: [
        {
          component: markRaw(FlyInput),
          formKey: 'name',
          changeEventName: 'input',
          props: {
            ...commonInputProps,
            label: 'Source Name *',
            autoFocus: true,
            placeholder: 'e.g., main-store, clothing, etc.',
          },
        },
      ],
    },
    {
      title: 'Source credentials',
      isOAuth: true,
      OAuthKey: 'config.static',
      fields: [
        {
          formKey: 'config.api_name',
          defaultValue: 'zendesk_sell',
        },
        startDateField('config.tap_config.start_date'),
        {
          formKey: 'config.start_date_format',
          defaultValue: "yyyy-LL-dd'T'HH:mm:ss'Z'",
        },
      ],
    },
  ],
};

export default {
  [SOURCE_ENUM.MYSQL]: mysqlForm,
  [SOURCE_ENUM.MARIA]: mysqlForm,
  [SOURCE_ENUM.POSTGRES]: postgresForm,
  [SOURCE_ENUM.SQLSRV]: sqlsrvForm,
  [SOURCE_ENUM.SALESFORCE]: salesforceForm,
  [SOURCE_ENUM.SALESFORCEV2]: salesforceV2Form,
  [SOURCE_ENUM.BIGCOMMERCE]: bigCommerceForm,
  [SOURCE_ENUM.KLAVIYO]: klaviyoForm,
  [SOURCE_ENUM.KLAVIYO_GENERIC]: klaviyoGenericForm,
  [SOURCE_ENUM.ALGOLIA]: algoliaForm,
  [SOURCE_ENUM.OMNISEND]: omnisendForm,
  [SOURCE_ENUM.ADOBE_ANALYTICS]: adobeAnalyticsForm,
  [SOURCE_ENUM.FACEBOOK]: facebookForm,
  [SOURCE_ENUM.SHIPPO]: shippoForm,
  [SOURCE_ENUM.RECHARGE]: rechargeForm,
  [SOURCE_ENUM.ONFLEET]: onfleetForm,
  [SOURCE_ENUM.GOOGLE_ANALYTICS]: googleAnalyticsForm,
  [SOURCE_ENUM.YOTPO]: yotpoForm,
  [SOURCE_ENUM.STRIPE]: stripeForm,
  [SOURCE_ENUM.BING_ADS]: bingAdsForm,
  [SOURCE_ENUM.SHOPIFY]: shopifyForm,
  [SOURCE_ENUM.EBAY]: ebayForm,
  [SOURCE_ENUM.BRAINTREE]: braintreeForm,
  [SOURCE_ENUM.GOOGLE_SHEETS]: googleSheetsForm,
  [SOURCE_ENUM.ZENDESK_CHAT]: zendeskChatForm,
  [SOURCE_ENUM.ADROLL]: adrollForm,
  [SOURCE_ENUM.LINKEDIN_ADS]: linkedInAdsForm,
  [SOURCE_ENUM.EXCHANGERATES]: exchangeRatesForm,
  [SOURCE_ENUM.EXCHANGERATES_DATA_API]: exchangeRatesDataAPIForm,
  [SOURCE_ENUM.SENDGRID]: sendgridForm,
  [SOURCE_ENUM.SALESFORCE_MARKETING]: salesforceMarketingForm,
  [SOURCE_ENUM.SURVEYMONKEY]: surveyMonkeyForm,
  [SOURCE_ENUM.SQUARE]: squareForm,
  [SOURCE_ENUM.ZENDESKSUPPORT]: zendeskSupportForm,
  [SOURCE_ENUM.GOOGLE_ADS]: googleAdsForm,
  [SOURCE_ENUM.HUBSPOT]: hubspotForm,
  [SOURCE_ENUM.GORGIAS]: gorgiasForm,
  [SOURCE_ENUM.DELIGHTED]: delightedForm,
  [SOURCE_ENUM.PAYPAL]: paypalForm,
  [SOURCE_ENUM.MAILCHIMP]: mailchimpForm,
  [SOURCE_ENUM.TWITTER]: twitterForm,
  [SOURCE_ENUM.YOUTUBE_ANALYTICS]: youtubeAnalyticsForm,
  [SOURCE_ENUM.YOUTUBE]: youtubeForm,
  [SOURCE_ENUM.PINTEREST]: pinterestForm,
  [SOURCE_ENUM.TIKTOK_ADS]: tiktokAdsForm,
  [SOURCE_ENUM.SHIPSTATION]: shipstationForm,
  [SOURCE_ENUM.SALESFORCE_PARDOT]: salesforcePardotForm,
  [SOURCE_ENUM.SNAPCHAT]: snapchatForm,
  [SOURCE_ENUM.MAGENTO]: mysqlForm,
  [SOURCE_ENUM.PRESTASHOP]: prestashopForm,
  [SOURCE_ENUM.SALESFORCE_EINSTEIN]: salesforceEinsteinForm,
  [SOURCE_ENUM.MANDRILL]: mandrillForm,
  [SOURCE_ENUM.YOTPO_LOYALTY]: yotpoLoyaltyForm,
  [SOURCE_ENUM.INTUIT_QUICKBOOKS]: intuitQuickbooksForm,
  [SOURCE_ENUM.FACEBOOK_PAGES]: facebookPagesForm,
  [SOURCE_ENUM.JUDGEME]: judgemeForm,
  [SOURCE_ENUM.RECURLY]: recurlyForm,
  [SOURCE_ENUM.NACELLE]: nacelleForm,
  [SOURCE_ENUM.ALCHEMER]: alchemerForm,
  [SOURCE_ENUM.RINGCENTRAL]: ringcentralForm,
  [SOURCE_ENUM.CYBERSOURCE]: cybersourceForm,
  [SOURCE_ENUM.RETURNLY]: returnlyForm,
  [SOURCE_ENUM.GOOGLE_SEARCH_CONSOLE]: googleSearchConsoleForm,
  [SOURCE_ENUM.ZENVENTORY]: zenventoryForm,
  [SOURCE_ENUM.NETSUITE]: netsuiteForm,
  [SOURCE_ENUM.RAKUTEN_ADVERTISING]: rakutenAdvertisingForm,
  [SOURCE_ENUM.CRITEO]: criteoForm,
  [SOURCE_ENUM.MARKETO]: marketoForm,
  [SOURCE_ENUM.AIRTABLE]: airtableForm,
  [SOURCE_ENUM.GITHUB]: githubForm,
  [SOURCE_ENUM.GITLAB]: gitlabForm,
  [SOURCE_ENUM.PICKZEN]: pickzenForm,
  [SOURCE_ENUM.DRIP]: dripForm,
  [SOURCE_ENUM.SLACK]: slackForm,
  [SOURCE_ENUM.ENQUIRELABS]: enquirelabsForm,
  [SOURCE_ENUM.VEND]: vendForm,
  [SOURCE_ENUM.SMILE]: smileForm,
  [SOURCE_ENUM.AMAZON_ADS]: amazonAdsForm,
  [SOURCE_ENUM.ZENDESK_SELL]: zendeskSellForm,
  [SOURCE_ENUM.AMAZON_MARKETPLACE]: amazonMarketplaceForm,
  [SOURCE_ENUM.GA4]: ga4Form,
  [SOURCE_ENUM.GA4_EXPORT]: ga4ExportForm,
  [SOURCE_ENUM.GA360]: ga360Form,
  [SOURCE_ENUM.GA360_BQ_STORAGE]: ga360BQSForm,
  [SOURCE_ENUM.S3]: s3Form,
};
