import DESTINATION_ENUM from 'web/destinations/DESTINATION_ENUM';
import FlyInput from 'shared/components/Form/FlyInput.vue';
import { markRaw } from 'vue';
import getRegionSelectField from 'web/common/getRegionSelectField';
import { trim } from 'lodash';
import FlySingleSelect from 'shared/components/Form/FlySingleSelect.vue';
import S3_FILE_FORMAT_ENUM from 'web/common/S3_FILE_FORMAT_ENUM';
import FILE_FORMAT_ENUM from 'web/common/FILE_FORMAT_ENUM';
import PipelineS3AccessSetup from 'web/pipelines/create/PipelineS3AccessSetup';
import { CREATE_DESTINATION_FORM } from 'web/form/formKeys';
import PipelineCreateSSHOptions from 'web/pipelines/create/PipelineCreateSSHOptions';
import PipelineCreateUseSSLSwitch from 'web/pipelines/create/PipelineCreateUseSSLSwitch';
import CLOUD_PROVIDER_ENUM from 'web/common/CLOUD_PROVIDER_ENUM';

const commonInputProps = {
  labelClass: 'fly-input--label--common-form',
  class: 'fly-input fly-input--column',
};

const nameFieldMaxLength = {
  inputtype: 'node_name',
};

const nodeConfigMaxLength = {
  inputtype: 'node_config',
};

export default {
  [DESTINATION_ENUM.REDSHIFT]: {
    fieldGroups: [
      {
        title: 'Destination Details',
        fields: [
          {
            component: markRaw(FlyInput),
            formKey: 'name',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nameFieldMaxLength,
              label: 'Destination Name *',
              autoFocus: true,
              placeholder: 'e.g., production-analytics, test-analytics, etc.',
            },
          },
        ],
      },
      {
        title: 'Destination Credentials',
        fields: [
          [
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.host',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Host *',
                autoFocus: true,
                placeholder:
                  'e.g., cluster-1.abcdefgh.us-east-1.redshift.amazonaws.com',
              },
              transformEventValue: (e) => trim(e.target.value),
            },
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.port',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                type: 'number',
                label: 'Port *',
              },
              defaultValue: 5439,
            },
          ],
          [
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.user',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'User *',
              },
              transformEventValue: (e) => trim(e.target.value),
            },
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.password',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                ...nodeConfigMaxLength,
                type: 'password',
                label: 'Password *',
              },
              ephemeral: true,
            },
          ],
          [
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.database',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Database *',
              },
              transformEventValue: (e) => trim(e.target.value),
            },
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.pgschema',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Schema *',
              },
              defaultValue: 'public',
              transformEventValue: (e) => trim(e.target.value),
            },
          ],
          {
            formKey: 'tunnel_id',
            defaultValue: null,
          },
        ],
      },
      {
        title: 'Connection options',
        fields: [
          {
            component: markRaw(PipelineCreateSSHOptions),
          },
        ],
      },
      {
        title: 'Custom SSL options',
        fields: [
          {
            component: markRaw(PipelineCreateUseSSLSwitch),
            formKey: 'config.connection_config.ssl',
            defaultValue: {
              rejectUnauthorized: false,
            },
          },
        ],
      },
    ],
  },
  [DESTINATION_ENUM.SNOWFLAKE]: {
    fieldGroups: [
      {
        title: 'Destination Details',
        fields: [
          {
            component: markRaw(FlyInput),
            formKey: 'name',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nameFieldMaxLength,
              label: 'Destination Name *',
              autoFocus: true,
              placeholder: 'e.g., production-analytics, test-analytics, etc.',
            },
          },
        ],
      },
      {
        title: 'Destination Credentials',
        fields: [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.account',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Snowflake Account Identifier *',
              autoFocus: true,
              placeholder: 'e.g., zx73881.us-east-2.aws',
              helpText:
                'You can find this in your account URL. For example, if zx73881.us-east-2.aws.snowflakecomputing.com is the URL, then zx73881.us-east-2.aws is the account identifier',
            },
            transformEventValue: (e) => {
              const trimmedValue = trim(e.target.value);
              return trimmedValue.replace('.snowflakecomputing.com', '');
            },
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.warehouse',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Warehouse *',
              placeholder: '',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          [
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.username',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Username *',
              },
              transformEventValue: (e) => trim(e.target.value),
            },
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.password',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                ...nodeConfigMaxLength,
                type: 'password',
                label: 'Password *',
              },
              ephemeral: true,
            },
          ],
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.role',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'User Role *',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.storage_integration_name',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Storage Integration Name *',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          [
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.database',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Database *',
              },
              transformEventValue: (e) => trim(e.target.value),
            },
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.snowflake_schema',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Schema *',
              },
              defaultValue: 'PUBLIC',
              allowEditAfterCreate: false,
              transformEventValue: (e) => trim(e.target.value),
            },
          ],
        ],
      },
    ],
  },
  [DESTINATION_ENUM.S3]: {
    fieldGroups: [
      {
        title: 'Destination Details',
        fields: [
          {
            component: markRaw(FlyInput),
            formKey: 'name',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              ...nameFieldMaxLength,
              label: 'Destination Name *',
              autoFocus: true,
              placeholder: 'e.g., production-analytics, test-analytics, etc.',
            },
          },
        ],
      },
      {
        title: 'Bucket Info & Access',
        fields: [
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.bucket_name',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Bucket name *',
            },
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            formKey: 'config.provider.type',
            defaultValue: CLOUD_PROVIDER_ENUM.AWS,
          },
          getRegionSelectField('Bucket region *', 'config.provider.region'),
          {
            component: markRaw(PipelineS3AccessSetup),
            formKey: 'config.connection_config.assume_role',
            changeEventName: 'change',
            props: {
              inputProps: {
                ...commonInputProps,
                label: 'Role ARN *',
              },
              formName: CREATE_DESTINATION_FORM,
            },
            allowEditAfterCreate: true,
            transformEventValue: (e) => trim(e.target.value),
          },
          {
            component: markRaw(FlyInput),
            formKey: 'config.connection_config.namespace',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Prefix',
            },
            transformEventValue: (e) => trim(e.target.value) || null,
          },
          {
            component: markRaw(FlySingleSelect),
            formKey: 'config.file_format',
            changeEventName: 'change',
            props: {
              class: 'fly-single-select--common-form',
              label: 'File format',
              search: false,
              options: S3_FILE_FORMAT_ENUM,
            },
            allowEditAfterCreate: false,
            transformEventValue: (value) => value.value,
            transformValue: (value) => S3_FILE_FORMAT_ENUM[value],
            defaultValue: FILE_FORMAT_ENUM.AVRO,
          },
        ],
      },
    ],
  },
  [DESTINATION_ENUM.BIGQUERY]: {
    fieldGroups: [
      {
        title: 'Destination Details',
        fields: [
          {
            component: markRaw(FlyInput),
            formKey: 'name',
            changeEventName: 'input',
            props: {
              ...commonInputProps,
              label: 'Destination Name *',
              autoFocus: true,
              placeholder: 'e.g., production-analytics, test-analytics, etc.',
            },
          },
        ],
      },
      {
        title: 'Destination Credentials',
        fields: [
          [
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.project_id',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Project ID *',
                autoFocus: true,
                placeholder: 'e.g., euphoric-effect-296205',
              },
              transformEventValue: (e) => trim(e.target.value),
            },
          ],
          [
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.credentials.client_email',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Client Email *',
              },
              transformEventValue: (e) => trim(e.target.value),
            },
            {
              component: markRaw(FlyInput),
              formKey: 'config.connection_config.credentials.private_key',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                ...nodeConfigMaxLength,
                type: 'password',
                label: 'Private Key *',
              },
              ephemeral: true,
            },
          ],
          [
            {
              component: markRaw(FlyInput),
              formKey: 'config.dataset_id',
              changeEventName: 'input',
              props: {
                ...commonInputProps,
                label: 'Dataset ID *',
              },
              transformEventValue: (e) => trim(e.target.value),
            },
          ],
        ],
      },
    ],
  },
};
