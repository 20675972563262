<template>
  <label
    :class="'fly-box fly-radio-card' + (checked ? ' checked' : '')"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <input
      type="radio"
      :style="{
        position: 'absolute',
        visibility: 'hidden',
      }"
      :disabled="isDisabled"
      :checked="checked"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
    />
    <slot>
      <div class="fly-box label-box">
        <slot name="label">
          <div class="fly-text fly-text--ui-medium label-text">
            {{ label }}
          </div>
        </slot>
        <slot>
          <div v-if="helpText" class="fly-text fly-text--ui-small help-text">
            {{ helpText }}
          </div>
        </slot>
      </div>
    </slot>
  </label>
</template>

<script>
import VueTypes from 'vue-types';
import { isEqual } from 'lodash';

export default {
  name: 'FlyRadioCard',
  props: {
    value: VueTypes.any.isRequired,
    selectedValue: VueTypes.any.isRequired,
    disabled: VueTypes.bool.def(false),
    label: VueTypes.string.def(''),
    helpText: VueTypes.string.def(''),
  },
  emits: ['change'],
  data() {
    return {
      hovered: false,
      focused: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading || this.disabled;
    },
    checked() {
      return isEqual(this.value, this.selectedValue);
    },
  },
  methods: {
    onMouseEnter() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    onChange() {
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="scss">
@import 'shared/styles/variables';
.fly-radio-card {
  cursor: pointer;
  padding: 16px 20px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid $fly-color-grey-6;

  &:hover,
  &:focus,
  &.checked {
    background-color: $fly-color-grey-8;
    border-color: $fly-color-blue-1;
  }

  &.checked {
    .label-box {
      .label-text {
        color: $fly-color-blue-1;
      }
    }
  }

  .label-box {
    flex-direction: column;

    .label-text {
      font-weight: 500;

      color: $fly-color-grey-1;
    }
  }

  .help-text {
    margin-top: 4px;
    color: $fly-color-grey-4;
  }
}
</style>
