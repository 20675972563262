import FILE_FORMAT_ENUM from './FILE_FORMAT_ENUM';
export default Object.freeze({
  // [FILE_FORMAT_ENUM.CSV]: {
  //   label: 'CSV',
  //   value: FILE_FORMAT_ENUM.CSV,
  // },
  // [FILE_FORMAT_ENUM.JSON]: {
  //   label: 'JSON',
  //   value: FILE_FORMAT_ENUM.JSON,
  // },
  [FILE_FORMAT_ENUM.AVRO]: {
    label: 'Avro',
    value: FILE_FORMAT_ENUM.AVRO,
  },
  // [FILE_FORMAT_ENUM.PARQUET]: {
  //   label: 'Parquet',
  //   value: FILE_FORMAT_ENUM.PARQUET,
  // },
});
