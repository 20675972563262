import { getMutationTypesForService } from 'shared/utils/createServiceStore.js';
import { isEmpty, isInteger, get, every, isFunction } from 'lodash';

function createPusherEventHandlersForService(
  serviceKey,
  {
    enqueueCommitType = 'enqueueRealtimeUpdate',
    shouldEnqueue = {},
    customHandlers = {}
  } = {}
) {
  const mutationTypes = getMutationTypesForService(serviceKey);
  const updatedEventName = `updated:${serviceKey}`;
  const createdEventName = `created:${serviceKey}`;
  const deletedEventName = `deleted:${serviceKey}`;

  if (!every(shouldEnqueue, isFunction)) {
    throw new Error('shouldEnqueue[eventType] should be a function');
  }

  if (!every(customHandlers, isFunction)) {
    throw new Error('customHandlers[eventType] should be a function');
  }

  return {
    [updatedEventName]: async function(store, eventData) {
      if (eventData && !isEmpty(eventData)) {
        const { resource_id: id, resource_delta: payload } = eventData;
        if (isInteger(id)) {
          const enqueue =
            shouldEnqueue.updated &&
            (await shouldEnqueue.updated(store, eventData));
          const customHandler = customHandlers.updated;

          if (enqueue) {
            return store.commit(enqueueCommitType, {
              eventName: updatedEventName,
              eventData
            });
          }

          if (customHandler) {
            return customHandler(store, eventData);
          }

          return store.commit(mutationTypes.update, { id, payload });
        }
      }
    },
    [createdEventName]: async function(store, eventData) {
      if (eventData && !isEmpty(eventData)) {
        const { resource_data: payload } = eventData;
        if (isInteger(get(payload, 'id'))) {
          const enqueue =
            shouldEnqueue.created &&
            (await shouldEnqueue.created(store, eventData));
          const customHandler = customHandlers.created;

          if (enqueue) {
            return store.commit(enqueueCommitType, {
              eventName: createdEventName,
              eventData
            });
          }

          if (customHandler) {
            return customHandler(store, eventData);
          }

          return store.commit(mutationTypes.set, payload);
        }
      }
    },
    [deletedEventName]: async function(store, eventData) {
      if (eventData && !isEmpty(eventData)) {
        const { resource_id: id } = eventData;
        if (isInteger(id)) {
          const enqueue =
            shouldEnqueue.deleted &&
            (await shouldEnqueue.deleted(store, eventData));
          const customHandler = customHandlers.deleted;

          if (enqueue) {
            return store.commit(enqueueCommitType, {
              eventName: deletedEventName,
              eventData
            });
          }

          if (customHandler) {
            return customHandler(store, eventData);
          }

          return store.commit(mutationTypes.remove, id);
        }
      }
    }
  };
}

export default createPusherEventHandlersForService;
