import { isPlainObject, isString, isFunction } from 'lodash';
import assert from 'assert';

export default (config, store) => {
  let method = 'dispatch';
  let name = config;

  if (isFunction(config)) {
    return data => config(store, data);
  }

  if (isPlainObject(config)) {
    assert(
      config.type === 'mutation' || config.type === 'action',
      `invalid config.type ${config.type} - should be 'action' or 'mutation'`
    );
    method = config.type === 'mutation' ? 'commit' : method;
    name = config.name;
  }

  assert(isString(name), 'config.name should be a string');

  return data => store[method](name, data);
};
