import createServiceStore from 'shared/utils/createServiceStore.js';
import { SOURCE_METRIC_SCHEMA_SERVICE_KEY } from './sourceMetricSchemaEnum.js';
import sourceMetricSchemaService from './sourceMetricSchemaService.js';
import { setLoading } from '../loading/loadingMutationTypes.js';
import { setError } from '../error/errorMutationTypes.js';
import { find, get, map } from 'lodash';
import {
  getColumnsForTablesInSourceMetricSchema,
  getSchemaInSourceMetricSchema,
  getTablesInSourceMetricSchema,
} from 'web/sourceMetricSchema/sourceMetricSchemaGetterTypes.js';

export default createServiceStore({
  serviceName: SOURCE_METRIC_SCHEMA_SERVICE_KEY,
  service: sourceMetricSchemaService,
  setLoadingMutation: setLoading,
  setErrorMutation: setError,
  getters: {
    [getSchemaInSourceMetricSchema]: (state) => (id) => {
      return get(state, [id, 'schema']) || [];
    },
    [getTablesInSourceMetricSchema]: (state, getters) => (id) => {
      const schema = getters[getSchemaInSourceMetricSchema](id);
      return map(schema, 'table');
    },
    [getColumnsForTablesInSourceMetricSchema]: (state, getters) => (
      id,
      table
    ) => {
      const schema = getters[getSchemaInSourceMetricSchema](id);
      const _table = find(schema, { table }) || {};
      return _table.columns || [];
    },
  },
});
