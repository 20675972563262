import { defineAsyncComponent } from '@vue/runtime-core';

const icons = {
  Bell: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './BellIcon.vue')
  ),
  Cog: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './CogIcon.vue')
  ),
  Edit: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './EditIcon.vue')
  ),
  Link: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './LinkIcon.vue')
  ),
  UnLink: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './UnLinkIcon.vue')
  ),
  VerticalMore: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './VerticalMoreIcon.vue')
  ),
  Logout: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './LogoutIcon.vue')
  ),
  Retry: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './RetryIcon.vue')
  ),
  Trash: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './TrashIcon.vue')
  ),
  FlyDataLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './FlyDataLogoIcon.vue')
  ),
  FlyDataBrandLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './FlyDataBrandLogoIcon.vue')
  ),
  Folder: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './FolderIcon.vue')
  ),
  Calendar: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './CalendarIcon.vue')
  ),
  Pipeline: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PipelineIcon.vue')
  ),
  Server: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ServerIcon.vue')
  ),
  Google: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './GoogleIcon.vue')
  ),
  ChevronUp: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ChevronUpIcon.vue')
  ),
  ChevronDown: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ChevronDownIcon.vue')
  ),
  ChevronLeft: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ChevronLeftIcon.vue')
  ),
  ChevronRight: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ChevronRightIcon.vue')
  ),
  Download: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './DownloadIcon.vue')
  ),
  SlideLeft: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SlideLeftIcon.vue')
  ),
  ArrowRight: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ArrowRightIcon.vue')
  ),
  Close: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './CloseIcon.vue')
  ),
  DWH: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './DWHIcon.vue')
  ),
  ETL: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ETLIcon.vue')
  ),
  ELTCDC: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ELTCDCIcon.vue')
  ),
  APIGeneration: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './APIGenerationIcon.vue')
  ),
  Tick: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './TickIcon.vue')
  ),
  TickCircle: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './TickCircleIcon.vue')
  ),
  WarningCircle: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './WarningCircleIcon.vue')
  ),
  InfoCircle: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './InfoCircleIcon.vue')
  ),
  CountryFlag: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './CountryFlag.vue')
  ),
  PlaneWithTick: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PlaneWithTickIcon.vue')
  ),
  Plus: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PlusIcon.vue')
  ),
  Archive: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ArchiveIcon.vue')
  ),
  Pause: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PauseIcon.vue')
  ),
  Refresh: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './RefreshIcon.vue')
  ),
  Search: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SearchIcon.vue')
  ),
  Spinner: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SpinnerIcon.vue')
  ),
  EmptyRadiobox: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './EmptyRadioboxIcon.vue')
  ),
  MySQLLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './MySQLLogoIcon.vue')
  ),
  PostgreSQLLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PostgreSQLLogoIcon.vue')
  ),
  SqlServerLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SqlServerLogoIcon.vue')
  ),
  SalesforceLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SalesforceLogoIcon.vue')
  ),
  RedshiftLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './RedshiftLogoIcon.vue')
  ),
  SnowflakeLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SnowflakeLogoIcon.vue')
  ),
  S3Logo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './S3LogoIcon.vue')
  ),
  AmazonDBLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './AmazonDBLogoIcon.vue')
  ),
  MariaDBLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './MariaDBLogoIcon.vue')
  ),
  BigCommerceLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './BigCommerceLogoIcon')
  ),
  BigQueryLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './BigQueryLogoIcon')
  ),
  KlaviyoLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './KlaviyoLogoIcon')
  ),
  GorgiasLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './GorgiasLogoIcon')
  ),
  DelightedLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './DelightedLogoIcon')
  ),
  FacebookLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './FacebookLogoIcon')
  ),
  EbayLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './EbayLogoIcon')
  ),
  BraintreeLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './BraintreeLogoIcon')
  ),
  GoogleSheetsLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './GoogleSheetsLogoIcon')
  ),
  ZendeskChatLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ZendeskChatLogoIcon')
  ),
  ShippoLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ShippoLogoIcon')
  ),
  RechargeLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './RechargeLogoIcon')
  ),
  OnfleetLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './OnfleetLogoIcon')
  ),
  YotpoLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './YotpoLogoIcon')
  ),
  StripeLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './StripeLogoIcon')
  ),
  BingLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './BingLogoIcon')
  ),
  ShopifyLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ShopifyLogoIcon')
  ),
  AdrollLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './AdrollLogoIcon')
  ),
  LinkedInLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './LinkedInLogoIcon')
  ),
  ExchangeRatesLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ExchangeRatesLogoIcon')
  ),
  ExchangeRatesDataAPILogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ExchangeRatesDataAPILogoIcon')
  ),
  SendgridLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SendgridLogoIcon')
  ),
  SurveyMonkeyLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SurveyMonkeyLogoIcon')
  ),
  ZendeskSupportLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ZendeskSupportIcon')
  ),
  SquareLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SquareLogoIcon')
  ),
  HubSpotLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './HubSpotLogoIcon.vue')
  ),
  PayPalLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PayPalLogoIcon')
  ),
  MailchimpLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './MailchimpLogoIcon')
  ),
  MandrillLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './MandrillLogoIcon')
  ),
  TwitterLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './TwitterLogoIcon')
  ),
  YouTubeLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './YouTubeLogoIcon')
  ),
  OmnisendLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './OmnisendIcon.vue')
  ),
  AdobeLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './AdobeLogoIcon.vue')
  ),
  TikTokAdsLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './TikTokAdsLogoIcon')
  ),
  ShipStationLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ShipStationLogoIcon')
  ),
  MagentoLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './MagentoLogoIcon')
  ),
  PrestaShopLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PrestaShopLogoIcon')
  ),
  NetsuiteLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './NetsuiteLogoIcon')
  ),
  CriteoLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './CriteoLogoIcon')
  ),
  AirtableLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './AirtableLogoIcon')
  ),
  GithubLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './GithubLogoIcon')
  ),
  GitlabLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './GitlabLogoIcon')
  ),
  SlackLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SlackLogoIcon')
  ),
  AmazonAdsLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './AmazonAdsLogoIcon')
  ),
  SyncHistory: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SyncHistoryIcon.vue')
  ),
  Sort: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SortIcon.vue')
  ),
  SortAsc: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SortAscIcon.vue')
  ),
  SortDesc: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SortDescIcon.vue')
  ),
  UserInvite: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './UserInviteIcon.vue')
  ),
  Help: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './HelpIcon.vue')
  ),
  AlgoliaLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './AlgoliaLogoIcon.vue')
  ),
  Lock: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './LockIcon.vue')
  ),
  PinterestLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PinterestLogoIcon')
  ),
  SnapchatLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SnapchatIcon.vue')
  ),
  IntuitQuickBooksLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './IntuitQuickBooksLogoIcon.vue')
  ),
  JudgeMeLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './JudgeMeLogoIcon.vue')
  ),
  RecurlyLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './RecurlyLogoIcon')
  ),
  NacelleLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './NacelleLogoIcon')
  ),
  AlchemerLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './AlchemerLogoIcon.vue')
  ),
  ZenventoryLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ZenventoryLogoIcon')
  ),
  RingCentralLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './RingCentralLogoIcon.vue')
  ),
  CybersourceLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './CybersourceLogoIcon.vue')
  ),
  ReturnlyLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ReturnlyLogoIcon.vue')
  ),
  MarketoLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './MarketoLogoIcon')
  ),
  PickzenLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './PickzenLogoIcon.vue')
  ),
  DripLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './DripLogoIcon.vue')
  ),
  EnquireLabsLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './EnquireLabsLogoIcon.vue')
  ),
  VendLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './VendLogoIcon')
  ),
  SmileLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './SmileLogoIcon.vue')
  ),
  ZendeskSellLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './ZendeskSellLogoIcon')
  ),
  RakutenAdvertisingLogo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './RakutenAdvertisingLogoIcon.vue')
  ),
  GoogleAnalytics4Logo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './GoogleAnalytics4LogoIcon.vue')
  ),
  GoogleAnalytics360Logo: defineAsyncComponent(() =>
    import(/* webpackChunkName: "icons" */ './GoogleAnalytics360LogoIcon.vue')
  ),
};

export default icons;
